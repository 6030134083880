import constants from 'lib/helpers/constants';
import UnitBezier from 'lib/gui/UnitBezier';
import scrollbarSize from 'lib/gui/scrollBarSize';

const [requestAnimationFrame] = [window.requestAnimationFrame];

const [WILL_OPEN, TWEEN_OPEN, DID_OPEN, WILL_CLOSE, TWEEN_CLOSE, DID_CLOSE] = [
	Symbol('WILL_OPEN'),
	Symbol('TWEEN_OPEN'),
	Symbol('DID_OPEN'),
	Symbol('WILL_CLOSE'),
	Symbol('TWEEN_CLOSE'),
	Symbol('DID_CLOSE')
];
const [appGridItemSelector, appsContainerSelector] = [
	`.${constants.CLASS_APP_GRID_ITEM}`,
	`.${constants.CLASS_APPS_CONTAINER}`
];

/**
 * Possible animation states
 * @type {Object<Symbol>}
 */
export const $menuAnimState = {
	WILL_OPEN,
	TWEEN_OPEN,
	DID_OPEN,

	WILL_CLOSE,
	TWEEN_CLOSE,
	DID_CLOSE
};

const reducer = (rootElem, root) => {
	const menuAnimState = root.state.menuAnimState;
	if ([TWEEN_OPEN, TWEEN_CLOSE].includes(menuAnimState)) {
		return;
	}

	if (!rootElem) {
		return;
	}

	const _elems = rootElem.querySelectorAll(appGridItemSelector);
	if (!_elems.length) {
		return;
	}

	const appsContainer = rootElem.querySelector(appsContainerSelector);
	if (!appsContainer) {
		return;
	}

	(function doAnimAfterATick() {
		/**
		 * Part 1 - Get the elements
		 */
		const appGridItems = [];
		for (let i = 0; i < _elems.length; i++) {
			const elem = _elems[i];
			appGridItems[elem.dataset.pos] = {
				elem
			};
		}

		const closing = menuAnimState === WILL_CLOSE;

		if (root.props.device === constants.DEVICES.MOBILE || !process.config.hasAnimations) {
			appGridItems.forEach((item, pos) => resetTransform(item.elem));

			if ([WILL_OPEN, WILL_CLOSE].includes(menuAnimState)) {
				root.handleMenuAnimStateChange(closing ? DID_CLOSE : DID_OPEN);
			}
			return;
		}

		if ([DID_OPEN, DID_CLOSE].includes(menuAnimState)) {
			appGridItems.forEach((item, pos) => {
				const transform = menuAnimState === DID_CLOSE ? getClosedPos(pos) : getOpenPos(pos);
				const offset = constants.LENGTH_APP_ICON / 2;
				setOffset(item.elem, {
					xoffset: offset,
					yoffset: offset
				});
				setTransform(item.elem, transform);
			});
			return;
		}

		/**
		 * Part 2 - Start/run the animation when the time is right
		 * @TODO save scroll position and restore it after opening
		 */
		if ([WILL_OPEN, WILL_CLOSE].includes(menuAnimState)) {
			appsContainer.scrollTop = 0;
			appGridItems.forEach((item, pos) => {
				item.start = closing ? getOpenPos(pos) : getClosedPos(pos);
				item.final = !closing ? getOpenPos(pos) : getClosedPos(pos);
			});

			root.handleMenuAnimStateChange(closing ? TWEEN_CLOSE : TWEEN_OPEN);

			let start = null;
			let done = false;
			const count = appGridItems.length;

			const curve = new UnitBezier(...constants.ANIM.EASING.CURVE);

			let duration;
			switch (root.props.device) {
				case constants.DEVICES.MOBILE:
					duration = constants.ANIM.DURATION.MOBILE.WEIRD;
					break;
				case constants.DEVICES.SMALL:
					duration = constants.ANIM.DURATION.TABLET.WEIRD;
					break;
				case constants.DEVICES.MEDIUM:
				case constants.DEVICES.LARGE:
				default:
					duration = constants.ANIM.DURATION.DESKTOP.WEIRD;
			}

			function step(now) {
				if (!start) {
					start = now;
				}
				const progress = now - start;
				const t = progress / duration;

				let x = 0;
				if (t < 1) {
					x = curve.solve(t);
				} else {
					x = 1;
					done = true;
				}

				for (let n = count - 1; n >= 0; --n) {
					const item = appGridItems[n];
					setTransform(item.elem, {
						x: linear(item.start.x, item.final.x, x),
						y: linear(item.start.y, item.final.y, x),
						scale: linear(item.start.scale, item.final.scale, closing ? x * 4 : x * 3 - 2)
					});
				}

				if (!done) {
					requestAnimationFrame(step);
				} else {
					root.handleMenuAnimStateChange(closing ? DID_CLOSE : DID_OPEN);
				}
			}
			requestAnimationFrame(step);
		}
	})();
};

export default reducer;

export function setOffset(elem, o) {
	elem.style.transformOrigin = `${o.xoffset || 0}px ${o.yoffset || 0}px 0px`;
}

export function setTransform(elem, t) {
	elem.style.transform = `translate3d(${t.x || 0}px, ${t.y || 0}px, 0px) scale(${t.scale || 1})`;
}

export function resetTransform(elem) {
	const style = elem.style;
	style.transform = '';
	style.transformOrigin = '';
}

// Transform calculation functions .............................................

/**
 * Get css transforms when the icon is in an open position
 * @param {number} pos The position of the AppGridItem in the grid
 * @returns {{x: number, y: number, scale: number}}
 */
export function getOpenPos(pos) {
	const scale = 1;
	let x = 0;
	// eslint-disable-next-line default-case
	switch (pos % 3) {
		case 2:
			x = (2 * (constants.WIDTH_MENU - scrollbarSize)) / 3;
			break;
		case 1:
			x = (constants.WIDTH_MENU - scrollbarSize) / 3;
			break;
		case 0:
	}
	x = Math.round(x);

	let y = 0;
	if (pos >= 3 && pos < 6) {
		y = constants.HEIGHT_APP_GRID;
	} else if (pos >= 6) {
		y = 2 * constants.HEIGHT_APP_GRID;
	}
	y = Math.round(y);

	return { x, y, scale };
}

/**
 * Get css transforms when the icon is in a closed position
 * @param {number} pos The position of the AppGridItem in the grid
 * @returns {{x: number, y: number, scale: number}}
 */
export function getClosedPos(pos) {
	const scale = constants.LENGTH_APP_ICON_CLOSED / constants.LENGTH_APP_ICON;
	const x = 0;
	const y = Math.round(pos * constants.LENGTH_APP_ICON);
	return { x, y, scale };
}

// Utility functions ...........................................................

/**
 * Calculate linear interpolation between start and final based on t
 * @param {number} start Starting value
 * @param {number} final End value
 * @param {number} t Percentage of time passed (0...1)
 * @returns {*}
 */
const linear = (start, final, t) => start + (final - start) * Math.max(Math.min(t, 1), 0);
