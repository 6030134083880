import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { svgToDataURI } from 'lib/helpers/helpers';
import 'components/TopBar/TopBar.less';

export const TopBar = ({ topBarData }) => {
	const WrapperTag = topBarData.url ? 'a' : 'div';
	let wrapperProps = {
		style: { backgroundColor: topBarData.backgroundColor }
	};

	if (topBarData.url) {
		wrapperProps = {
			...wrapperProps,
			href: topBarData.url,
			rel: 'noreferrer noopener'
		};
	}

	const logoCustomStyle = topBarData.logoStyle || {};
	const startLogoCustomStyle = topBarData.startLogoStyle || {};
	return (
		<WrapperTag
			className={[
				'top-bar',
				topBarData.isIEDeprecationBanner ? 'ie-11-deprecation-banner' : ''
			].join(' ')}
			{...wrapperProps}
		>
			<div className="left-side-wrapper">
				{topBarData.leftIcon && (
					<span className="left-icon">
						<img src={svgToDataURI(topBarData.leftIcon)} alt={topBarData.leftIconAlt} />
					</span>
				)}
				{topBarData.startLogo && !topBarData.logo && (
					<img
						className="start-logo"
						src={svgToDataURI(topBarData.startLogo)}
						alt={topBarData.startLogoAlt}
						style={startLogoCustomStyle}
					/>
				)}
				<span className="left-text" style={{ color: topBarData.textLeftColor }}>
					{topBarData.textLeft}
				</span>
				{topBarData.logo && !topBarData.startLogo && (
					<img
						className="logo"
						src={svgToDataURI(topBarData.logo)}
						alt={topBarData.logoAlt}
						style={logoCustomStyle}
					/>
				)}
			</div>
			<div className="right-text" style={{ color: topBarData.textRightColor }}>
				{topBarData.textRight}
			</div>
		</WrapperTag>
	);
};

TopBar.propTypes = {
	topBarData: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	topBarData: state.topBar
});

export default connect(mapStateToProps)(TopBar);
