import 'polyfills';
import 'pendo';
import 'lib/helpers/debug';
import debug from 'debug';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import googleAnalytics, { gaTiming } from 'lib/history/ga';
import setupIO from 'lib/events/IOEventHandler';
import EventHandler from 'lib/events/EventHandler';
import EventBus from 'lib/events/EventBus';
import KeepAlive from 'lib/events/KeepAlive';
import constants from 'lib/helpers/constants';
import layoutBroadcaster from 'lib/layout/layoutBroadcaster';
import setupLanguage from 'lib/language/language';
import resizeListener from 'lib/layout/resizeListener';
import setupConfig from 'lib/helpers/config';
import store from 'lib/store';
import Root from 'components/Root';
import Loading from 'components/loading/Loading';
import { unregister as unregisterServiceWorker } from 'registerServiceWorker';
import { actions as rootActions } from 'lib/root/root';
import 'less/root.less';

const d = debug(`${constants.NS}:index`);

d(`%c@tradeshift/chrome@${process.env.REACT_APP_VERSION}`, 'font-size: 24px; font-weight: bold;');

unregisterServiceWorker();

setupConfig();

/**
 * Called after rehydration is finished
 */
const onBeforeLift = () => {
	d('Initializing store');
	googleAnalytics(process.config.gaCode);
	setupIO();
	EventHandler.init();
	EventBus.listen();
	KeepAlive.start();
	setupLanguage();
	layoutBroadcaster();
	store.dispatch(rootActions.getData());
	resizeListener();
};

const LoadingWithPersistGate = () => {
	onBeforeLift();
	return <Loading fadeIn={true} fadeOut={false} empty={false} store={store} />;
};

export const RootContainer = () => (
	<Provider store={store}>
		<Root />
	</Provider>
);

const unsubscribe = store.subscribe(renderAfterInit);
let renderState = 'empty';
function renderAfterInit() {
	const rootReady = store.getState().root.ready;
	if (!rootReady && renderState === 'empty') {
		d('Hydrating store from localStorage');
		renderState = 'loading';
		render(<LoadingWithPersistGate />, document.getElementById('root'));
	} else if (rootReady && renderState !== 'rendered') {
		d('Rendering Chrome');
		renderState = 'rendered';
		render(<RootContainer />, document.getElementById('root'));
		gaTiming({
			timingCategory: 'Chrome Loaded',
			timingVar: 'load',
			timingValue: window.performance.now(),
			timingLabel: process.env.REACT_APP_VERSION
		});
		unsubscribe();
	}
}

renderAfterInit();
