import { createHashHistory as createHistory } from 'history';

/**
 * HashHistory, until we drop IE9 support
 */
const history = createHistory({
	basename: '',
	hashType: 'slash'
});
export default history;
