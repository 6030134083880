import React from 'react';
import PropTypes from 'prop-types';
import AppIcon from 'components/apps/appicon/AppIcon';
import './MenuItem.less';

export const ItemNode = ({ active, notification, label, iconUrl }) => (
	<div
		className={['menu-item', active && 'active', notification && 'notification']
			.filter(truthy => truthy)
			.join(' ')}
	>
		<div className="menu-item-icon">
			<div className="menu-item-icon-wrapper">
				<AppIcon alt={label} iconURL={iconUrl} />
			</div>
		</div>
		{label && <div className="menu-item-label">{label}</div>}
	</div>
);

const MenuItem = ({ iconUrl, label, active, notification, className, ...rest }) => {
	const WrapperTag = rest.href ? 'a' : 'button';
	return (
		<WrapperTag
			data-tooltip={label}
			className={['menu-item-wrapper', className].filter(truthy => truthy).join(' ')}
			{...rest}
		>
			<ItemNode active={active} label={label} notification={notification} iconUrl={iconUrl} />
		</WrapperTag>
	);
};

MenuItem.propTypes = {
	iconUrl: PropTypes.string.isRequired,
	label: PropTypes.string,
	active: PropTypes.bool,
	notification: PropTypes.bool,
	className: PropTypes.string,
	onClick: PropTypes.func.isRequired,
	tabIndex: PropTypes.string
};

MenuItem.defaultProps = {
	label: null,
	active: false,
	notification: false,
	onClick: () => {}
};

export default MenuItem;
