import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'lib/language/language';
import { actions as layoutActions } from 'lib/layout/layout';
import 'components/menu/header/header.less';
import toggleIcon from 'components/menu/header/__static__/toggleIcon.svg';
import tsLogo from 'components/menu/header/__static__/tsLogo.svg';

export const Header = ({ t, closeMenu }) => (
	<div className="header header-item">
		<div className="left-container">
			<img className="menu-icon" src={toggleIcon} alt={t('[Alt] Open menu')} />
		</div>
		<div className="center-container">
			<img className="logo" src={tsLogo} alt="Tradeshift" />
		</div>
		<div
			className="right-container"
			onClick={e => {
				e.stopPropagation();
				closeMenu();
			}}
		>
			<img className="close-icon" src={toggleIcon} alt={t('[Alt] Close menu')} />
		</div>
	</div>
);

Header.propTypes = {
	t: PropTypes.func.isRequired,
	closeMenu: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	t: t()
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			closeMenu: () => layoutActions.setMenu(false)
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
