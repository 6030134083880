import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loading from 'components/loading/Loading';
import MenuNew from 'components/menu/Menu';
import Frames from 'components/frames/Frames';
import DeletionRequestPopup from 'components/DeletionRequestPopup/DeletionRequestPopup';
import TopBar from 'components/TopBar/TopBar';
import constants from 'lib/helpers/constants';
import { selectors as layoutSelectors } from 'lib/layout/layout';
import rootAnimator, { $menuAnimState } from 'components/rootAnimator';
import ZendeskChat from 'components/ZendeskChat/ZendeskChat';

// eslint-disable-next-line react/no-deprecated
export class Root extends React.Component {
	constructor(props) {
		super(props);
		this.handleMenuAnimStateChange = this.handleMenuAnimStateChange.bind(this);
		this.reset(true);
	}

	reset(constructor = false, props) {
		if (props === undefined) {
			props = this.props;
		}
		const state = {
			hasAnimationsClassName: process.config.hasAnimations
				? constants.CLASS_HAS_ANIM
				: constants.CLASS_NO_ANIM,
			menuAnimState: props.menu ? $menuAnimState.DID_OPEN : $menuAnimState.DID_CLOSE,
			menuAnimClassName: '',
			collaborationAnimClassName: ''
		};
		if (!constructor) {
			this.setState(state);
		} else {
			state.ready = false;
			// eslint-disable-next-line react/no-direct-mutation-state
			this.state = state;
		}
	}

	handleMenuAnimStateChange(menuAnimState) {
		let menuAnimClassName = '';
		if (
			this.state.device !== constants.DEVICES.MOBILE &&
			process.config.hasAnimations &&
			[
				$menuAnimState.WILL_OPEN,
				$menuAnimState.WILL_CLOSE,
				$menuAnimState.TWEEN_OPEN,
				$menuAnimState.TWEEN_CLOSE
			].includes(menuAnimState)
		) {
			menuAnimClassName = constants.CLASS_MENU_ANIMATING;
		}
		this.setState({ menuAnimState, menuAnimClassName });
	}

	componentWillReceiveProps(nextProps) {
		const readyChanged = this.state.ready !== nextProps.ready;
		if (readyChanged) {
			this.setState({
				ready: nextProps.ready
			});
		}

		const collaborationChanged = this.props.collaboration !== nextProps.collaboration;
		if (collaborationChanged) {
			let duration;
			const durationType = nextProps.collaboration ? 'ENTER' : 'LEAVE';
			switch (this.props.device) {
				case constants.DEVICES.MOBILE:
					duration = constants.ANIM.DURATION.MOBILE[durationType];
					break;
				case constants.DEVICES.SMALL:
					duration = constants.ANIM.DURATION.TABLET[durationType];
					break;
				case constants.DEVICES.MEDIUM:
				case constants.DEVICES.LARGE:
				default:
					duration = constants.ANIM.DURATION.DESKTOP[durationType];
			}

			this.setState({ collaborationAnimClassName: constants.CLASS_COLLABORATION_ANIMATING });
			setTimeout(() => {
				this.setState({ collaborationAnimClassName: '' });
			}, duration);
		}

		const menuChanged = this.props.menu !== nextProps.menu;
		if (!menuChanged) {
			return;
		}

		const newDevice = this.props.device !== nextProps.device;
		if (newDevice) {
			this.reset(false, nextProps);
		} else {
			this.handleMenuAnimStateChange(
				nextProps.menu ? $menuAnimState.WILL_OPEN : $menuAnimState.WILL_CLOSE
			);
		}
	}

	render() {
		return (
			<div>
				<Loading fadeIn={false} fadeOut={this.state.ready} anim={true} />
				<div
					className={[
						constants.CLASS_ROOT,
						this.props.rootAttrs.className,
						this.state.menuAnimClassName,
						this.state.collaborationAnimClassName,
						this.state.hasAnimationsClassName,
						this.props.hasTopBar && constants.CLASS_HAS_TOP_BAR
					].join(' ')}
					ref={elem => rootAnimator(elem, this)}
				>
					{this.props.hasTopBar && <TopBar />}
					<MenuNew menuAnimClassName={this.state.menuAnimClassName} />
					{this.props.deletionRequest != null && (
						<DeletionRequestPopup
							deletionRequest={this.props.deletionRequest}
							isCompanyAdmin={this.props.isCompanyAdmin}
						/>
					)}
					<ZendeskChat />
					<Frames />
				</div>
			</div>
		);
	}
}

Root.propTypes = {
	rootAttrs: PropTypes.object.isRequired,
	menu: PropTypes.bool.isRequired,
	device: PropTypes.number.isRequired,
	ready: PropTypes.bool.isRequired,
	deletionRequest: PropTypes.object,
	isCompanyAdmin: PropTypes.bool.isRequired,
	hasTopBar: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	menu: state.layout.menu,
	device: state.layout.device,
	collaboration: state.layout.collaboration,
	ready: state.root.ready,
	rootAttrs: {
		className: layoutSelectors.rootClass(state)
	},
	deletionRequest: state.root.deletionRequest,
	isCompanyAdmin: state.root.isCompanyAdmin,
	hasTopBar: state.topBar.hasTopBar
});

export default connect(mapStateToProps)(Root);
