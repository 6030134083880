import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadZenDeskChatScript, zeWrapper } from './ZendeskWebWidgetHelper';
import 'components/ZendeskChat/ZendeskChat.less';

function ZendeskChat({ activeApp, isSeller, companyCountry, userName, userEmail }) {
	const [isActive, setIsActive] = useState(false);
	const activatedAppsList = process.config.zendeskConfig.activatedApps;
	const restrictedCountries = process.config.zendeskConfig.restrictedCountries;

	useEffect(() => {
		if (
			isSeller &&
			!restrictedCountries.includes(companyCountry) &&
			activatedAppsList.includes(activeApp)
		) {
			setIsActive(true);
		}
	}, [activeApp, isSeller, companyCountry, activatedAppsList, restrictedCountries]);

	useEffect(() => {
		// Since the Zendesk chat API doesn't have a remove listener kinda thing for chat:end
		// emitting an event from chat:end callback so we can clean up after each render to know the
		// active app when the chat widget was closed.
		const chatEndHandler = e => {
			if (!activatedAppsList.includes(activeApp)) {
				zeWrapper.hide();
			}
		};
		window.addEventListener('zendesk-chat-ended', chatEndHandler);
		return () => {
			window.removeEventListener('zendesk-chat-ended', chatEndHandler);
		};
	}, [activeApp, activatedAppsList]);

	useEffect(() => {
		if (!isActive) {
			return;
		}

		if (activatedAppsList.includes(activeApp)) {
			zeWrapper.show();
			loadZenDeskChatScript()
				.then(() => {
					zeWrapper.prefill({ userName, userEmail });
					zeWrapper.addChatEndListener();
				})
				.catch(error => {
					console.log('Failed to load Zendesk Embeddable script: ', error);
				});
		} else {
			if (!zeWrapper.isChatting()) {
				zeWrapper.hide();
			}
		}
	}, [activeApp, isActive, activatedAppsList, userEmail, userName]);
	return null;
}

ZendeskChat.propTypes = {
	activeApp: PropTypes.string.isRequired,
	companyCountry: PropTypes.string.isRequired,
	isSeller: PropTypes.bool.isRequired,
	userName: PropTypes.string.isRequired,
	userEmail: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
	activeApp: state.frames.main.appId,
	companyCountry: state.menu.user.companyCountry,
	isSeller: !state.menu.user.companyIsPremium,
	userName: state.menu.user.userName,
	userEmail: state.menu.user.email
});

export default connect(mapStateToProps)(ZendeskChat);
