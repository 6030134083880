import React from 'react';
import constants from 'lib/helpers/constants';
import TsFrame from 'components/frames/TsFrame';

export const Inbox = () => (
	<div className={constants.CLASS_INBOX}>
		<TsFrame frameId={constants.FRAMES.INBOX} />
	</div>
);

export default Inbox;
