import debug from 'debug';
import constants from 'lib/helpers/constants';
import { isStandalone, getCookie } from 'lib/helpers/helpers';
import { gaException } from 'lib/history/ga';

/**
 * Make GET request using fetch()
 * Also, add all necessary headers to work with the Tradeshift APIs
 * @param url URL without baseURL and frameBaseURL
 * @returns {Promise.<Object>}
 */
export const get = async (url, params = {}, opts = {}) => {
	const method = 'GET';

	// Try to load the request first
	try {
		return await request(method, url, params, opts);
	} catch (err) {
		console.warn(`Failed GET request to ${url}, retrying immediately…`, { params, opts }, err);
		gaException(`Failed first GET request to ${url} | Error: ${err.message}`);
	}

	// Retry in case it fails
	try {
		return await request(method, url, params, opts);
	} catch (err) {
		console.warn(
			`Failed second retry of GET request to ${url}, retrying in ${constants.DURATION_GET_RETRY /
				1000} seconds…`,
			{ params, opts },
			err
		);
		gaException(`Failed second GET request to ${url} | Error: ${err.message}`);
	}

	// Retry a third time, after a 5 second delay
	try {
		await new Promise(resolve => setTimeout(resolve, constants.DURATION_GET_RETRY));
		return await request(method, url, params, opts);
	} catch (err) {
		console.warn(`Failed final retry of GET request to ${url}.`, { params, opts }, err);
		gaException(`Failed third GET request to ${url} | Error: ${err.message}`);
		throw err;
	}
};

/**
 * Make POST request using fetch()
 * Also, add all necessary headers to work with the Tradeshift APIs
 * @param url URL without baseURL and frameBaseURL
 * @returns {Promise.<Object>}
 */
export const post = async (url, params = {}, opts = {}) => {
	const method = 'POST';
	return await request(method, url, params, opts);
};

const request = async (method, url, params = {}, opts = {}) => {
	const d = debug(`${constants.NS}:fetch:${method}`);
	if (!url) {
		const errorMsg = "Can't create request, url is undefined";
		d(errorMsg);
		throw new Error(errorMsg);
	}

	if (!process.config.request) {
		const errorMsg = "Can't create request, missing header info from process.config.request!";
		d(errorMsg);
		throw new Error(errorMsg);
	}

	if (isStandalone()) {
		const errorMsg = `Running in standalone mode, failing ${method} request.`;
		d(errorMsg);
		throw new Error(errorMsg);
	}

	try {
		const request = process.config.request;
		/**
		 * Headers stored here, so it's easy to print for debugging
		 * @type {object}
		 */
		const h = {
			'Content-Type': 'application/json',
			'X-Tradeshift-ActorId': request.userId,
			'X-Tradeshift-RequestId': request.requestId,
			'X-Tradeshift-TenantId': request.companyId
		};
		if (!opts.noAuth) {
			h['Authorization'] = request.accessToken;
		}
		const headers = new Headers();
		// Append headers for realz
		Object.keys(h).forEach(k => headers.append(k, h[k]));

		const fetchInit = {
			method: method,
			cache: 'no-cache',
			credentials: 'same-origin',
			headers
		};
		if (opts.body) {
			try {
				fetchInit.body = JSON.stringify(opts.body);
			} catch (e) {
				console.warn('Error while serializing request body.', opts.body, e);
				gaException(`Error while serializing request body. ${opts.body} | Error: ${e.message}`);
			}
		}
		let fetchURL = process.config.baseURL + url;
		fetchURL += `?_=${new Date().valueOf()}&csrfToken=${getCookie('csrfToken')}`;
		/**
		 * @TODO convert all `params` into URL search
		 */
		if (params.url) {
			fetchURL += '&url=' + encodeURIComponent(params.url || '');
		}
		d('fetching %o %O %O', fetchURL, fetchInit, h);
		const res = await fetch(fetchURL, fetchInit);
		if (res.ok) {
			if (res.status === 204) {
				return new Promise(resolve => resolve(true));
			}
			return res.json();
		} else {
			d('%o - %o while making %o request.', res.status, res.statusText, method);
			const errJSON = await res.json();
			const err = new Error(errJSON.name);
			err.message = errJSON.message;
			err.stack = errJSON.stack || err.stack;
			gaException(`${res.status} while making ${method} request | Error: ${err.message}`);
			throw rethrowError(
				err,
				errJSON.stack,
				`${method} request returned ${res.status} ${res.statusText}!`
			);
		}
	} catch (err) {
		throw err;
	}
};

/**
 * Append Error stacktrace for rethrowing.
 */
function rethrowError(e = {}, hasStack = false, name) {
	const error = new Error(name || `${e.name}: ${e.message}`);
	let stackWithoutRethrowError = (error.stack || '').split('\n');
	stackWithoutRethrowError.splice(1, 1);
	stackWithoutRethrowError = stackWithoutRethrowError.join('\n');

	error.stack = `${stackWithoutRethrowError}\n\n--- Apps stacktrace below: ---\n\nCaused by: ${
		hasStack ? e.stack : e.toString()
	}`;

	return error;
}
