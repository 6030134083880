import React from 'react';
import 'components/ModalLoadingCover/ModalLoadingCover.less';

const ModalLoadingCover = ({ children }) => (
	<div className="modal-loading-cover">
		<div className="spinner"></div>
		<div>{children}</div>
	</div>
);

export default ModalLoadingCover;
