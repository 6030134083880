import { createSelector } from 'reselect';
import constants from 'lib/helpers/constants';
import filters from 'lib/apps/appsFilters';
import { createActionsList } from 'components/CreateActionsModal/createActionsList';
import FlexSearch from 'flexsearch/dist/flexsearch.light';
import { t } from 'lib/language/language';

const getVisibleApps = createSelector([filters.apps], apps =>
	apps.filter(app => app.activated && !app.hidden).sort((a, b) => a.name.localeCompare(b.name))
);
const getPinnedApps = createSelector([getVisibleApps], visibleApps =>
	visibleApps
		.filter(app => app.pinned)
		.sort((a, b) => a.pinned - b.pinned)
		.slice(0, constants.NUM_GRID_APPS)
);

const getSearchIndex = createSelector(
	[getVisibleApps, filters.descriptions],
	(apps, descriptions) => {
		// docs: https://github.com/nextapps-de/flexsearch#options
		const searchIndex = new FlexSearch({
			split: /\s+/,
			encode: 'icase',
			tokenize: 'full'
		});

		if (descriptions) {
			apps.forEach(app => {
				let searchStr = `${app.name}::${app.id}`;
				if (descriptions[app.id]) {
					searchStr = searchStr.concat(`::${descriptions[app.id]}`);
				}
				searchIndex.add(app.id, searchStr);
			});
		}

		return searchIndex;
	}
);

const getFilteredApps = createSelector(
	[getVisibleApps, getSearchIndex, filters.filterValue],
	(visibleApps, searchIndex, filterValue) => {
		if (!filterValue) {
			return visibleApps;
		}

		const results = searchIndex.search(filterValue);

		return results
			.map(foundId => visibleApps.find(app => app.id === foundId))
			.sort((a, b) => a.name.localeCompare(b.name));
	}
);

export const selectors = {
	getPinnedApps,
	getVisibleApps,
	getFilteredApps,
	/**
	 * Is there enough room to pin apps?
	 */
	canPinApps: createSelector(
		[getPinnedApps],
		pinnedApps => pinnedApps.length < constants.NUM_GRID_APPS
	),
	getApp: createSelector([filters.apps, filters.appIdToMatch], (apps, appId) =>
		apps.find(app => app.id === appId)
	),
	getAppAfterRedirect: createSelector([filters.apps, filters.appIdToMatch], (apps, appId) => {
		let app = apps.find(app => app.id === appId);
		if (app && app.redirect) {
			app = apps.find(a => a.id === app.redirect);
		}
		return app;
	}),
	hasBranchSelector: createSelector(
		[filters.apps],
		apps =>
			!!apps.some(app => {
				return app.id === process.config.featureApps.branchSelector && !app.hidden;
			})
	),
	redirect: createSelector(
		[filters.redirects, filters.appIdToMatch],
		(redirects, appId) => redirects[appId]
	),
	getCreateActions: createSelector(
		[filters.createActions, filters.createActionsApps, filters.apps],
		(createActions, createActionsApps, availableApps) => {
			const result = [];
			if (createActionsApps && createActionsApps.length) {
				const filteredApps = availableApps.filter(app =>
					createActionsApps.some(a => a.id === app.id)
				);
				const filteredActions = createActionsList(t()).filter(a =>
					createActions.some(id => id === a.actionId)
				);
				filteredActions.forEach(action => {
					const appsList = action.onlyAppActivatedCheck ? availableApps : filteredApps;
					const app = appsList.find(app => app.id === action.appId);
					if (app) {
						// We need a href property from the app to be able to navigate to it
						result.push({ ...action, href: app.href });
					}
				});
			}
			return result;
		}
	)
};

export default selectors;
