import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { localeReducer as locale } from 'react-localize-redux';
import history from 'lib/history/hashHistory';
import menu from 'lib/menu/menu';
import apps from 'lib/apps/apps';
import frames from 'lib/frames/frames';
import inbox from 'lib/inbox/inbox';
import layout from 'lib/layout/layout';
import root from 'lib/root/root';
import topBar from 'lib/topBar/topBar';
import createActionsModal from 'lib/apps/createActionsModal';
import eventTrackerMiddleware from 'lib/middlewares/eventTracker';

import { composeWithDevTools } from 'redux-devtools-extension';

export const configureStore = function() {
	const middleware = applyMiddleware(thunk, eventTrackerMiddleware);

	const store = createStore(
		combineReducers({
			history,
			menu,
			apps,
			frames,
			inbox,
			layout,
			root,
			topBar,
			locale,
			createActionsModal
		}),
		composeWithDevTools(middleware)
	);

	return { store };
};

export const { store } = configureStore();

export default store;
