module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"您要提供给特定买家的产品集合。",
"b9b2bec3b24aa4b9a601ab046cd12009":"卖方开具给买方的一个文档，标明所提供的产品或服务的数量和费用。",
"5b24efebe4bd25a8c5e3aca06197e35e":"在发货或交付前发出的待确认销售单。",
"4e917d587825921098d19cb5374ffa54":"添加应用到收藏夹",
"7328b929c3b7ea8a93ee9e597817f510":"全部应用",
"baaa8b0af4b7c2a73cdd46756902f9dd":"箭头图标",
"421d54e688f76e063b53b1bd67178b42":"可用应用",
"614bb33c219a0809bbe8e52f938dcce7":"一揽子采购订单（请求）",
"51d895d6183265b06e899570e19eecf6":"分公司徽标",
"9d6641632312bcd817a18331b01bd073":"浏览并搜索可供购买的产品。",
"883d14b9efdb4894d9879c0eff9b5acb":"检查平台状态或联系客户支持。",
"55fc4360777c0241e107f70379e57e75":"关闭菜单",
"da76f49c6f0252b80a6e330d6394c094":"根据未结采购订单来确认收到的产品和服务。",
"67f2b8b530a56e35ec1e8254fb72748f":"新联系人申请",
"9860a293ae95e8583f868975af1f4849":"联系技术支持",
"210d71bea3790d832babc970a6674be3":"将一个未结订单转为一张发票。",
"8df8c2d028d750d31bb796681c2ea3f9":"创建测试发票",
"5b5efdc0c8989fe1056b0e54734e824d":"创建发票",
"20c9ff16bb2f0448290a5087a58cdebf":"创建并向客户发送报价文档。",
"830fa5e28d0b88eb70218ea763f71c5c":"创建一揽子采购订单",
"d75c3f6646800bc987c0c07f986b6111":"创建新产品",
"9f7cd528570ea5fa70d5f0fdb8227759":"创建提议",
"e778d3c66bb24ea357fcbaacb2e4bab9":"创建订单",
"9f2650a5abb1e17ffb4714580cc4c457":"创建商品",
"581c02d2262d5818fb14faef2a5c5e4e":"创建报价单",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"创建书面申请",
"3f884136e59449da353adefd80121181":"创建......",
"d23014cf5a5e7f8c6440b96692831aa8":"红字发票",
"a9616b4df87bbe0147d76ca08f1a4ad3":"使用（预估）价格和内部预算来定义产品或服务项目，以在限定时间段内创建采购订单。",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"定义在Tradeshift采购市场中销售的产品。",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"为商店中找不到的产品或服务定义您的请求，并将其添加到您的购物篮或采购申请中。",
"1b0dd1aca58f84099cff422597422e56":"Tradeshift加载失败",
"2ca056bf517beb52194e04b1d425b85d":"Tradeshift加载失败,",
"901bcad22b5486ef2185cb1e59e0a234":"收货单",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"将公司按网络分组以简化管理",
"d7b639ae724bad4cb10abf6e411bd74e":"我的应用",
"3c4be400f728da4526de626653d56ccc":"发票",
"697ca358c7443b09bab9478598f14726":"订单开票",
"db13bed8b517884b5e8e257c3e829e7d":"为将来的采购开具红字发票。",
"8535934d42d580306640d88e0ed217aa":"公司加载中…",
"802ed138bb62eb56ff187f839234478b":"加载中…",
"1c8e8813d12a483823656cc7c245cd58":"登出",
"b25df08fcf0d0acaf6384653ea02141e":"登出",
"34bea6b12f16fea4889a2a80c07ee8ec":"管理组",
"7a558a55eb2d9e641f608f759964a9f6":"为卖家手动创建采购订单，注明建议的商品或服务、数量和价格。",
"fbb9cc68fee07e74094da1030bfdf236":"网络分组",
"ba116f853ca15c3b6f136da33867158b":"没有与搜索条件匹配的应用。",
"14877050aa06ad5917e9d7a7048ad2e3":"没有描述。",
"2778717f6acbeb1edaac6a2656b478f3":"打开",
"37f066e2502bac5316dfc900890ade4c":"打开菜单",
"018c4048ea00acea67a5819288f4703f":"订单",
"2ae053867612b2202a870bd52cc3ff63":"平台状态",
"281f9682838b746f5a0b8a4555614843":"预付款发票",
"387117ac2a32235f30c143484f94147f":"形式发票",
"b2562e71feb3c0a4d25d8b4aeacd545f":"从商店购买",
"9bb4a36de6bff47eb4209096972979dd":"报价单",
"befc83a6b9e66cbfb953b901b91290d0":"已达到收藏夹的最大数量！",
"beb839cf63984c0634d05bdc900e1905":"从订单接收货物",
"d1b0cf6017a647b49abde5b8ba6fe614":"最近使用的分公司",
"793f04b605fc32835a578962a6994422":"记录一笔预付款。",
"4eddd0ca790e495626f275843c87f440":"刷新",
"ab8100964aeb93c35539b88420121939":"从收藏夹中移除应用",
"d817327a5392f2d398ed8721a314bc37":"搜索应用",
"31e12c8c15eae192e29acf9fcb949287":"在Tradeshift网络上搜索可开展业务的公司。",
"3bcee8f61c10123abe392bf0cc31f99c":"搜索公司",
"4716fe18b1d4f74edf1a1cdacc83b329":"商店",
"50b5a47e8f2e9f988f509e248860acd0":"显示操作",
"3ad73c1b179af6e6df8e5da91028b57c":"客户支持",
"e6e073d7d80064606e77b870ceaf452a":"切换分公司",
"5b79e2bee98d5f41df426c8a1cbad3a4":"正在尝试重新加载",
"2837b35c04c44f52c772b01723ad1ff0":"用户设置",
"5d3d3522e8b4899177ce29532ae479f0":"查看所有分公司",
"39b9368677bb137b328178942025581b":"查看未结订单",
"488b2f58d018e655df26de3ea3f2590c":"书面申请",
};
