import sha256 from 'hash.js/lib/hash/sha/256';

function uuid(str) {
	if (str === undefined) {
		return;
	}
	if (typeof str !== 'string') {
		throw new Error('Passed non-string to Censor.uuid:', str);
	}

	// Credit for UUID regex: http://stackoverflow.com/a/6640851
	var UUIDRegex = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;

	var UUIDList = str.match(UUIDRegex);

	if (UUIDList) {
		UUIDList.forEach(UUID => {
			str = str.replace(
				UUID,
				sha256()
					.update(UUID)
					.digest('hex')
			);
		});
	}
	return str;
}

const censor = {
	uuid: uuid
};
export default censor;
