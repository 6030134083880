import debug from 'debug';
import history from 'lib/history/createHistory';
import store from 'lib/store';
import constants from 'lib/helpers/constants';
import URLParser from 'lib/apps/URLParser';
import { gaPageview } from 'lib/history/ga';
import { message } from 'lib/events/broadcast';
import { actions as appsActions } from 'lib/apps/apps';
import { actions as rootActions } from 'lib/root/root';
import { actions as layoutActions } from 'lib/layout/layout';
import { selectors as appsSelectors } from 'lib/apps/appsSelectors';
import payload from 'lib/history/payload';
import analytics from 'lib/analytics/analytics';

/**
 * Debug logging
 */
const d = debug(`${constants.NS}:history`);

export const initialState = {
	location: null,
	interceptAppId: null
};

export const types = {
	LOCATION_CHANGE: 'ts.chrome/history/LOCATION_CHANGE',
	FRAME_SRC_CURRENT: 'ts.chrome/history/FRAME_SRC_CURRENT',
	FRAME_SRC_CHANGE: 'ts.chrome/history/FRAME_SRC_CHANGE',
	FRAME_SRC_REPLACE: 'ts.chrome/history/FRAME_SRC_REPLACE',
	FRAME_HASH_CHANGE: 'ts.chrome/history/FRAME_HASH_CHANGE',
	INTERCEPT_APP_ID_SET: 'ts.chrome/history/INTERCEPT_APP_ID_SET'
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.LOCATION_CHANGE:
			return {
				...state,
				location: action.payload
			};
		case types.INTERCEPT_APP_ID_SET:
			return {
				...state,
				interceptAppId: action.interceptAppId
			};
		default:
			return state;
	}
};
export default reducer;

const locationChange = url => ({
	type: types.LOCATION_CHANGE,
	payload: url
});
const routerActions = {
	push: url => {
		history.push(url);
		return locationChange(url);
	},
	replace: url => {
		history.replace(url);
		return locationChange(url);
	},
	change: locationChange
};

const setInterceptAppId = interceptAppId => ({
	type: types.INTERCEPT_APP_ID_SET,
	interceptAppId
});

export const actions = {
	setInterceptAppId,
	intercept: (interceptAppId, intercepting = true) => dispatch => {
		if (interceptAppId) {
			dispatch(setInterceptAppId(interceptAppId));
		}
		dispatch(layoutActions.setFullscreen(intercepting));
	},

	frameSrcPushed: url => dispatch => {
		dispatch({ type: types.FRAME_SRC_CURRENT, url });
		if (url) {
			dispatch(routerActions.push(url));
			dispatch(rootActions.setTitle(new URLParser(url, false, 'history:frameSrcPushed').app));
		}
	},
	frameSrcChanged: url => dispatch => {
		dispatch({ type: types.FRAME_SRC_CHANGE, url });
		if (url) {
			dispatch(routerActions.push(url));
			dispatch(rootActions.setTitle(new URLParser(url, false, 'history:frameSrcChanged').app));
		}
	},
	frameSrcReplaced: url => dispatch => {
		dispatch({ type: types.FRAME_SRC_REPLACE, url });
		if (url) {
			dispatch(routerActions.replace(url));
			dispatch(rootActions.setTitle(new URLParser(url, false, 'history:frameSrcReplaced').app));
		}
	},
	frameHashChanged: url => dispatch => {
		dispatch({ type: types.FRAME_HASH_CHANGE, url });
		if (url) {
			dispatch(routerActions.replace(url));
			dispatch(rootActions.setTitle(new URLParser(url, false, 'history:frameHashChanged').app));
		}
	}
};

/**
 * Logic to determine if which app we should load first.
 * It should only do anything if there's nothing in the URL, or we have to intercept.
 *
 * Load `interceptApp` if it's truthy,
 * Otherwise, load `startApp` if there is nothing in the address bar.
 *
 * @returns {string} The currently loaded app.
 */
export const loadFirstApp = data => {
	const { interceptApp, startApp } = data;
	if (interceptApp) {
		store.dispatch(actions.intercept(interceptApp));
		history.replace('/' + interceptApp);
		return appsSelectors.getApp({ apps: data }, interceptApp);
	}

	if (!window.location.hash || window.location.hash === '#/') {
		/**
		 * Load the startApp that is provided by the server if they don't have specific app in url
		 */
		const actualStartApp = startApp || process.config.startApp;
		history.replace(actualStartApp);
		return appsSelectors.getApp({ apps: data }, actualStartApp);
	}
	return new URLParser(history.location, false, `history:loadFirstApp:URL`, data).app;
};

let prevURL = '';

/**
 * Listen to URL changes from anywhere, and hook it into the AppFrame's src
 */
history.listen((location, action) => {
	d('%o: %o', action, location.pathname + location.search + location.hash);
	let prevApp = null;
	let app = null;

	// eslint-disable-next-line default-case
	switch (action) {
		case 'PUSH':
			store.dispatch(routerActions.change(location));
		// eslint-disable-next-line no-fallthrough
		case 'POP':
		case 'REPLACE':
			// Work out the previously loaded app
			prevApp = { id: '', location: { pathname: '' } };
			if (prevURL) {
				prevApp = new URLParser(prevURL, true, `history:${action}:prev`).app;
			}

			app = new URLParser(location, true, `history:${action}:next`).app;

			// Escape intercept fullscreen
			const interceptAppId = store.getState().history.interceptAppId;
			if (interceptAppId && app.id !== interceptAppId) {
				d('InterceptApp finished, exiting fullscreen');
				store.dispatch(actions.intercept(interceptAppId, false));
			}

			const sameAppId = prevApp.id === app.id;
			const aliasedApp = app.alias && app.alias.includes(prevApp.id);
			const sameApp = sameAppId || aliasedApp;
			const samePathname = prevApp.location.pathname === app.location.pathname;
			const sameHref = prevApp.href === app.href;

			// If there's a legacyWrapper frame, don't mess with the internals
			if (app.legacyWrapper && sameApp && samePathname) {
				d('LegacyWrapper-based app navigation without pathname change, ignoring');
				break;
			}

			if (sameHref) {
				d('Navigating to the same URL, ignoring');
				break;
			}

			if (!sameApp || (app.version === 'TS25' && !samePathname)) {
				// Something changed, replacing iframe with new url
				// Or Only TS25 apps different url should replace the url of iframe
				d('openApp %O', app);
				store.dispatch(appsActions.openApp(app));
			} else {
				// Same app, different hash inside the iframe, no need to update the iframe src
				message(constants.PREFIX_CHROME_HASH_CHANGE + app.path, constants.ID_MAIN_FRAME);
			}
			prevURL = location;
			break;
	}

	// Track to Google Analytics
	gaPageview({
		page: location.pathname + location.search + location.hash
	});

	// Track to Segment via ChromeService
	if (app && app.id) {
		analytics('page', payload(app, prevApp, prevURL), app.id);
	}
	if (window.Appcues) {
		window.Appcues.page();
	}
});
