import debug from 'debug';
import io from '@tradeshift/io';
import constants from 'lib/helpers/constants';

/**
 * Debug logging
 */
const d = debug(`${constants.NS}:MaintenanceHandler`);

const maintenance = {
	ready: false,
	nextMaintenance: 0,
	shown: {},
	loopTimeout: null
};

/**
 * Initialize all vars related to maintenance
 */
function init(nextMaintenance) {
	const force = nextMaintenance !== maintenance.nextMaintenance;
	if (!force && maintenance.ready) {
		return;
	}
	maintenance.ready = true;
	maintenance.nextMaintenance = nextMaintenance;

	const minutesLeft = diffMinutes(nextMaintenance);

	constants.MINUTES_LEFT_SHOW_MAINTENANCE.forEach(atMinutesLeft => {
		// Any notifications that we've missed now, should be counted as 'shown'.
		maintenance.shown[atMinutesLeft] = atMinutesLeft >= minutesLeft;
	});
	// We still show the popup, in case the maintenance notification is a few seconds ahead :(
	maintenance.shown[Number.POSITIVE_INFINITY] = minutesLeft < 0;
}

/**
 * Show the Maintenance Notification Popup / Modal
 */
async function showPopup() {
	const [err, data] = await io()
		.top()
		.spawn(process.config.featureApps.maintenancePopup, {
			nextMaintenance: maintenance.nextMaintenance
		});

	if (err) {
		d(
			'Failed to alert the user about the maintenance notification!\nData: %o\nError: %o',
			data,
			err
		);
	}
}

function diffMinutes(timestamp) {
	const now = Date.now();
	return Math.ceil((timestamp - now) / (60 * 1000));
}

/**
 * Main loop to check if we should show the maintenance popup (show it if yes)
 */
function loop() {
	// Reset existing loop
	clearTimeout(maintenance.loopTimeout);

	// Check if we should even try to show anything
	if (maintenance.nextMaintenance <= Date.now()) {
		return;
	}

	// Keep looping
	maintenance.loopTimeout = setTimeout(loop, constants.DURATION_MAINTENANCE_LOOP);

	const minutesLeft = diffMinutes(maintenance.nextMaintenance);
	Array.from(constants.MINUTES_LEFT_SHOW_MAINTENANCE)
		.sort((a, b) => b - a)
		.some(atMinutesLeft => {
			// If we haven't shown the popup when there's a certain time left, but we should
			if (!maintenance.shown[atMinutesLeft] && minutesLeft <= atMinutesLeft) {
				maintenance.shown[atMinutesLeft] = true;
				// Let's show the popup
				showPopup();
				return true;
			}
			return false;
		});
}

export default function handleMaintenance(nextMaintenance) {
	init(nextMaintenance);

	// Whenever there's a change in data, we'll restart the loop, to give the user immediate feedback
	loop();
}
