import segmentCall from './src/segmentCall';

import { getPayload } from './src/analyticsPayload';

export default function analytics(type, payload, appId) {
	if (typeof appId !== 'string') {
		console.warn('Please pass valid appId to analytics call.');
		return Promise.reject('No appId was provided');
	}

	if (!['track', 'page'].includes(type)) {
		console.warn('Please pass a valid analytic type: page or track.');
		return Promise.reject('Only page and track are supported');
	}

	if (type === 'track' && typeof payload.event !== 'string') {
		console.warn('Please pass event in root of the payload');
		return Promise.reject('No event was provided');
	}

	return segmentCall(type, getPayload(payload, appId, type));
}
