const $location = Symbol('location');

/**
 * Object representing an app
 */
export default class App {
	/**
	 * Construct it
	 * @param {object} app
	 */
	constructor(app = {}) {
		/**
		 * App identifier, in the form of `${VendorId}.${AppId}`
		 * @type {string}
		 */
		this.id = app.id || '';
		/**
		 * User-friendly name
		 * @type {string}
		 */
		this.name = app.name || '';
		/**
		 * User-friendly vendor name
		 * @type {string}
		 */
		this.vendor = app.vendor || '';
		/**
		 * URL, directly inserted into the `<img />`'s `src` attribute
		 * It can also be a base64 encoded image
		 * @type {string}
		 */
		this.iconURL = app.iconURL || '';
		/**
		 * App version, possible values: 'V4', 'TS25', 'LEGACY'
		 * @type {string}
		 */
		this.version = app.version || 'V4';
		/**
		 * Should we show an icon for this app?
		 * @type {boolean}
		 */
		this.hidden = !!app.hidden;
		/**
		 * Is this app activated in the current account?
		 * @type {boolean}
		 */
		this.activated = !!app.activated;
		/**
		 * This app has been manually pinned?
		 * @type {number}
		 */
		this.pinned = app.pinned || 0;

		/**
		 * ID of an app to redirect to
		 * @type {string}
		 */
		this.redirect = app.redirect || '';

		/**
		 * Is this a V4 app wrapping a legacy Grails page?
		 * @type {boolean}
		 */
		this.legacyWrapper = !!app.legacyWrapper;

		/**
		 * The same apps,which don't need reload
		 * @type {array}
		 */
		this.alias = app.alias || [];

		/**
		 * Location object of what the App is showing right now.
		 * @see {URLParser}
		 * @type {object}
		 */
		this.location = app.location;

		/**
		 * Is this a fake placeholder app object/
		 * @type {boolean}
		 */
		this.placeholder = app.placeholder || false;

		/**
		 * This app notifications/
		 * @type {boolean|number}
		 */
		this.notification = app.notification || false;
	}

	/**
	 * Clone values from another app
	 * @param app
	 * @returns {App}
	 */
	setFromApp(app) {
		if (typeof app === 'object') {
			Object.keys(app).forEach(appKey => {
				this[appKey] = app[appKey];
			});
			this.iconURL = this.iconURL || app.iconUrl || '';
		}
		return this;
	}

	/**
	 * Get the location object of the App.
	 * @see {URLParser}
	 * @returns {object}
	 */
	get location() {
		return {
			pathname: this[$location].pathname || '',
			search: this[$location].search || '',
			hash: this[$location].hash || ''
		};
	}

	/**
	 * Set the location object of the App.
	 * @see {URLParser}
	 * @returns {object}
	 */
	set location(location) {
		this[$location] = {
			pathname: (location && location.pathname) || '',
			search: (location && location.search) || '',
			hash: (location && location.hash) || ''
		};
	}

	/**
	 * This returns only the part of the path, that the App needs to deal with
	 * @returns {string}
	 */
	get path() {
		const location = this.location;
		return `${location.pathname}${location.search}${location.hash}`;
	}

	/**
	 * This is the whole path, including the appId
	 * @returns {string}
	 */
	get href() {
		const root = this.redirect || this.id;
		return `/${root}${this.path}`;
	}

	/**
	 * Serialize to a simple object
	 * @returns {object}
	 */
	toJSON() {
		return {
			id: this.id,
			name: this.name,
			vendor: this.vendor,
			iconURL: this.iconURL,
			version: this.version,
			hidden: this.hidden,
			activated: this.activated,
			pinned: this.pinned,
			redirect: this.redirect,
			legacyWrapper: this.legacyWrapper,
			alias: this.alias,
			href: this.href,
			placeholder: this.placeholder,
			notification: this.notification
		};
	}

	/**
	 * Serialize from a simple object
	 * @param {object} json
	 * @returns {App}
	 */
	static fromJSON(json) {
		return new this(json);
	}
}
