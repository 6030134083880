module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Kokoelma tuotteita, joita haluat tarjota myytäväksi tietylle ostajalle.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Asiakirja, jonka myyjä on antanut ostajalle ja jossa ilmoitetaan tarjottujen tuotteiden tai palvelujen määrät ja kustannukset.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Alustava myyntilasku, joka on lähetetty ennen tavaroiden lähettämistä tai toimitusta.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Tili on ajoitettu poistettavaksi.",
"4e917d587825921098d19cb5374ffa54":"Lisää sovellus suosikkeihin",
"7328b929c3b7ea8a93ee9e597817f510":"Kaikki sovellukset",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Nuolikuvake",
"421d54e688f76e063b53b1bd67178b42":"Käytettävissä olevan sovellukset",
"614bb33c219a0809bbe8e52f938dcce7":"Puiteosto (pyyntö)",
"51d895d6183265b06e899570e19eecf6":"Sivukonttorin logo",
"9d6641632312bcd817a18331b01bd073":"Selaa ja etsi ostettavissa olevia tuotteita.",
"883d14b9efdb4894d9879c0eff9b5acb":"Tarkista alustan status tai ota yhteyttä tukeen.",
"55fc4360777c0241e107f70379e57e75":"Sulje valikko",
"da76f49c6f0252b80a6e330d6394c094":"Vahvista tuotteiden ja palveluiden vastaanottaminen avoimen ostotilauksen perusteella.",
"67f2b8b530a56e35ec1e8254fb72748f":"Yhteyspyyntö",
"9860a293ae95e8583f868975af1f4849":"Ota yhteyttä tukeen",
"210d71bea3790d832babc970a6674be3":"Muunna avoin tilaus laskuksi.",
"8df8c2d028d750d31bb796681c2ea3f9":"Luo testilasku",
"e3f60bea8ce9079c56a6a69f86f4f048":"Luo hyvityslasku",
"5b5efdc0c8989fe1056b0e54734e824d":"Luo lasku",
"fadd16ebdc81e4fde9e7fc3154877894":"Luo Prepayment Invoice",
"0e1cf03ccaae390506a0da5f0f739280":"Luo proformalasku",
"20c9ff16bb2f0448290a5087a58cdebf":"Luo ja lähetä tarjousasiakirjoja asiakkaillesi.",
"830fa5e28d0b88eb70218ea763f71c5c":"Luo puiteostotilaus",
"d75c3f6646800bc987c0c07f986b6111":"Luo uusi tuote",
"9f7cd528570ea5fa70d5f0fdb8227759":"Luo tarjous",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Luo tilaus",
"9f2650a5abb1e17ffb4714580cc4c457":"Luo tuote",
"581c02d2262d5818fb14faef2a5c5e4e":"Luo tarjous",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Tee kirjallinen pyyntö",
"3f884136e59449da353adefd80121181":"Luo...",
"d23014cf5a5e7f8c6440b96692831aa8":"Hyvityslasku",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Määrittele tuotteiden tai palveluiden (arvio) hinnat ja sisäiset budjetit luodaksesi ostotilauksia tiettynä aikajaksona.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Määrittele Tradeshift-markkinapaikoilla myytävät tuotteet.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Määritä pyyntö tuotteelle tai palvelulle, jota ei löytynyt kaupasta, ja lisää se ostoskoriisi tai ostopyyntön.",
"1b0dd1aca58f84099cff422597422e56":"Tradeshiftin lataaminen epäonnistui",
"2ca056bf517beb52194e04b1d425b85d":"Tradeshiftin lataaminen epäonnistui,",
"901bcad22b5486ef2185cb1e59e0a234":"Tavaraanvastaanotto",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Yksinkertaista hallinnointia ryhmittelemällä yrityksen verkkoryhmiin",
"d7b639ae724bad4cb10abf6e411bd74e":"Asennetut sovelluksesi",
"3c4be400f728da4526de626653d56ccc":"Lasku",
"697ca358c7443b09bab9478598f14726":"Lasku tilauksesta",
"db13bed8b517884b5e8e257c3e829e7d":"Myönnä luottoa tulevia ostoja vastaan.",
"8535934d42d580306640d88e0ed217aa":"Ladataan yritystä…",
"802ed138bb62eb56ff187f839234478b":"Ladataan...",
"1c8e8813d12a483823656cc7c245cd58":"Kirjaudu ulos",
"b25df08fcf0d0acaf6384653ea02141e":"Kirjaudu ulos",
"34bea6b12f16fea4889a2a80c07ee8ec":"Hallitse ryhmiä",
"7a558a55eb2d9e641f608f759964a9f6":"Luo myyjälle ostotilaus manuaalisesti ilmoittamalla ehdotetut tavarat tai palvelut, määrät ja hinnat.",
"fbb9cc68fee07e74094da1030bfdf236":"Verkkoryhmä",
"ba116f853ca15c3b6f136da33867158b":"Mikään sovellus ei vastaa hakusanaasi.",
"14877050aa06ad5917e9d7a7048ad2e3":"Ei kuvausta.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Ei käyttöoikeuksia",
"bacad0fc8777924b7400a5b05b8cc715":"Yritystilisi ja Tradeshift-käyttäjätilisi poistetaan {deletionDate}. Jos kyseessä on virhe, voit peruuttaa sen.",
"2778717f6acbeb1edaac6a2656b478f3":"Avaa",
"37f066e2502bac5316dfc900890ade4c":"Avaa valikko",
"018c4048ea00acea67a5819288f4703f":"Tilaus",
"2ae053867612b2202a870bd52cc3ff63":"Alustan tila",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Ota yhteyttä tilin ylläpitäjään, jos tahdot muokata käyttöoikeuksiasi.",
"281f9682838b746f5a0b8a4555614843":"Ennakkomaksulasku",
"387117ac2a32235f30c143484f94147f":"Proformalasku",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Osto kaupasta",
"9bb4a36de6bff47eb4209096972979dd":"Tarjous",
"befc83a6b9e66cbfb953b901b91290d0":"Suosikkien enimmäismäärä saavutettu!",
"beb839cf63984c0634d05bdc900e1905":"Vastaanota tavaroita tilauksesta",
"d1b0cf6017a647b49abde5b8ba6fe614":"Viimeksi käytetyt sivukonttorit",
"793f04b605fc32835a578962a6994422":"Tallenna ennakkomaksu.",
"4eddd0ca790e495626f275843c87f440":"Lataa uudelleen",
"ab8100964aeb93c35539b88420121939":"Poista sovellus suosikeista",
"d817327a5392f2d398ed8721a314bc37":"Hae sovelluksia",
"31e12c8c15eae192e29acf9fcb949287":"Etsi yrityksiä Tradeshift-verkostosta liiketoimintaa varten.",
"3bcee8f61c10123abe392bf0cc31f99c":"Hae yritystä",
"4716fe18b1d4f74edf1a1cdacc83b329":"Kauppa",
"50b5a47e8f2e9f988f509e248860acd0":"Näytä toiminnot",
"a563aabceadd081e01b44f50d0c9ba26":"31. maaliskuuta 2021 alkaen Tradeshift-sovellukset eivät välttämättä ole yhteensopivia Internet Explorerin versioon 11. Päivitä selaimesi alustan tukemaan versioon.",
"3ad73c1b179af6e6df8e5da91028b57c":"Tuki",
"e6e073d7d80064606e77b870ceaf452a":"Vaihda sivukonttori",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Yritä sivun lataamista uudelleen.",
"2837b35c04c44f52c772b01723ad1ff0":"Käyttäjän asetukset",
"5d3d3522e8b4899177ce29532ae479f0":"Näytä kaikki sivukonttorit",
"39b9368677bb137b328178942025581b":"Näytä avoimet tilaukset",
"488b2f58d018e655df26de3ea3f2590c":"Kirjallinen pyyntö",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Sinulla ei ole oikeuksia suorittaa tätä toimintoa.",
"1bf011c064e4459d5665ff6548bb3c04":"Tilisi on ajoitettu poistettavaksi.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Yritystilisi sekä Tradeshiftin käyttäjätilisi on ajoitettu poistettavaksi {deletionDate}. Jos kyseessä on virhe, ota yhteyttä tilin ylläpitäjään.",
};
