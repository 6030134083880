module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"特定のバイヤーに販売のために提供したい製品一覧",
"b9b2bec3b24aa4b9a601ab046cd12009":"サプライヤーがバイヤーに発行する、提供された商品の数量や価格を記載し、請求する文書。",
"5b24efebe4bd25a8c5e3aca06197e35e":"商品の出荷または配送に先立って送付される仮の請求書。",
"4e917d587825921098d19cb5374ffa54":"お気に入りに追加",
"7328b929c3b7ea8a93ee9e597817f510":"すべてのアプリ",
"baaa8b0af4b7c2a73cdd46756902f9dd":"矢印アイコン",
"421d54e688f76e063b53b1bd67178b42":"利用可能なアプリ",
"614bb33c219a0809bbe8e52f938dcce7":"ブランケットオーダー(要求)",
"51d895d6183265b06e899570e19eecf6":"支店のロゴ",
"9d6641632312bcd817a18331b01bd073":"購入可能な商品をブラウズして検索します。",
"883d14b9efdb4894d9879c0eff9b5acb":"プラットフォームの状態を確認するか、サポートにお問い合わせください。",
"55fc4360777c0241e107f70379e57e75":"メニューを閉じる",
"da76f49c6f0252b80a6e330d6394c094":"注文書に基づいた商品の受領を通知する文書",
"67f2b8b530a56e35ec1e8254fb72748f":"ネットワークリクエスト",
"9860a293ae95e8583f868975af1f4849":"サポートに連絡する",
"210d71bea3790d832babc970a6674be3":"注文書をコピーして請求書を作成",
"8df8c2d028d750d31bb796681c2ea3f9":"新規作成",
"e3f60bea8ce9079c56a6a69f86f4f048":"クレジットノートの作成",
"5b5efdc0c8989fe1056b0e54734e824d":"請求書の作成",
"fadd16ebdc81e4fde9e7fc3154877894":"前払い請求書の作成",
"0e1cf03ccaae390506a0da5f0f739280":"プロフォーマインボイスの作成",
"20c9ff16bb2f0448290a5087a58cdebf":"顧客に見積書を作成して送信します。",
"830fa5e28d0b88eb70218ea763f71c5c":"ブランケットオーダーの作成",
"d75c3f6646800bc987c0c07f986b6111":"新しい製品を作成",
"9f7cd528570ea5fa70d5f0fdb8227759":"オファーの作成",
"e778d3c66bb24ea357fcbaacb2e4bab9":"注文書の作成",
"9f2650a5abb1e17ffb4714580cc4c457":"商品データ作成",
"581c02d2262d5818fb14faef2a5c5e4e":"見積書を作成する",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"カタログ外購入リクエストを作成",
"3f884136e59449da353adefd80121181":"新規作成...",
"d23014cf5a5e7f8c6440b96692831aa8":"クレジットノート",
"a9616b4df87bbe0147d76ca08f1a4ad3":"（推定）価格と内部予算を使用して製品またはサービスアイテムを定義し、定義された期間の注文書を作成します。",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Tradeshiftマーケットプレイスで販売する商品を定義します。",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"ショップで見つけることができなかった商品やサービスへのリクエストを定義し、バスケットまたは購入リクエストに追加します。",
"1b0dd1aca58f84099cff422597422e56":"Tradeshiftへの接続に失敗しました",
"2ca056bf517beb52194e04b1d425b85d":"Tradeshiftへの接続に失敗しました。",
"901bcad22b5486ef2185cb1e59e0a234":"検収書",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"グループ会社をネットワークグループに含めることで管理がシンプルになります",
"d7b639ae724bad4cb10abf6e411bd74e":"有効化されているアプリ",
"3c4be400f728da4526de626653d56ccc":"請求書",
"697ca358c7443b09bab9478598f14726":"注文書からの請求書",
"db13bed8b517884b5e8e257c3e829e7d":"販売した商品に対し、返金を約束する書類。",
"8535934d42d580306640d88e0ed217aa":"会社情報の読み込み中…",
"802ed138bb62eb56ff187f839234478b":"読み込み中...",
"1c8e8813d12a483823656cc7c245cd58":"ログアウト",
"b25df08fcf0d0acaf6384653ea02141e":"ログアウト",
"34bea6b12f16fea4889a2a80c07ee8ec":"グループの管理",
"7a558a55eb2d9e641f608f759964a9f6":"商品またはサービス、数量および価格を示し、注文書を手動で作成します。",
"fbb9cc68fee07e74094da1030bfdf236":"ネットワークグループ",
"ba116f853ca15c3b6f136da33867158b":"検索条件に一致するアプリはありません。",
"14877050aa06ad5917e9d7a7048ad2e3":"説明はありません。",
"b6f4ed6dd31b74d156a22c04e9e02199":"権限がありません",
"2778717f6acbeb1edaac6a2656b478f3":"開く",
"37f066e2502bac5316dfc900890ade4c":"メニューを開く",
"018c4048ea00acea67a5819288f4703f":"注文書",
"2ae053867612b2202a870bd52cc3ff63":"ステータスページ",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"権限が修正される必要があると思われる場合は、テナントの管理者にお問い合わせください。",
"281f9682838b746f5a0b8a4555614843":"前払い請求書",
"387117ac2a32235f30c143484f94147f":"プロフォーマインボイス",
"b2562e71feb3c0a4d25d8b4aeacd545f":"ショップから購入",
"9bb4a36de6bff47eb4209096972979dd":"見積書",
"befc83a6b9e66cbfb953b901b91290d0":"お気に入りの最大数に達しました",
"beb839cf63984c0634d05bdc900e1905":"注文書から検収書を発行する",
"d1b0cf6017a647b49abde5b8ba6fe614":"最近使用したログイン先",
"793f04b605fc32835a578962a6994422":"事前支払いを記録する",
"4eddd0ca790e495626f275843c87f440":"再読み込み",
"ab8100964aeb93c35539b88420121939":"お気に入りから削除します",
"d817327a5392f2d398ed8721a314bc37":"アプリの検索",
"31e12c8c15eae192e29acf9fcb949287":"Tradeshiftネットワーク上の企業を検索します。",
"3bcee8f61c10123abe392bf0cc31f99c":"企業を検索します。",
"4716fe18b1d4f74edf1a1cdacc83b329":"ショップ",
"50b5a47e8f2e9f988f509e248860acd0":"アクションの表示",
"3ad73c1b179af6e6df8e5da91028b57c":"サポート",
"e6e073d7d80064606e77b870ceaf452a":"ログイン先変更",
"5b79e2bee98d5f41df426c8a1cbad3a4":"ページの再読み込みをしてください。",
"2837b35c04c44f52c772b01723ad1ff0":"ユーザー設定",
"5d3d3522e8b4899177ce29532ae479f0":"すべてのログイン先を表示",
"39b9368677bb137b328178942025581b":"未処理の注文を表示",
"488b2f58d018e655df26de3ea3f2590c":"カタログ外購入",
"7afb6c3060810bd8e4f55a2e42fd69c0":"操作を行う権限がありません．",
};
