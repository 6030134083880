import debug from 'debug';
import constants from 'lib/helpers/constants';
import store from 'lib/store';
import { get } from 'lib/helpers/fetch';
import { isStandalone, sleep } from 'lib/helpers/helpers';
import { actions as rootActions } from 'lib/root/root';

/**
 * Debug logging
 */
const d = debug(`${constants.NS}:KeepAlive`);

export default class KeepAlive {
	static async start() {
		let loop = !isStandalone();
		while (loop) {
			try {
				await this.updateAccessToken();
				await this.checkLoginState();
				await sleep(constants.DURATION_KEEPALIVE_REPEAT);
			} catch (e) {
				loop = false;
				d('Error in KeepAlive: %o', e);
			}
		}
	}

	static async checkLoginState() {
		try {
			d('Checking if user is still logged in...');
			const res = await get(process.config.urls.keepAliveURL);
			if (!res || !res.loggedIn) {
				d('User is not logged in anymore, forcing logout.');
				store.dispatch(rootActions.logout());
			}
		} catch (e) {
			d('Error in loginState checker: %o', e);
		}
	}

	static async updateAccessToken() {
		if (isStandalone()) {
			return;
		}
		try {
			d('Checking for new accessToken...');
			const res = await get(process.config.urls.accessTokenURL, {}, { noAuth: true });
			if (res && res.accessToken && res.accessToken !== process.config.request.accessToken) {
				d(
					'Updating accessToken to %O (was %O)',
					{ accessToken: res.accessToken },
					{ accessToken: process.config.request.accessToken }
				);
				process.config.request.accessToken = res.accessToken;
			}
		} catch (e) {
			d('Error in accessToken check: %o', e);
		}
	}
}
