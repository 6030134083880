import React from 'react';
import PropTypes from 'prop-types';
import DeferredImage from 'components/DeferredImage';
import constants from 'lib/helpers/constants';
import 'components/apps/appicon/appIcon.less';

const getAppIcon = (iconURL, hadError) => {
	if (hadError) {
		return `${process.env.PUBLIC_URL}/${process.config.defaultAppIcon}`;
	} else {
		return iconURL;
	}
};

class AppIcon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hadError: false
		};
	}
	render() {
		return (
			<DeferredImage
				src={getAppIcon(this.props.iconURL, this.state.hadError)}
				className={['tst-app-icon', constants.CLASS_APP_ICON_IMG].join(' ')}
				onError={() => {
					this.setState({
						hadError: true
					});
				}}
				alt={this.props.alt}
			/>
		);
	}
}
AppIcon.propTypes = {
	iconURL: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired
};

export default AppIcon;
