import debug from 'debug';
import constants from 'lib/helpers/constants';
import store from 'lib/store';
import history from 'lib/history/createHistory';
import URLParser from 'lib/apps/URLParser';
import broadcast from 'lib/events/broadcast';
import { setLanguage } from 'lib/language/language';
import { actions as historyActions } from 'lib/history/hashHistory';
import { actions as inboxActions } from 'lib/inbox/inbox';
import { actions as rootActions } from 'lib/root/root';
import { actions as layoutActions } from 'lib/layout/layout';
import { actions as framesActions } from 'lib/frames/frames';
import { actions as createActionModalActions } from 'lib/apps/createActionsModal';
import { removeExtraSlashes } from 'lib/helpers/helpers';
import { topApp } from 'lib/events/IOEventHandler';
import { setBranchSwitchLog } from 'lib/localStorage';

/**
 * Debug logging
 */
const d = debug(`${constants.NS}:EventHandler`);

/**
 * Shorthands for all the message prefixes
 */
const [tsAppBroadcast, spiritualBroadcast, frameSrcCurrentBroadcast] = [
	constants.PREFIX_TS_APP_BROADCAST,
	constants.PREFIX_SPIRITUAL_BROADCAST,
	constants.PREFIX_FRAME_SRC_CURRENT
];

/**
 * Listen to all compatible postMessage events and handle them
 * @implements EventListener
 */
export default class EventHandler {
	/**
	 * Add the listener
	 */
	static init() {
		window.addEventListener('message', EventHandler.handleEvent);
	}

	/**
	 * Handle the event
	 * @param {MessageEvent} event
	 * @returns {boolean|void} false on error
	 */
	static handleEvent(event) {
		if (!event || !event.data || typeof event.data !== 'string') {
			return false;
		}

		const message = event.data;
		const type = EventHandler.getMessageType(message);
		if (!type) {
			return false;
		}

		let data = {};
		let rawData = message.substring(type.length);

		if (type !== frameSrcCurrentBroadcast) {
			try {
				data = JSON.parse(rawData);
			} catch (e) {
				console.warn(`Error while parsing message: ${e}`, event);
			}
		} else {
			data = rawData;
		}

		// eslint-disable-next-line default-case
		switch (type) {
			case tsAppBroadcast:
				return EventHandler.tsAppBroadcastHandler(data, message);
			case spiritualBroadcast:
				return EventHandler.spiritualBroadcastHandler(data, event);
			case frameSrcCurrentBroadcast:
				return EventHandler.frameSrcCurrentBroadcastHandler(data, event);
		}
	}

	/**
	 * Handle broadcast from `ts.app.broadcast()`
	 * @param {object} data
	 * @param {string|array} data.appIds AppIds to forward the message to
	 * @param {*} message Message from inside the MessageEvent
	 * @returns {boolean|void} false on error
	 */
	static tsAppBroadcastHandler(data, message) {
		if (!data.appIds || !data.key) {
			return false;
		}
		const isString = typeof data.appIds === 'string';
		const isArray = Array.isArray(data.appIds);
		if (!(isString ^ isArray)) {
			return false;
		}

		let appIds = data.appIds;

		if (isString) {
			appIds = [data.appIds];
		}

		d('%o, appIds: %o, key: %o, data: %o,', 'ts.app.broadcast', data.appIds, data.key, data.data);

		appIds.forEach(appId => {
			let frameContentWindow;
			switch (appId) {
				case process.config.featureApps.chrome:
					EventHandler.chromeHandler(data);
					break;
				case process.config.featureApps.inbox:
					frameContentWindow = EventHandler.getContentWindow(constants.ID_INBOX_FRAME);
					break;
				case process.config.featureApps.collaboration:
					frameContentWindow = EventHandler.getContentWindow(constants.ID_COLLABORATION_FRAME);
					break;
				default:
					frameContentWindow = EventHandler.getContentWindow(constants.ID_MAIN_FRAME);
					break;
			}
			if (frameContentWindow) {
				frameContentWindow.postMessage(message, '*');
			}
		});
	}

	/**
	 * Handle Chrome-related events
	 * @param {object} messageData
	 * @param {string} messageData.key The key of the action to perform
	 * @param {*} messageData.data
	 */
	static chromeHandler(messageData) {
		const { key, data } = messageData;
		switch (key) {
			case constants.EVENT_INBOX_UNREAD_SET:
				store.dispatch(inboxActions.setUnread(!!data));
				break;

			case constants.EVENT_COLLABORATION_OPEN:
				store.dispatch(layoutActions.setCollaboration(true));

				if (data) {
					if (data.url) {
						store.dispatch(
							framesActions.setFrame({
								target: constants.FRAMES.COLLABORATION,
								src: data.url
							})
						);
						// hack to click tasklist when you click again
						if (window[constants.ID_COLLABORATION_FRAME]) {
							window[constants.ID_COLLABORATION_FRAME].location.reload();
						}
						return;
					}

					console.warn(`
 						Passing conversation data using "ts-collaboration-open"
 						directly will soon be deprecated! Please use CollaborationService or
 						provide a "url" string in the event data instead.
 					`);
					const baseURL = process.config.urls.collaborationURL + '&';
					const search = Object.keys(data)
						.map(k => `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`)
						.join('&');
					store.dispatch(
						framesActions.setFrame({
							target: constants.FRAMES.COLLABORATION,
							src: baseURL + search
						})
					);
				}

				break;

			case constants.EVENT_COLLABORATION_CLOSE:
				/**
				 * Clear Collaboration frame when conversation is closed
				 */
				store.dispatch(
					framesActions.setFrame({
						target: constants.FRAMES.COLLABORATION,
						src: 'about:blank'
					})
				);
				store.dispatch(layoutActions.setCollaboration(false));
				break;

			case constants.EVENT_CHROME_MENU_OPEN:
				store.dispatch(layoutActions.setMenu(true, true));
				break;
			case constants.EVENT_CHROME_MENU_CLOSE:
				store.dispatch(layoutActions.setMenu(false, true));
				break;

			case constants.EVENT_APP_ENTER_FULLSCREEN:
				store.dispatch(layoutActions.setFullscreen(true));
				break;
			case constants.EVENT_APP_LEAVE_FULLSCREEN:
				store.dispatch(layoutActions.setFullscreen(false));
				break;

			case constants.EVENT_LAYOUT_SEND:
				if (data && data.app) {
					const state = store.getState();
					broadcast(data.app, constants.EVENT_LAYOUT_SEND, {
						section: state.menu.section,
						menu: state.layout.menu
					});
				}
				break;

			case constants.EVENT_CHROME_CREATE_MODAL_OPEN:
				store.dispatch(createActionModalActions.showCreateActionsModal());
				break;

			case constants.EVENT_SET_LOCALE:
				setLanguage(data.locale);
				break;

			// eslint-ignore-next-line no-fallthrough
			case constants.EVENT_REFRESH_CHROME:
				if (data) {
					const keys = ['userId', 'companyId'];
					if (data.softRefresh) {
						const hasChanges = keys.some(k => data[k] && data[k] !== process.config.request[k]);
						if (!hasChanges) {
							return;
						}
					}
					/**
					 * No need to reload if only the requestId changed,
					 * but we should still save it.
					 */
					keys.push('requestId');
					keys.forEach(k => {
						if (data[k] && process.config.request[k] !== data[k]) {
							d(
								`chromeHandler:%o - %o set to %o (was %o)`,
								constants.EVENT_REFRESH_CHROME,
								k,
								data[k],
								process.config.request[k]
							);
							process.config.request[k] = data[k];
						}
					});
				}
			/**
			 * !!! Intentionally falling through !!!
			 */
			// eslint-ignore-next-line no-fallthrough
			case constants.EVENT_APP_ACTIVATE:
			case constants.EVENT_APP_DEACTIVATE:
				store.dispatch(rootActions.getData());
				break;

			case constants.EVENT_UI_APP_UPDATES:
				const url = new URLParser(
					history.location,
					false,
					'EventHandler:frameSrcCurrentBroadcastHandler:main'
				);
				topApp().emit(
					constants.EVENT_APP_UPDATES,
					{ ...data.Properties, currentApp: url.app.id },
					process.config.featureApps.chromeService
				);
				break;

			case constants.EVENT_CHANGE_MAIN_FRAME_URL:
				store.dispatch(
					historyActions.frameSrcChanged(
						new URLParser(data, false, `EventHandler:spiritualBroadcastHandler:main`).url
					)
				);
				break;

			case constants.EVENT_REPLACE_MAIN_FRAME_URL:
				store.dispatch(
					historyActions.frameSrcReplaced(
						new URLParser(data, false, `EventHandler:spiritualBroadcastHandler:main`).url
					)
				);
				break;

			case constants.EVENT_BRANCH_SWITCH_LOG:
				const currentBranch = {
					id: window.ts.chrome.config.companyId.valueOf(),
					name: store.getState().menu.user.companyName
				};
				window.ts.chrome.config.companyId = data.branch.id;
				// log the current active branch
				setBranchSwitchLog(currentBranch);
				setBranchSwitchLog(data.branch);
				store.dispatch(rootActions.addSwitchedBranch(currentBranch));
				store.dispatch(rootActions.addSwitchedBranch(data.branch));
				break;

			default:
				console.warn(`Unknown event ${key} passed to ${process.config.featureApps.chrome}`);
		}
	}

	/**
	 * Handle broadcast from `gui.Broadcast.dispatchGlobal`
	 * @param {object} b Broadcast itself
	 * @param {string} b.type Broadcast type
	 * @param {*} b.data Broadcast data
	 * @param {MessageEvent} event
	 * @returns {boolean} false on error
	 */
	static spiritualBroadcastHandler(b, event) {
		const valid =
			b &&
			typeof b === 'object' &&
			b.type &&
			typeof b.type === 'string' &&
			b.data !== undefined &&
			!b.type.startsWith('edb-') &&
			!b.type.startsWith('gui-') &&
			!b.type.startsWith('ts-broadcast-g') &&
			event &&
			event.source &&
			allowLocationEvents(event.source);
		if (!valid) {
			return false;
		}

		d(
			'%o, type: %o, broadcast: %o, source: %o, event: %O',
			'gui.Broadcast.dispatchGlobal',
			b.type,
			b,
			getFrameStrangeName(event),
			event
		);

		/**
		 * Get the topFrame URL
		 * @param object} b Broadcast data
		 * @returns {string}
		 */
		const getTopFrameURL = b =>
			new URLParser(b.data, false, `EventHandler:spiritualBroadcastHandler:${b.type}`).url;

		// eslint-disable-next-line default-case
		switch (b.type) {
			case constants.EVENT_FRAME_SRC_CHANGE:
				store.dispatch(historyActions.frameSrcChanged(getTopFrameURL(b)));
				break;
			case constants.EVENT_FRAME_SRC_REPLACE:
				store.dispatch(historyActions.frameSrcReplaced(getTopFrameURL(b)));
				break;
			case constants.EVENT_FRAME_HASH_CHANGE:
				EventHandler.frameHashChangedBroadcastHandler(b, event);
				break;
		}
	}

	/**
	 * Handle broadcast for `ts-frame-hash-change`
	 * @param {object} b Broadcast itself
	 * @param {string} b.type Broadcast type
	 * @param {*} b.data Broadcast data
	 * @param {MessageEvent} event
	 * @returns {boolean} false on error
	 */
	static frameHashChangedBroadcastHandler(b, event) {
		try {
			const currentApp = new URLParser(
				// If the app is from the different origin it would cause a CORS error
				// Since we know this is not the case for legacy apps, it safe to ignore this error
				event.source.location.href,
				false,
				'EventHandler:frameHashChangedBroadcastHandler:prev'
			).app;
			if (currentApp && currentApp.legacyWrapper && !isLegacyFrame(event.source)) {
				d('LegacyWrapper-based broadcast from the non-legacy frame, ignoring');
				return false;
			}
		} catch (e) {
			console.error(
				'Error during LegacyWrapper-based broadcast from the non-legacy frame ignoring :',
				e
			);
		}

		store.dispatch(
			historyActions.frameHashChanged(
				new URLParser(
					// We only get the new app path, we need to prepend appId
					history.location.pathname.replace(
						constants.REGEX_APP_PATH,
						(m, appId, path) => `${appId}${removeExtraSlashes(b.data)}`
					),
					false,
					`EventHandler:frameHashChangedBroadcastHandler:next`
				).url
			)
		);
	}

	/**
	 * Handle the `ts-frame-url-current` event
	 * @param {object} data
	 * @param {*|void} data.error Defined if there's an error indicated in the message
	 * @param {MessageEvent} event
	 * @returns {boolean} false on error
	 */
	static frameSrcCurrentBroadcastHandler(data, event) {
		const valid = event && event.source && allowLocationEvents(event.source) && data;
		if (!valid) {
			return false;
		}
		if (data.error) {
			console.warn('Error in AppFrame event', data.error);
			return false;
		}
		if (typeof data !== 'string') {
			return false;
		}

		d(
			'%o, source: %o, data: %o, event: %O',
			'ts-frame-url-current',
			getFrameStrangeName(event),
			data,
			event
		);

		const nextURLObj = new URLParser(
			data,
			false,
			'EventHandler:frameSrcCurrentBroadcastHandler:next'
		);
		const nextURL = removeExtraSlashes(nextURLObj.url);

		const prevURLObj = new URLParser(
			history.location,
			false,
			'EventHandler:frameSrcCurrentBroadcastHandler:prev'
		);
		const prevURL = removeExtraSlashes(prevURLObj.url);

		/**
		 * If the previous app WAS NOT legacyWrapper, but the current is,
		 * DO NOT ignore legacyWrapper events from a non-legacy frame.
		 * (This will help to load the app in a new iframe, including the legacyWrapper frame).
		 *
		 * If the previous app WAS legacyWrapper, and the current is as well,
		 * then it’s safe to ignore legacyWrapper events from a non-legacy frame.
		 * (This will help to not reload the page unnecessarily)
		 */
		if (
			prevURLObj.app.legacyWrapper &&
			nextURLObj.app &&
			nextURLObj.app.legacyWrapper &&
			!isLegacyFrame(event.source)
		) {
			d('LegacyWrapper-based event from the non-legacy frame, ignoring');
			return false;
		}

		const isNewURL = prevURL !== nextURL;

		if (isNewURL) {
			store.dispatch(historyActions.frameSrcReplaced(nextURL));
		}
	}

	/**
	 * Get type of message based on prefixes
	 * @param {string} message
	 * @returns {string|void} Prefix if found, false on error
	 */
	static getMessageType(message) {
		if (message.startsWith(tsAppBroadcast)) {
			return tsAppBroadcast;
		} else if (message.startsWith(spiritualBroadcast)) {
			return spiritualBroadcast;
		} else if (message.startsWith(frameSrcCurrentBroadcast)) {
			return frameSrcCurrentBroadcast;
		}
		return false;
	}

	/**
	 * Shorthand to get the `contentWindow` of an element, preferably an iframe
	 * @param {string} id ID of an iframe
	 * @returns {Window|null}
	 */
	static getContentWindow(id) {
		const elem = window.document.getElementById(id);
		return elem ? elem.contentWindow : null;
	}
}

/**
 * Is the window a Tradeshift.LegacyWrapper container,
 * that contains another iframe that has the Grails app inside?
 * See https://github.com/Tradeshift/Apps/tree/master/src/main/v4apps/chrome/LegacyWrapper
 * Legacy apps: https://github.com/Tradeshift/Apps/tree/master/src/main/v4apps/chrome/legacy
 * @param {Window} win
 * @returns {boolean}
 */
function isLegacyFrame(win) {
	try {
		return win.frameElement.classList.contains(constants.CLASS_LEGACY_FRAME);
	} catch (xdomainexception) {
		return false;
	}
}

/**
 * Is the window a fixed, special frame, like:
 * 'MAIN', 'INBOX', 'COLLABORATION' or 'SERVICE'?
 * @see src/lib/frames/frames.js
 * @param {Window} win
 * @returns {boolean}
 */
function isSpecialFrame(name, win) {
	try {
		if (!win) {
			return false;
		}
		if (!constants[`ID_${name}_FRAME`]) {
			return false;
		}
		const specialFrameWindow = document.getElementById(constants[`ID_${name}_FRAME`]);
		if (!specialFrameWindow) {
			return false;
		}
		if (!specialFrameWindow.contentWindow) {
			return false;
		}
		return win === specialFrameWindow.contentWindow;
	} catch (xdomainexception) {
		return false;
	}
}

/**
 * Should we allow events related to navigation from a window?
 * @param {Window} win
 * @returns {boolean}
 */
function allowLocationEvents(win) {
	try {
		return (
			isSpecialFrame('MAIN', win) ||
			isSpecialFrame('INBOX', win) ||
			isSpecialFrame('COLLABORATION', win) ||
			isLegacyFrame(win)
		);
	} catch (e) {
		return false;
	}
}

/**
 * Attempt to read the className OR name of the hosting
 * iframe. Setup to not fail on cross domain event source.
 * @param {MessageEventt} event
 * @returns {string}
 */
function getFrameStrangeName(event) {
	const src = (event || {}).source || {};
	try {
		const iframe = src.frameElement || {};
		return iframe.className || iframe.name || '';
	} catch (xdomainexception) {
		return '';
	}
}
