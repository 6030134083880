import debug from 'debug';
import constants from 'lib/helpers/constants';
import { hasDevice } from 'lib/localStorage';

const d = debug(`${constants.NS}:layout:responsive`);

const [LARGE, MEDIUM, SMALL, MOBILE] = [
	constants.DEVICES.LARGE,
	constants.DEVICES.MEDIUM,
	constants.DEVICES.SMALL,
	constants.DEVICES.MOBILE
];

const deviceFallbacks = {};
deviceFallbacks[LARGE] = [LARGE, MEDIUM, SMALL, MOBILE];
deviceFallbacks[MEDIUM] = [MEDIUM, LARGE, SMALL, MOBILE];
deviceFallbacks[SMALL] = [SMALL, MEDIUM, LARGE, MOBILE];
deviceFallbacks[MOBILE] = [MOBILE];

/**
 * Gets the nearest available deviceType.
 * A deviceType might not be available if it's been disabled using window.ts.chrome.debug.setDevice()
 * @see 'lib/helpers/debug.js'
 */
function getAvailableDeviceType(deviceType) {
	const fallbacks = deviceFallbacks[deviceType];
	if (fallbacks) {
		const availableDeviceType = fallbacks.find(fallback => {
			if (hasDevice(fallback)) {
				return true;
			}
			return false;
		});
		if (availableDeviceType) {
			d('%o', Object.keys(constants.DEVICES)[availableDeviceType]);
			return availableDeviceType;
		}
	}
	d('%o', 'MOBILE');
	return MOBILE;
}

function getDeviceType() {
	const windowWidth =
		window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
	// const openMenuWidth = constants.WIDTH_MENU; // 360
	const closedMenuWidth = constants.LENGTH_MENU_ICON; // 60
	const collaborationWidth = constants.WIDTH_COLLABORATION; // 400
	const mainframeMinWidth = 1024 - closedMenuWidth; // 964
	const mobileWidth = 600; // ts.ui.BREAKPOINT_MOBILE

	const largeStart = mainframeMinWidth + collaborationWidth + closedMenuWidth; // 1424
	const smallStart = mobileWidth; // 600

	/**
	 * When everything fits perfectly
	 * * We just open anything the user wants to open.
	 */
	// viewportWidth >= 1424px
	if (windowWidth >= largeStart) {
		return getAvailableDeviceType(constants.DEVICES.LARGE);
	}

	/**
	 * @WARNING THIS BREAKPOINT IS TEMPORARILY DISABLED!
	 *
	 * If everything fits if we close the menu
	 * * Collaboration can't be open while the Menu is open
	 * * Menu can't be open while the Collaboration is open
	 * We do this because having the app fully visible is more important than having Collaboration and Menu side-by-side
	 */
	// 1310px <= viewportWidth < 1596px
	// if (
	// 	windowWidth < mainframeMinWidth + collaborationWidth + openMenuWidth &&
	// 	windowWidth >= mainframeMinWidth + openMenuWidth
	// ) {
	// 	return getAvailableDeviceType(constants.DEVICES.MEDIUM);
	// }

	/**
	 * If it won't fit a full app even if we close the menu, but it can fit both the Menu and Collaboration open
	 * * Both the Collaboration and Menu can be open
	 * * The app gets an overlay whenever the Collaboration or Menu is open
	 * * * Tapping on the overlay will close both the Collaboration and the Menu
	 */
	// 600px =< viewportWidth < 1424px
	if (windowWidth < largeStart && windowWidth >= smallStart) {
		return getAvailableDeviceType(constants.DEVICES.SMALL);
	}

	d('%o', 'MOBILE');
	return constants.DEVICES.MOBILE;
}

/**
 * Get the number of icons that fit on a collapsed Menu
 * @returns {Number}
 */
function getMaxAppIcons() {
	/**
	 * Viewport height
	 */
	const win =
		window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
	/**
	 * Height of an icon itself
	 */
	const ico = constants.LENGTH_APP_ICON_CLOSED;
	/**
	 * Height of an icon's container
	 */
	const wrp = constants.LENGTH_APP_ICON;
	/**
	 * Vertical padding below and icon, inside the container
	 */
	const pad = (wrp - ico) / 2;
	/**
	 * Height of everything else (hamburger, inbox & apps header, footer)
	 */
	const oth = 4 * constants.LENGTH_MENU_ICON;
	/**
	 * Maximum number of icons to fit
	 *
	 * This is how it's calculated:
	 * 1) Take the available space (viewport - everything else),
	 * 2) subtract the height of an icon and its vertical padding,
	 * 3) divide by the height of an icon container,
	 * 4) add one, which we subtracted in step 2,
	 * 5) take this number or the max number of grid apps (whichever is lower).
	 */
	const max = Math.min(Math.floor((win - oth - (ico + pad)) / wrp) + 1, constants.NUM_GRID_APPS);
	d('Max icons when collapsed: %o', max);
	return max;
}

/**
 * Compute the pixel width of the scrollbar. Do we have this elsewhere?
 * @returns {number}
 */
function getScrollbarWidth() {
	if (!getScrollbarWidth.width) {
		const outer = document.createElement('div');
		const inner = document.createElement('div');
		outer.style.visibility = 'hidden';
		outer.style.width = '100px';
		document.body.appendChild(outer);
		const widthNoScroll = outer.offsetWidth;
		outer.style.overflow = 'scroll';
		inner.style.width = '100%';
		outer.appendChild(inner);
		const widthWithScroll = inner.offsetWidth;
		outer.parentNode.removeChild(outer);
		getScrollbarWidth.width = widthNoScroll - widthWithScroll;
	}
	return getScrollbarWidth.width;
}

const responsive = {
	getScrollbarWidth,
	getMaxAppIcons,
	getDeviceType,
	getDeviceTypeName: device => {
		device = device || getDeviceType();
		const devices = Object.keys(constants.DEVICES);
		return devices[device].toUpperCase();
	},
	getDeviceTypeClass: device => {
		device = device || getDeviceType();
		const devices = Object.keys(constants.DEVICES);
		const outClasses = [];
		for (let i = 0; i <= device; i++) {
			outClasses.push(`device-${devices[i].toLowerCase()}`);
		}
		outClasses.push(`device-${devices[device].toLowerCase()}-only`);
		return outClasses.join(' ');
	}
};

export default responsive;
