module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"En samling produkter du ønsker å tilby for salg til en bestemt kjøper.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Et dokument utstedt av selger til en kjøper, som angir mengdene og kostnadene til produkter eller tjenester som leveres.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Foreløpig fakturering av salg, som sendes i forkant av selve forsendelsen eller leveransen av varer.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Kontoen er planlagt slettet.",
"4e917d587825921098d19cb5374ffa54":"Legg til app som favoritt",
"7328b929c3b7ea8a93ee9e597817f510":"Alle apper",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Pil-ikon",
"421d54e688f76e063b53b1bd67178b42":"Tilgjengelige apper",
"614bb33c219a0809bbe8e52f938dcce7":"Rammeinnkjøpsordre (forespørsel)",
"51d895d6183265b06e899570e19eecf6":"Grenlogo",
"9d6641632312bcd817a18331b01bd073":"Bla gjennom og søk etter tilgjengelige produkter for kjøp.",
"883d14b9efdb4894d9879c0eff9b5acb":"Sjekk plattformstatusen eller kontakt brukerstøtte.",
"55fc4360777c0241e107f70379e57e75":"Lukk meny",
"da76f49c6f0252b80a6e330d6394c094":"Bekreft mottagelse av produkter og tjenester basert på en åpen innkjøpsordre.",
"67f2b8b530a56e35ec1e8254fb72748f":"Forbindelseforespørsel",
"9860a293ae95e8583f868975af1f4849":"Kontakt kundestøtte",
"210d71bea3790d832babc970a6674be3":"Konverter en åpen ordre til en faktura.",
"8df8c2d028d750d31bb796681c2ea3f9":"Opprett",
"e3f60bea8ce9079c56a6a69f86f4f048":"Opprett kreditnota",
"5b5efdc0c8989fe1056b0e54734e824d":"Opprett faktura",
"fadd16ebdc81e4fde9e7fc3154877894":"Opprett en forskuddsbetalingsfaktura",
"0e1cf03ccaae390506a0da5f0f739280":"Opprett Proformafaktura",
"20c9ff16bb2f0448290a5087a58cdebf":"Opprett og send tilbudsdokumenter til kundene dine.",
"830fa5e28d0b88eb70218ea763f71c5c":"Opprett rammeinnkjøpsordre",
"d75c3f6646800bc987c0c07f986b6111":"Opprett nytt produkt",
"9f7cd528570ea5fa70d5f0fdb8227759":"Opprett tilbud",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Opprett bestilling",
"9f2650a5abb1e17ffb4714580cc4c457":"Opprett produkt",
"581c02d2262d5818fb14faef2a5c5e4e":"Opprett tilbud",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Opprett skriftlig forespørsel",
"3f884136e59449da353adefd80121181":"Opprett ...",
"d23014cf5a5e7f8c6440b96692831aa8":"Kreditnota",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Angi produkt- eller tjenestevarer med (estimerte) priser og interne budsjetter for å opprette innkjøpsordrer for en angitt tidsperiode.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Angi produkter for salg på Tradeshift Marketplaces.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definer forespørselen din for et produkt eller en tjeneste som du ikke kunne finne i butikken og legg den til i handlekurven, eller i en kjøpsforespørsel.",
"1b0dd1aca58f84099cff422597422e56":"Kan ikke laste Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Kan ikke laste Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Varekvittering",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Gruppér selskaper i nettverksgrupper for forenklet administrasjon",
"d7b639ae724bad4cb10abf6e411bd74e":"Dine installerte apper",
"3c4be400f728da4526de626653d56ccc":"Faktura",
"697ca358c7443b09bab9478598f14726":"Fakturer fra ordre",
"db13bed8b517884b5e8e257c3e829e7d":"Utsted kreditter for fremtidige kjøp.",
"8535934d42d580306640d88e0ed217aa":"Laster selskap …",
"802ed138bb62eb56ff187f839234478b":"Laster inn …",
"1c8e8813d12a483823656cc7c245cd58":"Logg ut",
"b25df08fcf0d0acaf6384653ea02141e":"Logg ut",
"34bea6b12f16fea4889a2a80c07ee8ec":"Administrer grupper",
"7a558a55eb2d9e641f608f759964a9f6":"Manuelt opprett en innkjøpsordre for en selger som indikerer foreslåtte varer eller tjenester, mengder og priser.",
"fbb9cc68fee07e74094da1030bfdf236":"Nettverksgruppe",
"ba116f853ca15c3b6f136da33867158b":"Ingen apper samsvarer med søkeordet ditt.",
"14877050aa06ad5917e9d7a7048ad2e3":"Ingen beskrivelse.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Ingen tilgang",
"bacad0fc8777924b7400a5b05b8cc715":"Den {deletionDate}, vil firmakontoen og Tradeshift-kontoen din bli slettet. Hvis dette er en feil, kan du avbryte den.",
"2778717f6acbeb1edaac6a2656b478f3":"Åpen",
"37f066e2502bac5316dfc900890ade4c":"Åpne meny",
"018c4048ea00acea67a5819288f4703f":"Bestilling",
"2ae053867612b2202a870bd52cc3ff63":"Plattformstatus",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Vennligst kontakt din kontoadministrator dersom du mener at dine tillatelser bør endres.",
"281f9682838b746f5a0b8a4555614843":"Forskuddsbetalingsfaktura",
"387117ac2a32235f30c143484f94147f":"Proformafaktura",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Kjøp fra butikk",
"9bb4a36de6bff47eb4209096972979dd":"Tilbud",
"befc83a6b9e66cbfb953b901b91290d0":"Nådde maks. antall favoritter!",
"beb839cf63984c0634d05bdc900e1905":"Motta varer fra bestilling",
"d1b0cf6017a647b49abde5b8ba6fe614":"Nylig brukte grener",
"793f04b605fc32835a578962a6994422":"Register en forskuddsbetaling.",
"4eddd0ca790e495626f275843c87f440":"Last inn på nytt",
"ab8100964aeb93c35539b88420121939":"Fjern app fra favoritter",
"d817327a5392f2d398ed8721a314bc37":"Søk apper",
"31e12c8c15eae192e29acf9fcb949287":"Søk etter bedrifter på Tradeshift-nettverket som du kan gjøre forretninger med.",
"3bcee8f61c10123abe392bf0cc31f99c":"Søk etter firma",
"4716fe18b1d4f74edf1a1cdacc83b329":"Butikk",
"50b5a47e8f2e9f988f509e248860acd0":"Vis handlinger",
"a563aabceadd081e01b44f50d0c9ba26":"Fra og med 31. mars 2021, er Tradeshift-applikasjoner garantert å være kompatible med versjon 11 av Internet Explorer. Oppdater nettleseren din til en versjon støttet av vår plattform.",
"3ad73c1b179af6e6df8e5da91028b57c":"Støtte",
"e6e073d7d80064606e77b870ceaf452a":"Bytt gren",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Prøv å laste siden på nytt.",
"2837b35c04c44f52c772b01723ad1ff0":"Brukerinnstillinger",
"5d3d3522e8b4899177ce29532ae479f0":"Vis alle grener",
"39b9368677bb137b328178942025581b":"Se åpne bestillinger",
"488b2f58d018e655df26de3ea3f2590c":"Skriftlig forespørsel",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Du har ikke tillatelse til å utføre denne handlingen.",
"1bf011c064e4459d5665ff6548bb3c04":"Kontoen din er planlagt slettet.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Firmakontoen din samt Tradeshift-brukerkontoen din planlegges er planlagt å slettes den {deletionDate}. Hvis dette er en feil, kontakt en kontoadministrator.",
};
