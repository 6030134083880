import { isDevelopment } from 'lib/helpers/helpers';
import rootConfig from 'config.json';
import localConfig from 'config.local.json';

const setupConfig = () => {
	const config = Object.assign({}, rootConfig, isDevelopment() ? localConfig : {});

	process.config = process.config || {};
	process.config.hasAnimations = true;

	Object.keys(config).forEach(configKey => {
		process.config[configKey] = config[configKey];
	});

	// Pre-compile the RegExp for matching legacy app URLs
	Object.keys(config.legacyApps).forEach(legacyApp => {
		process.config.legacyApps[legacyApp].matchPath = new RegExp(
			config.legacyApps[legacyApp].matchPath || '(.*)'
		);
	});

	window.name = process.config.featureApps.chrome;

	/**
	 * Get baseURL from the Frontend, if it's supplied.
	 */
	if (
		window.ts !== undefined &&
		window.ts.chrome !== undefined &&
		window.ts.chrome.config !== undefined &&
		window.ts.chrome.config.baseURL !== undefined
	) {
		const config = window.ts.chrome.config;
		process.config.baseURL = config.baseURL;
		process.config.gaCode = config.gaCode;
		process.config.segmentIoKey = config.segmentIoKey;
		process.config.request = {
			accessToken: config.accessToken,
			userId: config.userId,
			requestId: config.requestId,
			companyId: config.companyId
		};
	} else if (isDevelopment()) {
		process.config.request = {
			userId: 'standalone'
		};

		if (process.env.REACT_APP_BASE_URL) {
			process.config.baseURL = process.env.REACT_APP_BASE_URL;
		}
	}

	if (isDevelopment(true) || process.config.gaCode === 'false') {
		process.config.gaCode = null;
	}

	return process.config;
};

export default setupConfig;
