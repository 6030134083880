import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalLoadingCover from 'components/ModalLoadingCover/ModalLoadingCover';

const AppListModal = React.lazy(() => import('./AppListModal'));

function AppListModalContainer({ visible }) {
	const [loaded, setLoaded] = useState(false);
	if (!visible && !loaded) {
		return null;
	}
	if (visible && !loaded) {
		setLoaded(true);
		return <ModalLoadingCover />;
	}
	return (
		<div>
			<Suspense fallback={<ModalLoadingCover />}>
				<AppListModal />
			</Suspense>
		</div>
	);
}

AppListModalContainer.propTypes = {
	visible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	visible: state.apps.appListModal.visible
});

export default connect(mapStateToProps)(AppListModalContainer);
