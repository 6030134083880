import constants from 'lib/helpers/constants';
import utm from './libs/utm';
import censor from './libs/censor';

const ChromeVersion = process.env.REACT_APP_VERSION;

export function getPayload(payload, appId, type) {
	let pageDefaultProperties = {};
	payload = payload || {};

	if (type === 'page') {
		payload.name = constants.PAGE_VIEW_EVENT;

		const censoredWindowData = getCensoredWindowData();

		pageDefaultProperties = {
			path: censoredWindowData.path,
			search: censoredWindowData.search,
			title: censoredWindowData.title,
			url: censoredWindowData.href,
			referrer: censoredWindowData.referrer
		};
	}

	payload.properties = Object.assign(
		{},
		{
			...pageDefaultProperties,
			...getDefaultProperties(),
			appId
		},
		payload.properties
	);

	payload.context = Object.assign({}, getDefaultContext(), payload.context);

	payload.traits = Object.assign({}, getDefaultTraits(), payload.traits);

	return payload;
}

export function getDefaultProperties() {
	const censoredWindowData = getCensoredWindowData();
	const ts = window.ts;

	const companyId =
		ts && ts.chrome && ts.chrome.config && ts.chrome.config.companyId
			? ts.chrome.config.companyId
			: '';
	return {
		companyId,
		ChromeVersion,
		Referrer: censoredWindowData.referrer,
		ChromeType: '2021',
		Frame: 'sub',
		...getUtmPayload(censoredWindowData.search)
	};
}

export function getDefaultTraits() {
	return {
		ChromeVersion,
		ChromeType: '2021',
		Frame: 'sub'
	};
}

export function getDefaultContext() {
	const censoredWindowData = getCensoredWindowData();
	const page = {
		hash: censoredWindowData.hash,
		path: censoredWindowData.path,
		referrer: censoredWindowData.referrer,
		search: censoredWindowData.search,
		title: censoredWindowData.title,
		url: censoredWindowData.href
	};

	const screen = {
		height: window.screen.height,
		width: window.screen.width,
		density: window.devicePixelRatio
	};

	return {
		page,
		screen,
		userAgent: window.navigator.userAgent,
		library: {
			name: constants.VALUE_TRACK_LIBRARIES
		},
		locale: window.navigator.language
	};
}

export function getUtmPayload(search) {
	const utmParams = utm(search);
	return {
		utm_campaign: utmParams.name,
		utm_source: utmParams.source,
		utm_medium: utmParams.medium,
		utm_term: utmParams.term,
		utm_content: utmParams.content
	};
}

export function getCensoredWindowData() {
	const loc = window.location;
	const doc = window.document;

	const data = {
		hash: loc.hash,
		path: loc.pathname,
		referrer: doc.referrer,
		search: loc.search,
		title: doc.title,
		url: loc.href
	};
	Object.keys(data).forEach(key => {
		data[key] = censor.uuid(data[key]);
	});
	return data;
}
