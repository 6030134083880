module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Un ensemble de produits que vous souhaitez proposer à un acheteur.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Un document émis par un vendeur à un acheteur qui indique les quantités et les coûts des produits ou des services fournis.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Une facture de vente préliminaire envoyée avant l'expédition ou la livraison des marchandises.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Compte dont la suppression est programmée.",
"4e917d587825921098d19cb5374ffa54":"Ajouter l'application aux favoris",
"7328b929c3b7ea8a93ee9e597817f510":"Toutes les applications",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Icône Flèche",
"421d54e688f76e063b53b1bd67178b42":"Applications disponibles",
"614bb33c219a0809bbe8e52f938dcce7":"Commande d'achat avec limites (demande)",
"51d895d6183265b06e899570e19eecf6":"Logo de branche",
"9d6641632312bcd817a18331b01bd073":"Parcourir et rechercher les produits disponibles à l'achat.",
"883d14b9efdb4894d9879c0eff9b5acb":"Vérifier le statut de la plateforme ou l’assistance du contrat.",
"55fc4360777c0241e107f70379e57e75":"Menu Fermer",
"da76f49c6f0252b80a6e330d6394c094":"Confirmer la réception des produits et services sur la base d'une commande d'achat ouverte.",
"67f2b8b530a56e35ec1e8254fb72748f":"Demande de connexion",
"9860a293ae95e8583f868975af1f4849":"Contacter l’assistance",
"210d71bea3790d832babc970a6674be3":"Convertissez une commande ouverte en facture.",
"8df8c2d028d750d31bb796681c2ea3f9":"Créer",
"e3f60bea8ce9079c56a6a69f86f4f048":"Créer un avoir",
"5b5efdc0c8989fe1056b0e54734e824d":"Créer une facture",
"fadd16ebdc81e4fde9e7fc3154877894":"Créer une facture de pré-paiement",
"0e1cf03ccaae390506a0da5f0f739280":"Créer une facture pro forma",
"20c9ff16bb2f0448290a5087a58cdebf":"Créez et envoyez des devis à vos clients.",
"830fa5e28d0b88eb70218ea763f71c5c":"Créer une commande d''achat avec limites",
"d75c3f6646800bc987c0c07f986b6111":"Créer un produit",
"9f7cd528570ea5fa70d5f0fdb8227759":"Créer une offre",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Créer un bon de commande",
"9f2650a5abb1e17ffb4714580cc4c457":"Créer un produit",
"581c02d2262d5818fb14faef2a5c5e4e":"Créer un devis",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Créer une demande écrite",
"3f884136e59449da353adefd80121181":"Créer...",
"d23014cf5a5e7f8c6440b96692831aa8":"Avoir",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Définissez des produits ou des services avec des prix (estimés) et des budgets internes pour créer des commandes d'achat pour une période déterminée.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Définissez les produits à vendre sur Tradeshift Marketplaces.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Définissez votre demande du produit ou du service que vous n'avez pas pu trouver dans la boutique et ajoutez-les à votre panier ou à une commande d'achat.",
"1b0dd1aca58f84099cff422597422e56":"Impossible de charger Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Impossible de charger Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Réception des marchandises",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Grouper les entreprises en groupes de réseau pour une administration simplifiée",
"d7b639ae724bad4cb10abf6e411bd74e":"Vos applications installées",
"3c4be400f728da4526de626653d56ccc":"Facture",
"697ca358c7443b09bab9478598f14726":"Facture depuis la commande",
"db13bed8b517884b5e8e257c3e829e7d":"Émettre un crédit sur les achats futurs.",
"8535934d42d580306640d88e0ed217aa":"Chargement de l’entreprise…",
"802ed138bb62eb56ff187f839234478b":"Chargement...",
"1c8e8813d12a483823656cc7c245cd58":"Déconnexion",
"b25df08fcf0d0acaf6384653ea02141e":"Déconnexion",
"34bea6b12f16fea4889a2a80c07ee8ec":"Gérer les groupes",
"7a558a55eb2d9e641f608f759964a9f6":"Créer manuellement une commande d'achat pour un vendeur, en indiquant les produits ou services proposés, les quantités et les prix.",
"fbb9cc68fee07e74094da1030bfdf236":"Groupe de réseau",
"ba116f853ca15c3b6f136da33867158b":"Aucune application ne correspond au terme de la recherche",
"14877050aa06ad5917e9d7a7048ad2e3":"Aucune description",
"b6f4ed6dd31b74d156a22c04e9e02199":"Aucune autorisation",
"bacad0fc8777924b7400a5b05b8cc715":"Le {deletionDate}, votre compte d'entreprise et votre compte d'utilisateur Tradeshift seront supprimés. S'il s'agit d'une erreur, vous pouvez l'annuler.",
"2778717f6acbeb1edaac6a2656b478f3":"Ouvrir",
"37f066e2502bac5316dfc900890ade4c":"Menu Ouvrir",
"018c4048ea00acea67a5819288f4703f":"Commande",
"2ae053867612b2202a870bd52cc3ff63":"État de la plateforme",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Veuillez contacter l'administrateur de votre compte si vous pensez que vos autorisations doivent être modifiées.",
"281f9682838b746f5a0b8a4555614843":"Facture d’acompte",
"387117ac2a32235f30c143484f94147f":"Facture proforma",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Achat depuis la boutique",
"9bb4a36de6bff47eb4209096972979dd":"Devis",
"befc83a6b9e66cbfb953b901b91290d0":"Vous avez atteint le nombre maximum de favoris !",
"beb839cf63984c0634d05bdc900e1905":"Recevoir des marchandises de la commande",
"d1b0cf6017a647b49abde5b8ba6fe614":"Branches utilisées récemment",
"793f04b605fc32835a578962a6994422":"Enregistrez un paiement anticipé.",
"4eddd0ca790e495626f275843c87f440":"Recharger",
"ab8100964aeb93c35539b88420121939":"Supprimer l'application des favoris",
"d817327a5392f2d398ed8721a314bc37":"Rechercher des applications",
"31e12c8c15eae192e29acf9fcb949287":"Recherchez des entreprises sur le réseau Tradeshift pour faire des affaires avec elles.",
"3bcee8f61c10123abe392bf0cc31f99c":"Rechercher une entreprise",
"4716fe18b1d4f74edf1a1cdacc83b329":"Boutique",
"50b5a47e8f2e9f988f509e248860acd0":"Afficher les actions",
"a563aabceadd081e01b44f50d0c9ba26":"À compter du 31 mars 2021, la compatibilité des applications Tradeshift avec la version 11 d'Internet Explorer n'est pas garantie. Veuillez mettre à jour votre navigateur vers une version prise en charge par notre plateforme.",
"e6e073d7d80064606e77b870ceaf452a":"Changer de branche",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Essayez de recharger la page.",
"2837b35c04c44f52c772b01723ad1ff0":"Paramètres utilisateur",
"5d3d3522e8b4899177ce29532ae479f0":"Afficher toutes les branches",
"39b9368677bb137b328178942025581b":"Afficher les commandes ouvertes",
"488b2f58d018e655df26de3ea3f2590c":"Demandes écrite",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Vous n’êtes pas autorisé à exécuter cette action.",
"1bf011c064e4459d5665ff6548bb3c04":"La suppression de votre compte est programmée.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Votre compte d'entreprise, ainsi que votre compte utilisateur Tradeshift, doivent être supprimés le {deletionDate}. S'il s'agit d'une erreur, contactez un administrateur de compte.",
};
