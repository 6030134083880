import React from 'react';
import PropTypes from 'prop-types';
import constants from 'lib/helpers/constants';

const observed = [];
const observer = supports() ? new IntersectionObserver(handleIntersect) : null;
/**
 * Current browser supports the IntersectionObserver API?
 * If so, lazy-load the images as they enter the viewport
 * @returns {boolean}
 */
function supports() {
	return (
		'IntersectionObserver' in window &&
		'IntersectionObserverEntry' in window &&
		'intersectionRatio' in window.IntersectionObserverEntry.prototype
	);
}

/**
 * Observe element, invoke callback when it's in view
 * @param {Element} elem
 * @param {function} callback
 */
function observe(elem, callback) {
	if (!observer) {
		return;
	}
	observed.push({
		elem,
		callback
	});
	observer.observe(elem);
}

/**
 * Unobserve element
 * @param {Element} elem
 */
function unobserve(elem) {
	if (!observer) {
		return;
	}
	const idx = observed.findIndex(o => o.elem === elem);
	if (idx !== -1) {
		observed.splice(idx, 1);
	}
	if (elem && elem instanceof Element) {
		observer.unobserve(elem);
	}
}

/**
 * Handle elements becoming visible
 * @param {Array<IntersectionObserverEntry>} entries
 */
function handleIntersect(entries) {
	entries.forEach(e => {
		const { intersectionRatio, isIntersecting, target } = e;
		const { callback } = observed.find(o => o.elem === target);
		if ((isIntersecting || intersectionRatio > 0) && callback && typeof callback === 'function') {
			callback();
		}
	});
}

const defaultSrc = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';

/**
 * Deferred image loading, with optional scroll detection on supported browsers
 */
// eslint-disable-next-line react/no-deprecated
class DeferredImage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			src: defaultSrc,
			className: '',
			unobserved: !observer,
			onLoad: () => {
				if (this.state.src !== defaultSrc) {
					this.setState({
						className: constants.CLASS_LOADED,
						unobserved: true
					});
					unobserve(this.img);
					this.props.onLoad();
				}
			}
		};
	}
	componentDidMount() {
		if (observer) {
			observe(this.img, this.setSrc.bind(this));
		} else {
			this.setSrc(this.props.src);
		}
	}
	componentDidUpdate() {
		if (this.state.unobserved) {
			this.setSrc();
		}
	}
	componentWillReceiveProps(nextProps) {
		if (this.props.src !== nextProps.src) {
			this.setSrc(nextProps.src);
		}
	}
	/**
	 * Update the element with src and anything else related
	 * @param {string=} overrideSrc
	 */
	setSrc(overrideSrc) {
		// Use overrideSrc, but fall back to props
		const src = overrideSrc || this.props.src;
		// Set the src if changed
		if (this.state.src !== src) {
			const cname = observer ? this.state.className : constants.CLASS_LOADED;
			this.setState({
				className: cname,
				src
			});
		}
	}
	render() {
		return (
			<img
				ref={ref => (this.img = ref)}
				className={[this.props.className, this.state.className].join(' ')}
				src={this.state.src}
				alt={this.props.alt}
				onError={this.props.onError}
				onLoad={this.state.onLoad}
			/>
		);
	}
}

DeferredImage.defaultProps = {
	className: '',
	src: defaultSrc,
	alt: '',
	onError: function() {},
	onLoad: function() {}
};

DeferredImage.propTypes = {
	className: PropTypes.string.isRequired,
	src: PropTypes.string.isRequired,
	alt: PropTypes.string.isRequired,
	onError: PropTypes.func,
	onLoad: PropTypes.func
};

export default DeferredImage;
