import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/language/language';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filters as layoutFilters, actions as layoutActions } from 'lib/layout/layout';
import MenuItem from 'components/MenuItem/MenuItem';
import 'components/apps/appsmodalbutton/appsModalButton.less';
import { actions as appsActions } from 'lib/apps/apps';
import PlusIcon from 'components/apps/appsmodalbutton/__static__/icon-all-apps.svg';

export const AppsModalButton = ({ t, tabIndex, showModal, closeMenu }) => (
	<MenuItem
		className="apps-modal-button"
		iconUrl={PlusIcon}
		label={t('[Button] All apps')}
		tabIndex={tabIndex}
		onClick={e => {
			e.stopPropagation();
			closeMenu();
			showModal();
		}}
	/>
);

AppsModalButton.propTypes = {
	t: PropTypes.func.isRequired,
	tabIndex: PropTypes.string
};

const mapStateToProps = state => ({
	t: t(),
	tabIndex: layoutFilters.tabIndex(state)
});
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			showModal: () => appsActions.showAppListModal(),
			closeMenu: () => layoutActions.setMenu(false)
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AppsModalButton);
