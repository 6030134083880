import debug from 'debug';
import constants from 'lib/helpers/constants';
import store from 'lib/store';
import App from 'lib/apps/App';
import appsSelectors from 'lib/apps/appsSelectors';
import { u } from 'lib/helpers/debug';
import { gaException } from 'lib/history/ga';

const [
	$href,
	$toFrameSrc,
	$app,
	$objURL,
	$url,
	$getURL,
	$redirect,
	$d,
	$skipRoot,
	$deconstructHash,
	$state
] = [
	Symbol('href'),
	Symbol('toFrameSrc'),
	Symbol('app'),
	Symbol('objURL'),
	Symbol('url'),
	Symbol('getURL'),
	Symbol('redirect'),
	Symbol('d'),
	Symbol('skipRoot'),
	Symbol('deconstructHash'),
	Symbol('appsState')
];

/**
 * URLParser
 * This class can convert between ChromeURLs and AppFrame URLs, in any combination.
 *
 * It's a single-use Class, so if you want to calculate another URL, make a new instance.
 */
export default class URLParser {
	/**
	 * We already calculate the App and the URL in the constructor.
	 * @param {string} href This can be any relative or absolute URL, either from the AppFrame or Chrome
	 * @param {boolean} toFrameSrc If true, the url AppFrame URL
	 * @param {string} debugMsg Message for the debug console
	 * @param {object} appsState The apps redux state (needed for reselect)
	 */
	constructor(href, toFrameSrc = false, debugMsg = '', appsState = null) {
		if (debugMsg) {
			debugMsg = `:${debugMsg}`;
			this[$d] = debug(`${constants.NS}${debugMsg}->URLParser`);
		} else {
			this[$d] = function() {};
		}
		this[$href] = href;
		this[$toFrameSrc] = toFrameSrc;
		this[$app] = new App();
		this[$redirect] = null;
		/**
		 * If we don't get the state passed, that means that redux is not dispatching,
		 * so it's safe to get the state.
		 * @see https://github.com/reduxjs/redux/issues/1568
		 */
		this[$state] = appsState ? { apps: appsState } : store.getState();

		this[$url] = this[$getURL]();
	}

	get redirect() {
		return this[$redirect];
	}

	/**
	 * Get the app that was resolved from the URL
	 * @returns {App}
	 */
	get app() {
		return this[$app];
	}

	get url() {
		return this[$url];
	}

	[$getURL]() {
		// Normalize input .........................................................
		// If href doesn't contain the hostname, prepend it
		if (typeof this[$href] === 'string' && !constants.REGEX_HTTP.test(this[$href])) {
			this[$href] = process.config.baseURL + this[$href].replace(constants.REGEX_EXTRA_SLASHES, '');
		}

		try {
			this[$objURL] = new window.URL(this[$href].pathname || this[$href], process.config.baseURL);
		} catch (urlError) {
			console.error('Error while parsing URL', this[$href]);
			console.error(urlError);
			gaException(`Error while parsing URL ${this[$href]} | Error: ${urlError.message}`);
			throw new Error({ error: urlError, href: this[$href] });
		}

		if (typeof this[$href] === 'object') {
			this[$objURL].pathname = this[$href].pathname;
			this[$objURL].search = this[$href].search;
			this[$objURL].hash = this[$href].hash;
		} else {
			this[$objURL].href = this[$href];
		}
		this[$objURL].pathname = this[$skipRoot](
			'/' + this[$objURL].pathname.replace(constants.REGEX_EXTRA_SLASHES, '')
		);

		// Figure out current app ..................................................

		// Check if it's a frame /${versionBase}/apps/
		if (constants.REGEX_FRAME_SRC.test(this[$objURL].pathname)) {
			// We're handling a frameURL
			const matches = constants.REGEX_FRAME_SRC.exec(
				this[$objURL].pathname + this[$objURL].search + this[$objURL].hash
			);
			this[$app].version = matches[1];
			this[$app].id = matches[2];

			this[$app].location = this[$deconstructHash](matches[3]);

			this[$objURL].hash = '';
		} else {
			// We're handling a chromeURL
			// Check if it's a classic /${sectionId}/${appVendor}.${appName}/* URI
			if (constants.REGEX_APP_PATH_WITH_SECTION.test(this[$objURL].pathname)) {
				const matches = constants.REGEX_APP_PATH_WITH_SECTION.exec(this[$objURL].pathname);
				// Remove sectionId from URL
				this[$objURL].pathname = '/' + matches[2] + matches[3];
			}

			// Check if it's a /${appVendor}.${appName}/* URI
			if (constants.REGEX_APP_PATH.test(this[$objURL].pathname)) {
				const matches = constants.REGEX_APP_PATH.exec(
					this[$objURL].pathname + this[$objURL].search + this[$objURL].hash
				);
				this[$app].id = matches[1];

				this[$app].location = this[$deconstructHash](matches[2]);
			} else {
				// Look up if we can resolve the URI using the LegacyWrapper apps
				this[$app].id = Object.keys(process.config.legacyApps).find(legacyApp =>
					process.config.legacyApps[legacyApp].matchPath.test(
						this[$objURL].pathname + this[$objURL].search + this[$objURL].hash
					)
				);
				this[$app].setFromApp(appsSelectors.getApp(this[$state], this[$app].id));

				this[$app].location = {
					pathname: this[$objURL].pathname.substring(
						process.config.legacyApps[this[$app].id].basePath.length
					),
					search: this[$objURL].search,
					hash: this[$objURL].hash
				};
			}
		}

		// Check for redirects .....................................................

		// Look up if the app has to be redirected
		const redirect = appsSelectors.redirect(this[$state], this[$app].id);
		if (redirect) {
			this[$redirect] = redirect;
			this[$d]('%o redirects to %o', this[$app].id, redirect);
			// IF YES, rewrite the URL
			this[$objURL].pathname.replace(this[$app].id, redirect);
			this[$app].id = redirect;
		}

		// Load app info ...........................................................

		// Look up app in the storage, to see if the user has it activated
		const appResult = appsSelectors.getApp(this[$state], this[$app].id);
		if (appResult && appResult.activated) {
			// IF YES, save the app, so we know the version
			this[$app].setFromApp({ ...this[$app], ...appResult });
		} else {
			this[$d]('%o not activated', this[$app].id);
			// IF NOT, generate URI to the appropriate AppStore page
			const appId = this[$app].id;
			this[$app].setFromApp(
				appsSelectors.getApp(this[$state], process.config.featureApps.appStore)
			);
			this[$app].location = {
				pathname: '/apps/' + appId,
				search: '',
				hash: ''
			};
		}

		// Generate output .........................................................

		// Check if we're building the new URI for an app-frame
		if (this[$toFrameSrc]) {
			if (this[$app].version === 'TS25') {
				// IF YES and App is TS25, we build /${getVersionBase()/${appVendor}.${appName}*#* and return it
				this[$objURL].pathname =
					this[$app].version.toLowerCase() +
					'/apps/' +
					this[$app].id +
					this[$skipRoot](this[$app].location.pathname);
				this[$objURL].search = this[$app].location.search;
				this[$objURL].hash = this[$app].location.hash;
			} else {
				// IF YES and App is NOT TS25, we build /${getVersionBase()/${appVendor}.${appName}#* and return it
				this[$objURL].pathname = this[$app].version.toLowerCase() + '/apps/' + this[$app].id;
				this[$objURL].search = '';
				this[$objURL].hash =
					this[$app].location.pathname + this[$app].location.search + this[$app].location.hash;
			}
		} else {
			// IF NOT, we build /${appVendor}.${appName}*#* and return it
			const appPath = new window.URL(this[$app].href, process.config.baseURL);
			this[$objURL].pathname = this[$skipRoot](
				'/' + appPath.pathname.replace(constants.REGEX_EXTRA_SLASHES, '')
			);
			this[$objURL].search = appPath.search || this[$objURL].search;
			this[$objURL].hash = appPath.hash || this[$objURL].hash;
		}

		this[$d](
			'target: %o - appId: %o, path: %o - input: %o -> output: %o',
			this[$toFrameSrc] ? 'appframe' : 'topframe',
			this[$app].id,
			this[$app].location,
			u(this[$href]),
			u(this[$objURL])
		);
		return this[$objURL].pathname + this[$objURL].search + this[$objURL].hash;
	}

	/**
	 * Don't return the path, if it's just '/'
	 * @param {string} path
	 * @returns {string}
	 */
	[$skipRoot](path = '') {
		return path !== '/' ? path : '';
	}

	/**
	 * Decontruct the hash-part of the URL
	 *
	 * This is where we take the actual relative URL from the hash-part of the absolute URL and deconstruct it.
	 * eg:
	 * In '/v4/apps/Tradeshift.CoolGuy#/actual/app/path?some=vars&go=here#and-an-extra-hash'
	 * we would take '#/actual/app/path?some=vars&go=here#and-an-extra-hash' and pass it to this function
	 * and it becomes:
	 * { pathname: '/actual/app/path', search: '?some=vars&go=here', hash: '#and-an-extra-hash' }
	 *
	 * Possible types of relative URL combinations:
	 * `path`, `path` & `search`, `path` & `hash`, `path` & `search` & `hash`
	 * `search`, `search` & `hash`
	 * `hash`
	 *
	 * @param {string} The part of the URL that we need to deconstruct
	 * @returns {object} The deconstructed location object
	 */
	[$deconstructHash](input = '') {
		let pathname = '';
		let search = '';
		let hash = '';

		let hasPath = false;
		let hasSearch = false;
		let hasHash = false;

		// We support both the `hash` passed from a URL, or just a URL as a string,
		// this is where we clean it up.
		if (input[0] === '#') {
			input = input.substring(1);
		}

		const markPos = input.indexOf('?');
		const hashPos = input.indexOf('#');

		// We'll figure out, which is the first type in the string:

		if (input[0] === '/') {
			// If it starts with '/', it has to be a `path`,
			hasPath = true;
		} else if (markPos === 0) {
			// If it starts with '?', it has to be a `search`,
			hasSearch = true;
		} else {
			// If it doesn't start with anything, it must be a `hash`.
			hasHash = true;
		}

		// If it has a '?', it probably has `search` (see below, when it might not),
		if (hasPath && markPos !== -1) {
			hasSearch = true;
		}

		// If it has a '#' it definitely has a `hash`,
		if (hashPos !== -1) {
			hasHash = true;

			// If it has a '?', but it's after the '#', it is not `search`, just a part of the `hash`.
			// eg: /path#hash-has-the-qmark?_yes
			if (hasSearch && markPos > hashPos) {
				hasSearch = false;
			}
		}

		if (hasPath) {
			if (hasSearch) {
				if (hasHash) {
					pathname = input.substring(0, markPos);
					search = input.substring(markPos, hashPos);
					hash = input.substring(hashPos);
				} else {
					pathname = input.substring(0, markPos);
					search = input.substring(markPos);
				}
			} else {
				pathname = input;
			}
		} else if (hasSearch) {
			if (hasHash) {
				search = input.substring(0, hashPos);
				hash = input.substring(hashPos);
			} else {
				search = input;
			}
		} else {
			// In case we saw that the input is a `hash`, but it didn't come with a '#' prepended,
			// we'll need to add it manually.
			if (input[0] !== '#' && input.length) {
				input = '#' + input;
			}
			hash = input;
		}

		return {
			pathname,
			search,
			hash
		};
	}
}
