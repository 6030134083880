const LENGTH_UNIT = 20;

/**
 * Slow down animations
 */
const DEBUG_MULTIPLIER = 1;

const ANIM = {
	DEBUG_MULTIPLIER,
	MOBILE_TO_TABLET_MULTIPLIER: 1.3,
	MOBILE_TO_DESKTOP_MULTIPLIER: 0.8,
	DURATION: {
		MOBILE: {
			WEIRD: 375 * DEBUG_MULTIPLIER,
			ENTER: 225 * DEBUG_MULTIPLIER,
			LEAVE: 195 * DEBUG_MULTIPLIER
		}
	},
	EASING: {
		CURVE: [0.4, 0.0, 0.2, 1],
		LEAVE: [0.4, 0.0, 1.0, 1],
		ENTER: [0.0, 0.0, 0.2, 1],
		SHARP: [0.4, 0.0, 0.6, 1]
	}
};
ANIM.DURATION.TABLET = {
	WEIRD: ANIM.DURATION.MOBILE.WEIRD * ANIM.MOBILE_TO_TABLET_MULTIPLIER,
	ENTER: ANIM.DURATION.MOBILE.ENTER * ANIM.MOBILE_TO_TABLET_MULTIPLIER,
	LEAVE: ANIM.DURATION.MOBILE.LEAVE * ANIM.MOBILE_TO_TABLET_MULTIPLIER
};
ANIM.DURATION.DESKTOP = {
	WEIRD: ANIM.DURATION.MOBILE.WEIRD * ANIM.MOBILE_TO_DESKTOP_MULTIPLIER,
	ENTER: ANIM.DURATION.MOBILE.ENTER * ANIM.MOBILE_TO_DESKTOP_MULTIPLIER,
	LEAVE: ANIM.DURATION.MOBILE.LEAVE * ANIM.MOBILE_TO_DESKTOP_MULTIPLIER
};

ANIM.DURATION.SMALL = ANIM.DURATION.TABLET;
ANIM.DURATION.MEDIUM = ANIM.DURATION.LARGE = ANIM.DURATION.DESKTOP;

const constants = {
	NS: 'ts:chrome',
	DURATION_GET_RETRY: 5000,
	DURATION_STANDALONE_STARTUP: 1000,
	DURATION_RESIZE_DEBOUNCE: 250,
	DURATION_ERROR_FADE: 2000,
	ANIM,
	LENGTH_UNIT: LENGTH_UNIT,
	LENGTH_MENU_ICON: 3 * LENGTH_UNIT,
	LENGTH_APP_ICON: 3 * LENGTH_UNIT,
	HEIGHT_APP_GRID: 5 * LENGTH_UNIT,
	WIDTH_MENU: 18 * LENGTH_UNIT,
	WIDTH_COLLABORATION: 20 * LENGTH_UNIT,

	LENGTH_APP_ICON_CLOSED: 2 * LENGTH_UNIT,

	NUM_GRID_APPS: 9,
	PINNED_START_INDEX: 1,
	MAX_RECENT_BRANCHES: 3,

	CLASS_PLACEHOLDER: 'placeholder',

	CLASS_SHOW: 'show',
	CLASS_HIDE: 'hide',

	CLASS_OPEN: 'open',
	CLASS_CLOSED: 'closed',

	CLASS_HAS_ANIM: 'has-anim',
	CLASS_NO_ANIM: 'no-anim',

	CLASS_MENU_OPEN: 'menu-open',
	CLASS_MENU_CLOSED: 'menu-closed',

	CLASS_MENU_ANIMATING: 'menu-animating',
	CLASS_COLLABORATION_ANIMATING: 'collaboration-animating',

	CLASS_BUSY: 'busy',

	CLASS_ROOT: 'root',

	CLASS_CONTAINER: 'container',

	CLASS_MAIN_FRAME_CONTAINER: 'mainframe-container',
	CLASS_MAIN_FRAME_COVER: 'mainframe-cover',
	CLASS_MAIN_FRAME: 'tst-app-frame main-frame',

	CLASS_SPAWNED_FRAME: 'spawned-frame',

	CLASS_MENU: 'menu',
	CLASS_MENU_SECTION: 'menu-section',

	CLASS_FOOTER: 'footer',
	CLASS_FOOTER_HAS_BRANCH_SELECTOR: 'footer-has-branch-selector',

	CLASS_INBOX_FRAME: 'tst-inbox-frame',
	CLASS_INBOX: 'inbox',

	CLASS_COLLABORATION_FRAME: 'tst-collaboration-frame',
	CLASS_COLLABORATION_CONTAINER: 'collaboration-container',
	CLASS_COLLABORATION_OPEN: 'collaboration-open',
	CLASS_COLLABORATION_CLOSED: 'collaboration-closed',

	CLASS_APP_LIST: 'app-list',
	CLASS_APPS_CONTAINER: 'apps-container',
	CLASS_INSTALLED_APPS: 'installed-apps-container',
	CLASS_APP_GRID_ITEM: 'app-grid-item',

	CLASS_APP_ICON_IMG: 'app-icon-img',

	CLASS_LOADED: 'loaded',

	CLASS_FULLSCREEN: 'fullscreen',

	CLASS_HAS_TOP_BAR: 'has-top-bar',

	/**
	 * URL Stuff
	 */

	REGEX_HTTP: /^https?:\/\//,

	REGEX_PROTOCOL_HOSTNAME_PORT: /^https?:\/\/([^/]*)/,

	REGEX_EXTRA_SLASHES: /^\/+|\/$/g,

	REGEX_TRAILING_SLASH: /\/$/,

	/**
	 * /:sectionId/:appId/:appLocalPathAndQueryParams
	 */
	REGEX_APP_PATH_WITH_SECTION: /^\/([a-z]+)\/([a-z0-9_]+\.[a-z0-9_]+)(.*)$/i,
	/**
	 * /:appId/:appLocalPathAndQueryParams
	 */
	REGEX_APP_PATH: /^\/([a-z0-9_]+\.[a-z0-9_]+)(.*)$/i,
	/**
	 * /:versionBase/apps/:appLocalPathAndQueryParams
	 */
	REGEX_FRAME_SRC: /^\/(v4|ts25|legacy)+\/apps\/([a-z0-9_]+\.[a-z0-9_]+)(.*)$/i,

	PREFIX_FRAME_SRC_CURRENT: 'ts-frame-url-current:',

	PREFIX_TS_APP_BROADCAST: 'app-broadcast:',

	PREFIX_SPIRITUAL_BROADCAST: 'spiritual-broadcast:',
	PREFIX_CHROME_HASH_CHANGE: 'ts-chrome-location-hash:',
	EVENT_FRAME_SRC_CHANGE: 'ts-frame-url-change',
	EVENT_FRAME_SRC_REPLACE: 'ts-frame-url-replace',
	EVENT_FRAME_HASH_CHANGE: 'ts-frame-hash-change',

	/**
	 * Apps
	 */
	APP_LEGACY: {
		id: 'Tradeshift.Legacy',
		legacyWrapper: true
	},
	APP_MESSAGES: {
		id: 'Tradeshift.Messages'
	},
	APP_COMPANY_PROFILE: {
		id: 'Tradeshift.CompanyProfile'
	},

	/**
	 * KeepAlive
	 */
	DURATION_KEEPALIVE_REPEAT: 5 * 60 * 1000,

	/**
	 * Maintenance notifications
	 */
	MINUTES_LEFT_SHOW_MAINTENANCE: [Number.POSITIVE_INFINITY, 10, 5, 1],
	DURATION_MAINTENANCE_LOOP: 5000,

	/**
	 * EventBus events
	 */
	DURATION_EVENTBUS_RETRY: 1000,
	PREFIX_EVENT_BUS: 'ts:',
	EVENT_APP_ACTIVATE: 'ts:app:activate',
	EVENT_APP_DEACTIVATE: 'ts:app:deactivate',
	EVENT_SYSTEM_MAINTENANCE_STATUS_CHANGE: 'systemInfo:nextMaintenance',
	EVENT_UI_APP_UPDATES: 'ts:ui.updates.app.events',

	/**
	 * Chrome events
	 */
	EVENT_TASK_LIST_CLOSE: 'ts-task-list-close',
	EVENT_COLLABORATION_OPEN: 'ts-collaboration-open',
	EVENT_COLLABORATION_CLOSE: 'ts-collaboration-close',
	EVENT_INBOX_UNREAD_SET: 'inbox-unread-badge-set',
	EVENT_REFRESH_CHROME: 'ts-chrome-refresh',
	EVENT_SET_LOCALE: 'ts-chrome-locale',
	EVENT_CHROME_MENU_OPEN: 'ts-broadcast-chrome-menu-open',
	EVENT_CHROME_MENU_CLOSE: 'ts-broadcast-chrome-menu-close',
	EVENT_APP_ENTER_FULLSCREEN: 'ts-app-fullscreen-enter',
	EVENT_APP_LEAVE_FULLSCREEN: 'ts-app-fullscreen-leave',
	EVENT_APP_UPDATES: 'tradeshift-chrome/app-updates',
	EVENT_CHANGE_MAIN_FRAME_URL: 'tradeshift-chrome/change-main-frame-url',
	EVENT_REPLACE_MAIN_FRAME_URL: 'tradeshift-chrome/replace-main-frame-url',
	EVENT_LAYOUT_SEND: 'ts-layout-send',
	EVENT_BRANCH_SWITCH_LOG: 'ts-branch-switch-log',
	EVENT_CHROME_CREATE_MODAL_OPEN: 'tradeshift-chrome/create-modal-open',
	EVENT_COMPANY_PROFILE_SETTINGS_OPEN: 'ts-company-profile-settings-open',

	VALUE_TRACK_LIBRARIES: 'Tradeshift chrome client-side segmentLibrary',

	ID_INBOX_FRAME: 'inbox-frame',
	ID_COLLABORATION_FRAME: 'collaboration-frame',
	ID_LEGACY_FRAME: 'legacy-frame',
	CLASS_LEGACY_FRAME: 'legacy-frame',
	/**
	 * This can't be changed, lots of legacy code is looking for it by id
	 */
	ID_MAIN_FRAME: 'main-app-iframe',

	PREFIX_CHROME_LOCAL_STORAGE: 'tradeshift-chrome/state/',
	KEY_APP_OPEN_COUNTS: 'tradeshift-chrome/openCounts',
	KEY_APP_PINNED: 'tradeshift-chrome/pinnedApps',
	KEY_LAST_URLS: 'tradeshift-chrome/lastURLs',
	KEY_ERROR_COUNT: 'tradeshift-chrome/errorCount',
	KEY_DEVICE_LARGE: 'tradeshift-chrome/devices/large',
	KEY_DEVICE_MEDIUM: 'tradeshift-chrome/devices/medium',
	KEY_DEVICE_SMALL: 'tradeshift-chrome/devices/small',
	KEY_BRANCH_SWITCH_LOGS: 'tradeshift-chrome/branch-switch-logs',
	KEY_QUICK_BRANCH_SWITCH_LAST_URL: 'tradeshift-chrome/quick-branch-switch-last-url',

	GA_NS: 'chrome',
	GA_CATEGORY_CHROME: 'chrome',
	GA_ACTION_LOGIN: 'logging-on',
	GA_ACTION_RELOAD: 'reload',
	GA_LABEL_BSOD: 'black-fail-screen',

	DEVICES: {
		MOBILE: 0,
		SMALL: 1,
		MEDIUM: 2,
		LARGE: 3
	},

	FRAMES: {
		MAIN: 'main',
		INBOX: 'inbox',
		COLLABORATION: 'collaboration',
		SERVICE: 'service'
	},

	/******** ANALYTICS ********/
	// Mix panel event names
	PAGE_VIEW_EVENT: 'Core App',
	PANEL_EVENT_APP_CLICK: 'Core Chrome App click',
	PANEL_EVENT_SETTINGS_CLICK: 'Core Chrome Settings click',
	PANEL_EVENT_SECTION_CHANGE: 'Core Chrome Section Toggle',
	PANEL_EVENT_PANEL_TOGGLED: 'Core Chrome Panel Toggle',
	PANEL_EVENT_LOG_OUT: 'Core Chrome Log Out',
	PANEL_EVENT_MESSAGES_APP_CLICK: 'Core Chrome Messages app click',

	SEGMENT_URL: 'https://api.segment.io/v1/',

	/**
	 * Elements loader constants
	 */
	LOAD_ELEMENTS_STATES: {
		NONE: 'none',
		LOADING: 'loading',
		LOADED: 'loaded'
	},

	ENVS: {
		SMOKE_TEST: 'smoke_test'
	}
};

export default constants;
