import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'lib/language/language';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { filters as layoutFilters, actions as layoutActions } from 'lib/layout/layout';
import MenuItem from 'components/MenuItem/MenuItem';
import 'components/apps/createactionsbutton/CreateActionsButton.less';
import { actions as createActionModalActions } from 'lib/apps/createActionsModal';
import CreateIcon from 'components/apps/createactionsbutton/__static__/icon-create.svg';

export const CreateActionsButton = ({ t, tabIndex, showModal, closeMenu }) => (
	<MenuItem
		className="create-actions-button"
		tabIndex={tabIndex}
		onClick={e => {
			e.stopPropagation();
			closeMenu();
			showModal();
		}}
		label={t('Create')}
		iconUrl={CreateIcon}
	/>
);

CreateActionsButton.propTypes = {
	t: PropTypes.func.isRequired,
	tabIndex: PropTypes.string,
	showModal: PropTypes.func.isRequired,
	closeMenu: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	t: t(),
	tabIndex: layoutFilters.tabIndex(state)
});
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			showModal: () => createActionModalActions.showCreateActionsModal(),
			closeMenu: () => layoutActions.setMenu(false)
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(CreateActionsButton);
