import React from 'react';
import PropTypes from 'prop-types';
import DeferredImage from 'components/DeferredImage';

/**
 * Get initials for name.
 * If the name is Karl Benson(Ka), It will return KA
 * @param {string} name
 * @returns {string}
 */
function getInitials(name) {
	const regExp = /\(([^)]+)\)/;
	if (regExp.test(name)) {
		return regExp.exec(name)[1].toUpperCase();
	}
	const names = name.split(/\s+/);
	const first = names.shift();
	const last = names.pop() || '';
	return (first[0] + (last[0] || '')).toUpperCase();
}

/**
 * Compute consistently pleasant color for given string.
 * @param {string} name
 * @param {number} mod Darken or ligthen mod
 */
function getColor(name, mod = 1) {
	const base = [245, 245, 245]; // TODO: optimize for diff backgrounds
	const r = base[0];
	const g = base[1];
	const b = base[2];
	const red = (color(name, 3) + r) / 2;
	const green = (color(name, 5) + g) / 2;
	const blue = (color(name, 7) + b) / 2;
	const col = [red, green, blue].map(Math.floor);
	return 'rgb(' + darken(col, mod || 0).join(',') + ')';
}

/**
 * Get computed color value.
 * @param {string} word
 * @param {number} n
 * @returns {number}
 */
const color = (word, n) => Math.floor(parseFloat('0.' + hash(word, n)) * 256);

/**
 * Get integer hashcode.
 * @param {string} word
 * @param {number} n
 * @returns {number}
 */
function hash(word, n) {
	let h = 0;
	for (let i = 0; i < word.length; i++) {
		h = word.charCodeAt(i) + ((h << n) - h);
	}
	return Math.abs(h);
}

/**
 * Obscurely darken or lighten a color.
 * https://gist.github.com/p01/1005192
 */
// eslint-disable-next-line no-cond-assign
const darken = (c, n) => c.map(d => ((d += n) < 0 ? 0 : d > 255 ? 255 : d | 0));

const Avatar = ({ busy, userName, companyName, avatarURL }) => {
	const name = busy ? '-' : userName || companyName || '-';
	const attrs = {
		className: 'userimage-icon'
	};

	if (avatarURL) {
		attrs.src = avatarURL;
		attrs.alt = name;
		// eslint-disable-next-line jsx-a11y/alt-text
		return <DeferredImage {...attrs} />;
	}

	const initials = getInitials(name);
	attrs.title = name;
	attrs.className += ' userimage-fake';
	const fgColor = getColor(name, -180);
	const bgColor = getColor(name, 50);
	attrs.style = { color: fgColor, background: bgColor };
	return <div {...attrs}>{initials}</div>;
};

Avatar.propTypes = {
	busy: PropTypes.bool.isRequired,
	companyName: PropTypes.string.isRequired,
	userName: PropTypes.string.isRequired,
	avatarURL: PropTypes.string
};

export default Avatar;
