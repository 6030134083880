import constants from 'lib/helpers/constants';

/**
 * Are we in dev/standalone mode?
 * @param gitHubCounts
 * @returns {boolean}
 */
export const isDevelopment = (gitHubCounts = true) =>
	process.env.NODE_ENV === 'development' ||
	(gitHubCounts &&
		(window.location.hostname === 'chrome.sampi.io' ||
			window.location.hostname === 'tradeshift.github.io'));

/**
 * Are we in standalone mode?
 * @returns {boolean}
 */
export const isStandalone = () =>
	process.config.request && process.config.request.userId === 'standalone';

/**
 * Generate href (and target) attribute for an app `<a />`
 * @param pathname
 * @param disable If true, appHref won't return the href object
 * @returns {object}
 */
export const appHref = (pathname, disable = false) =>
	disable
		? {}
		: {
				href: `#${pathname}`
		  };

/**
 * Are we in IE11?
 * @returns {boolean}
 */
export const isIE11 = () => window.navigator.userAgent.indexOf('Trident/7.0') > 0;

/**
 * Are we in Edge?
 * @returns {boolean}
 */
export const isEdge = () => window.navigator.userAgent.indexOf('Edge/') > 0;

/**
 * Remove namespace from redux action type
 * @param {string} type
 * @returns {string}
 */
export const cleanupType = type => type.split('/').pop();

export const removeExtraSlashes = href =>
	`/${(href + '').replace(constants.REGEX_EXTRA_SLASHES, '')}`;

/**
 * Async sleep/setTimeout
 * Use it like this: `await sleep(1000);`
 */
export const sleep = duration => new Promise(resolve => setTimeout(resolve, duration));

/**
 * Get cookie by name
 * https://stackoverflow.com/questions/10730362/get-cookie-by-name
 * @param {string} name
 * @returns {string}
 */
export const getCookie = name => {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');
	if (parts.length === 2) {
		return parts
			.pop()
			.split(';')
			.shift();
	}
	return '';
};

/**
 * Get csrf token from cookie
 * @returns {string}
 */
export const getCsrfToken = () => getCookie('csrfToken');

export const switchBranch = branch => {
	// It's legacy code copied from BranchSelector app
	const form = window.document.createElement('form');
	form.setAttribute('method', 'post');
	form.setAttribute(
		'action',
		window.location.origin + `/login/change/${branch.id}?csrfToken=${getCsrfToken()}`
	);
	window.document.body.appendChild(form);
	form.submit();
};

export function removeDuplicates(array, prop) {
	return array.filter((obj, pos, arr) => {
		return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
	});
}

export const svgToDataURI = svgString => `data:image/svg+xml;base64,${window.btoa(svgString)}`;

export const getFlagrFetchOpts = data => ({
	method: 'POST',
	cache: 'no-cache',
	mode: 'cors',
	credentials: 'omit',
	referrerPolicy: 'no-referrer',
	headers: {
		'Content-Type': 'application/json'
	},
	body: JSON.stringify(data)
});
