import { types as rootTypes } from 'lib/root/root';

const initialState = {
	busy: false,
	user: {
		companyName: '',
		userName: '',
		email: '',
		avatarURL: '',
		companyIsPremium: false,
		companyCountry: ''
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case rootTypes.DATA_FETCH:
			return {
				...state,
				busy: true
			};

		case rootTypes.DATA_FETCHED:
			return {
				...state,
				user: action.data.user
			};

		case rootTypes.DATA_FETCHED_NEXT:
			return {
				...state,
				busy: false
			};

		default:
			return { ...state };
	}
};

export default reducer;

export const filters = {
	busy: state => state.menu.busy
};
