module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"En samling produkter som du vill erbjuda till en specifik köpare.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Ett dokument som utfärdas av en säljare till en köpare som anger kvantitet och kostnader för produkter eller tjänster.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Ett preliminärt köpebrev skickas före leverans av varor.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Konto är schemalagt för borttagning.",
"4e917d587825921098d19cb5374ffa54":"Lägg till appen i favoriter",
"7328b929c3b7ea8a93ee9e597817f510":"Alla appar",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Pilikon",
"421d54e688f76e063b53b1bd67178b42":"Tillgängliga appar",
"614bb33c219a0809bbe8e52f938dcce7":"Avropsorder, inköp (begäran)",
"51d895d6183265b06e899570e19eecf6":"Affärsenhetslogotyp",
"9d6641632312bcd817a18331b01bd073":"Bläddra och sök efter tillgängliga produkter för köp.",
"883d14b9efdb4894d9879c0eff9b5acb":"Kontrollera plattformsstatus eller kontakta support.",
"55fc4360777c0241e107f70379e57e75":"Stäng meny",
"da76f49c6f0252b80a6e330d6394c094":"Bekräfta mottagande av produkter och tjänster baserad på en öppen inköpsorder.",
"67f2b8b530a56e35ec1e8254fb72748f":"Förbindelseförfrågan",
"9860a293ae95e8583f868975af1f4849":"Kontakta support",
"210d71bea3790d832babc970a6674be3":"Omvandla en öppen order till en faktura.",
"8df8c2d028d750d31bb796681c2ea3f9":"Skapa en testfaktura",
"e3f60bea8ce9079c56a6a69f86f4f048":"Skapa kreditnota",
"5b5efdc0c8989fe1056b0e54734e824d":"Skapa faktura",
"fadd16ebdc81e4fde9e7fc3154877894":"Skapa förskottsfaktura",
"0e1cf03ccaae390506a0da5f0f739280":"Skapa proformafaktura",
"20c9ff16bb2f0448290a5087a58cdebf":"Skapa och skicka offertdokument till kunderna.",
"830fa5e28d0b88eb70218ea763f71c5c":"Skapa avropsorder för inköp",
"d75c3f6646800bc987c0c07f986b6111":"Skapa ny produkt",
"9f7cd528570ea5fa70d5f0fdb8227759":"Skapa erbjudande",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Skapa inköpsorder",
"9f2650a5abb1e17ffb4714580cc4c457":"Skapa produkt",
"581c02d2262d5818fb14faef2a5c5e4e":"Skapa offert",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Skapa skriftlig förfrågan",
"3f884136e59449da353adefd80121181":"Skapa...",
"d23014cf5a5e7f8c6440b96692831aa8":"Kreditfaktura",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definiera produkter eller tjänster med (uppskattade) priser och interna budgetar för att skapa inköpsorder under en bestämd tidsperiod.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definiera produkter till försäljning på Tradeshift Marketplaces.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definiera begäran om en produkt eller tjänst som du inte hittade i Butik och lägg till den i varukorgen eller i en inköpsförfrågan.",
"1b0dd1aca58f84099cff422597422e56":"Det gick inte att läsa in Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Det gick inte att läsa in Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Varuinleverans",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Gruppera företag i nätverksgrupper för förenklad administration",
"d7b639ae724bad4cb10abf6e411bd74e":"Installerade appar",
"3c4be400f728da4526de626653d56ccc":"Faktura",
"697ca358c7443b09bab9478598f14726":"Fakturera från order",
"db13bed8b517884b5e8e257c3e829e7d":"Utfärda kredit mot framtida köp.",
"8535934d42d580306640d88e0ed217aa":"Läser in företag…",
"802ed138bb62eb56ff187f839234478b":"Läser in...",
"1c8e8813d12a483823656cc7c245cd58":"Logga ut",
"b25df08fcf0d0acaf6384653ea02141e":"Logga ut",
"34bea6b12f16fea4889a2a80c07ee8ec":"Hantera grupper",
"7a558a55eb2d9e641f608f759964a9f6":"Skapa en inköpsorder manuellt för en säljare, ange föreslagna varor eller tjänster, kvantiteter och priser.",
"fbb9cc68fee07e74094da1030bfdf236":"Nätverksgrupp",
"ba116f853ca15c3b6f136da33867158b":"Inga appar matchar sökningen.",
"14877050aa06ad5917e9d7a7048ad2e3":"Ingen beskrivning.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Ingen behörighet",
"bacad0fc8777924b7400a5b05b8cc715":"Den {deletionDate} kommer ditt företagskonto och ditt Tradeshift-användarkonto att raderas. Om detta är ett misstag kan du avbryta det.",
"2778717f6acbeb1edaac6a2656b478f3":"Öppna",
"37f066e2502bac5316dfc900890ade4c":"Öppna meny",
"018c4048ea00acea67a5819288f4703f":"Inköpsorder",
"2ae053867612b2202a870bd52cc3ff63":"Plattformens status",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Kontakta kontoadministratören om du anser att dina behörigheter bör ändras.",
"281f9682838b746f5a0b8a4555614843":"Förskottsfaktura",
"387117ac2a32235f30c143484f94147f":"Proformafaktura",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Köp i butik",
"9bb4a36de6bff47eb4209096972979dd":"Kostnadsförslag",
"befc83a6b9e66cbfb953b901b91290d0":"Maximalt antal favoriter uppnått.",
"beb839cf63984c0634d05bdc900e1905":"Ta emot varor från order",
"d1b0cf6017a647b49abde5b8ba6fe614":"Nyligen använda affärsenheter",
"793f04b605fc32835a578962a6994422":"Registrera en förskottsbetalning.",
"4eddd0ca790e495626f275843c87f440":"Läs in igen",
"ab8100964aeb93c35539b88420121939":"Ta bort app från favoriter",
"d817327a5392f2d398ed8721a314bc37":"Sök efter appar",
"31e12c8c15eae192e29acf9fcb949287":"Sök efter företag på Tradeshift-nätverket att göra affärer med.",
"3bcee8f61c10123abe392bf0cc31f99c":"Sök efter företag",
"4716fe18b1d4f74edf1a1cdacc83b329":"Butiken",
"50b5a47e8f2e9f988f509e248860acd0":"Visa åtgärder",
"a563aabceadd081e01b44f50d0c9ba26":"Från och med den 31 mars 2021 garanteras inte att Tradeshift-applikationer är kompatibla med version 11 av Internet Explorer. Uppdatera din webbläsare till en version som stöds av vår plattform.",
"e6e073d7d80064606e77b870ceaf452a":"Växla affärsenhet",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Försök med att läsa in sidan på nytt.",
"2837b35c04c44f52c772b01723ad1ff0":"Användarinställningar",
"5d3d3522e8b4899177ce29532ae479f0":"Visa alla affärsenheter",
"39b9368677bb137b328178942025581b":"Visa öppna order",
"488b2f58d018e655df26de3ea3f2590c":"Skriftlig förfrågan",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Du har inte behörighet att utföra den här åtgärden.",
"1bf011c064e4459d5665ff6548bb3c04":"Ditt konto är schemalagt för borttagning.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Ditt företagskonto samt ditt Tradeshift-användarkonto är planerade att tas bort den {deletionDate}. Om detta är ett misstag, kontakta en kontoadministratör.",
};
