import { types as layoutActionTypes } from 'lib/layout/layout';
import { default as appsActionTypes } from 'lib/apps/appsTypes';
import { types as rootActionTypes } from 'lib/root/root';
import constants from 'lib/helpers/constants';
import trackPanelEvent from 'lib/analytics/panelTracking';

// When user want click on app icon in the panel
// the MENU_SET action is fired prior to the APP_LAUNCH
// to have the correct data `panelOpen` we use this variable
// to store the panel state till the next app launch
let panelWasOpen;

const eventTrackerMiddleware = ({ dispatch, getState }) => next => action => {
	const state = getState();
	// Only track user direct interactions with Chrome Panel and
	// do not track the actions that was triggered by other apps
	if (action.isIOEvent) {
		return next(action);
	}
	switch (action.type) {
		case layoutActionTypes.MENU_SET:
			// only track if the panel was toggled
			if (state.layout.menu !== action.menu) {
				panelWasOpen = state.layout.menu;

				// Dirty workaround for situation that the pinned app gets clicked right after closing the panel
				// Without this the first pinned app click after closing the panel would have wrong panelOpen value
				if (!action.menu) {
					window.setTimeout(() => (panelWasOpen = false), 500);
				}
				trackPanelEvent(
					{
						event: constants.PANEL_EVENT_PANEL_TOGGLED,
						reduxAction: action.type,
						value: action.menu
					},
					state
				);
			}
			break;
		case appsActionTypes.APP_CLICK:
			if (action.app.id === 'Tradeshift.Messages') {
				trackPanelEvent(
					{
						event: constants.PANEL_EVENT_MESSAGES_APP_CLICK,
						reduxAction: action.type,
						value: action.app.id,
						pinned: action.app.pinned,
						panelOpen: panelWasOpen || state.layout.menu,
						unread: state.inbox.unread
					},
					state
				);
			}
			trackPanelEvent(
				{
					event: constants.PANEL_EVENT_APP_CLICK,
					reduxAction: action.type,
					value: action.app.id,
					pinned: action.app.pinned,
					panelOpen: panelWasOpen || state.layout.menu
				},
				state
			);
			panelWasOpen = state.layout.menu;
			break;
		case appsActionTypes.SETTINGS_APP_CLICK:
			trackPanelEvent(
				{
					event: constants.PANEL_EVENT_SETTINGS_CLICK,
					reduxAction: action.type,
					panelOpen: panelWasOpen || state.layout.menu
				},
				state
			);
			panelWasOpen = state.layout.menu;
			break;
		case rootActionTypes.USER_LOGOUT:
			trackPanelEvent(
				{
					event: constants.PANEL_EVENT_LOG_OUT,
					reduxAction: action.type
				},
				state
			);
			break;
		default:
			// do nothing;
			break;
	}

	return next(action);
};

export default eventTrackerMiddleware;
