import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import constants from 'lib/helpers/constants';
import Cover from 'components/cover/Cover';
import TsFrame from 'components/frames/TsFrame';
import AppListModalContainer from 'components/AppListModal/AppListModalContainer';
import CreateActionsModalContainer from 'components/CreateActionsModal/CreateActionsModalContainer';
import { selectors as layoutSelectors } from 'lib/layout/layout';
import 'components/frames/mainFrame.less';
import 'components/frames/collaborationFrame.less';
import 'components/frames/serviceFrame.less';
import 'components/frames/spawnedFrame.less';

export const Frames = ({ spawned, mainClass, collaborationClass }) => (
	<div id="frames-container">
		<div className={mainClass}>
			<TsFrame key={constants.FRAMES.MAIN} frameId={constants.FRAMES.MAIN} />
			<Cover />
			{Object.keys(spawned).map(frameId => (
				<TsFrame key={frameId} frameId={frameId} />
			))}
		</div>
		<div className="modal-container">
			<AppListModalContainer />
			<CreateActionsModalContainer />
		</div>
		<div className={collaborationClass}>
			<TsFrame key={constants.FRAMES.COLLABORATION} frameId={constants.FRAMES.COLLABORATION} />
		</div>
	</div>
);

Frames.propTypes = {
	spawned: PropTypes.object,
	mainClass: PropTypes.string,
	collaborationClass: PropTypes.string
};

const mapStateToProps = state => ({
	mainClass: layoutSelectors.mainClass(state),
	collaborationClass: layoutSelectors.collaborationClass(state),
	spawned: state.frames.spawned,
	_renderHack: Object.keys(state.frames.spawned).length
});

export default connect(mapStateToProps)(Frames);
