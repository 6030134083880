import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as layoutActions, filters as layoutFilters } from 'lib/layout/layout';
import { actions as appsActions } from 'lib/apps/apps';
import MenuItem from 'components/MenuItem/MenuItem';
import { appHref } from 'lib/helpers/helpers';

export const AppListSection = ({
	className,
	apps,
	children,
	closeMenu,
	appClick,
	tabIndex,
	activeAppId
}) => (
	<div className={className}>
		<div className="app-list-section tst-app-list-section">
			{apps.map(app => (
				<MenuItem
					data-tst-app={app.id}
					key={app.id}
					onClick={e => {
						e.stopPropagation();
						closeMenu();
						appClick(app.id);
					}}
					label={app.name}
					tabIndex={tabIndex}
					iconUrl={app.iconURL}
					{...appHref(app.href)}
					active={activeAppId === app.id}
				/>
			))}
		</div>
		{children}
	</div>
);

AppListSection.propTypes = {
	className: PropTypes.string,
	apps: PropTypes.arrayOf(PropTypes.object).isRequired,
	children: PropTypes.element
};

const mapStateToProps = state => ({
	activeAppId: state.frames.main.appId,
	tabIndex: layoutFilters.tabIndex(state)
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			closeMenu: () => layoutActions.setMenu(false),
			appClick: id => appsActions.appClick({ id, pinned: false })
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(AppListSection);
