import io from '@tradeshift/io';
import debug from 'debug';
import uuid from 'uuid/v4';
import constants from 'lib/helpers/constants';
import appsTypes from 'lib/apps/appsTypes';
import { actions as layoutActions } from 'lib/layout/layout';

/**
 * Debug logging
 */
const d = debug(`${constants.NS}:frames`);

export const initialState = {
	main: {
		id: constants.ID_MAIN_FRAME,
		className: constants.CLASS_MAIN_FRAME,
		win: null,
		src: 'about:blank',
		ready: false,
		appId: 'N/A'
	},
	inbox: {
		id: constants.ID_INBOX_FRAME,
		className: constants.CLASS_INBOX_FRAME,
		win: null,
		src: 'about:blank',
		ready: false,
		appId: 'N/A'
	},
	collaboration: {
		id: constants.ID_COLLABORATION_FRAME,
		className: constants.CLASS_COLLABORATION_FRAME,
		win: null,
		src: 'about:blank',
		ready: false,
		appId: 'N/A'
	},
	spawned: {}
};

export const types = {
	FRAME_SET: 'ts.chrome/frames/FRAME_SET',
	FRAME_SPAWN: 'ts.chrome/frames/FRAME_SPAWN',
	FRAME_UNSPAWN: 'ts.chrome/frames/FRAME_UNSPAWN'
};

function filterFrameProps(frame) {
	const propList = ['id', 'className', 'win', 'src', 'ready', 'appId', 'parent'];
	const outFrame = {};
	propList.forEach(prop => {
		if (frame[prop] !== undefined) {
			outFrame[prop] = frame[prop];
		}
	});
	return outFrame;
}

export default function reducer(state = initialState, action) {
	// Deep clone the state
	const frames = Object.values(constants.FRAMES);
	const outState = { ...state };
	frames.forEach(frame => {
		outState[frame] = { ...state[frame] };
	});
	const spawnedIds = Object.keys(state.spawned);
	if (spawnedIds.length) {
		outState.spawned = { ...state.spawned };
		spawnedIds.forEach(frameId => {
			outState.spawned[frameId] = { ...state.spawned[frameId] };
		});
	}

	switch (action.type) {
		case appsTypes.APP_LAUNCH:
			d('APP_LAUNCH: killing %o - %o', constants.FRAMES.MAIN, outState[constants.FRAMES.MAIN].win);
			let win = outState[constants.FRAMES.MAIN].win;
			// Only clear the current app if it's about to change.
			if (outState[constants.FRAMES.MAIN].appId !== action.app.id) {
				// Invalidates the current app in the ts.io hub
				io().forgetApp(outState[constants.FRAMES.MAIN].win);
				win = null;
			}
			spawnedIds.some(frameId => {
				const frame = outState.spawned[frameId];
				if (frame.parent === outState[constants.FRAMES.MAIN].appId) {
					d('APP_LAUNCH: killing %o (child) - %o', frame.appId, frame.win);
					io().forgetApp(frame.win);
					delete outState.spawned[frameId];
					return true;
				}
				return false;
			});
			action = {
				...action,
				target: constants.FRAMES.MAIN,
				src: action.frameURL,
				appId: action.app.id,
				win
			};

		// eslint-ignore-next-line no-fallthrough
		case types.FRAME_SET:
			const target = action.target;
			if (frames.includes(action.target)) {
				frames.forEach(frame => {
					outState[target] = { ...state[target], ...filterFrameProps(action) };
				});
			} else {
				outState.spawned[target] = { ...state.spawned[target], ...filterFrameProps(action) };
			}
			return outState;

		case types.FRAME_SPAWN:
			const frameId = uuid();
			outState.spawned[frameId] = {
				name: frameId,
				id: `frame-${frameId}`,
				className: constants.CLASS_SPAWNED_FRAME,
				...filterFrameProps(action)
			};
			return outState;

		case types.FRAME_UNSPAWN:
			spawnedIds.some(frameId => {
				const frame = outState.spawned[frameId];
				if (frame.appId === action.appId && frame.parent === action.parent) {
					io().forgetApp(frame.win);
					delete outState.spawned[frameId];
					return true;
				}
				return false;
			});
			return outState;

		default:
			return state;
	}
}

export const actions = {
	setFrame: frameOpts => ({
		type: types.FRAME_SET,
		...frameOpts
	}),
	spawnFrame: frameOpts => (dispatch, getState) => {
		dispatch(layoutActions.setCover(true));
		dispatch({
			type: types.FRAME_SPAWN,
			...frameOpts
		});
	},
	unspawnFrame: frameOpts => (dispatch, getState) => {
		const state = getState();
		if (Object.keys(state.frames.spawned).length === 1) {
			dispatch(layoutActions.setCover(false));
		}
		dispatch({
			type: types.FRAME_UNSPAWN,
			...frameOpts
		});
	}
};
