import debug from 'debug';
import KeepAlive from 'lib/events/KeepAlive';
import constants from 'lib/helpers/constants';
import App from 'lib/apps/App';
import history from 'lib/history/createHistory';
import URLParser from 'lib/apps/URLParser';
import types from 'lib/apps/appsTypes';
import selectors from 'lib/apps/appsSelectors';
import { get, post } from 'lib/helpers/fetch';
import { isStandalone, isDevelopment } from 'lib/helpers/helpers';
import { types as rootTypes } from 'lib/root/root';
import { actions as createActionsModalActions } from 'lib/apps/createActionsModal';

const d = debug(`${constants.NS}:apps`);

const initialState = {
	apps: [],
	descriptions: {},
	descriptionsLoading: false,
	redirects: {},
	busy: false,
	appListModal: {
		filterValue: '',
		visible: false
	}
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.APP_PIN:
			// We only pin the app, if there's room for it
			if (selectors.canPinApps({ apps: state })) {
				return {
					...state,
					apps: updateApps({
						apps: state.apps,
						id: action.id,
						action: app =>
							new App({
								...app,
								pinned:
									state.apps
										.filter(app => app.pinned)
										// Get the highest `pinned` value and add 1 to it
										.reduce((acc, app) => Math.max(acc, app.pinned), 0) + 1
							})
					})
				};
			}
			return state;

		case types.APP_UNPIN:
			return {
				...state,
				apps: updateApps({
					apps: state.apps,
					id: action.id,
					action: app =>
						new App({
							...app,
							pinned: 0
						})
				})
			};

		case rootTypes.DATA_FETCH:
			return {
				...state,
				busy: true
			};

		case rootTypes.DATA_FETCHED:
			return {
				...state,
				redirects: action.data.redirects || {},
				apps: action.data.apps.map(app =>
					new App().setFromApp({
						...app,
						redirect: action.data.redirects[app.id],
						legacyWrapper: Object.keys(process.config.legacyApps).includes(app.id)
					})
				)
			};

		case rootTypes.DATA_FETCHED_NEXT:
			return {
				...state,
				busy: false
			};

		case types.SAVE_PINNED_APPS:
			if (!isStandalone()) {
				const pinnedApps = selectors.getPinnedApps({ apps: state }).map(app => app.id);
				post(process.config.urls.favAppsURL, {}, { body: { pinnedApps, changedFav: action.data } });
			}
			return state;

		case types.GET_DESCRIPTIONS:
			return {
				...state,
				descriptionsLoading: true
			};

		case types.GET_DESCRIPTIONS_SUCCESS:
			return {
				...state,
				descriptionsLoading: false,
				descriptions: action.data.reduce((acc, item) => {
					acc[item.id] = item.description;
					return acc;
				}, {})
			};
		case types.GET_DESCRIPTIONS_FAILURE:
			return {
				...state,
				descriptionsLoading: false
			};

		case types.SHOW_MODAL:
			return {
				...state,
				appListModal: {
					visible: true,
					filterValue: ''
				}
			};

		case types.HIDE_MODAL:
			return {
				...state,
				appListModal: {
					visible: false,
					filterValue: ''
				}
			};

		case types.FILTER_APPS:
			return {
				...state,
				appListModal: {
					...state.appListModal,
					filterValue: action.data
				}
			};

		default:
			return state;
	}
};

export default reducer;

function updateApps({ apps, id, action }) {
	return apps.map(app => (app.id === id ? action(app) : app));
}

export const actions = {
	launchApp: (app, frameURL) => dispatch => {
		dispatch(actions.hideAppListModal());
		dispatch(createActionsModalActions.hideCreateActionsModal());
		dispatch({ type: types.APP_LAUNCH, app, frameURL });
	},
	pinApp: id => ({ type: types.APP_PIN, id }),
	unpinApp: id => ({ type: types.APP_UNPIN, id }),
	openApp: app => (dispatch, getState) => {
		const state = getState();
		// If the App would be redirected to another, we'll replace the original with the new one
		if (app.redirect) {
			const path = app.path;
			app = selectors.getApp(state, app.redirect);
			app.path = path;
		}
		dispatch(
			actions.launchApp(
				app,
				new URLParser(history.location, true, 'apps:launchApp', state.apps).url
			)
		);
	},

	// Just to track it in the eventTracker in middleware
	appClick: app => ({ type: types.APP_CLICK, app }),
	settingsAppClick: () => ({ type: types.SETTINGS_APP_CLICK }),
	supportAppClick: () => ({ type: types.SUPPORT_APP_CLICK }),
	savePinnedApps: changedFav => ({ type: types.SAVE_PINNED_APPS, data: changedFav }),
	showAppListModal: () => dispatch => {
		dispatch(createActionsModalActions.hideCreateActionsModal());
		dispatch(actions.fetchDescriptions());
		dispatch({ type: types.SHOW_MODAL });
	},
	hideAppListModal: () => ({ type: types.HIDE_MODAL }),
	filterApps: filterValue => ({ type: types.FILTER_APPS, data: filterValue }),
	getDescriptions: () => ({ type: types.GET_DESCRIPTIONS }),
	getDescriptionsSuccess: descriptions => ({
		type: types.GET_DESCRIPTIONS_SUCCESS,
		data: descriptions
	}),
	getDescriptionsFailure: err => ({
		type: types.GET_DESCRIPTIONS_FAILURE,
		data: err
	}),
	fetchDescriptions: () => async (dispatch, getState) => {
		const descriptions = getState().apps.descriptions;
		if (descriptions && Object.keys(descriptions).length > 0) {
			return;
		}
		dispatch(actions.getDescriptions());
		try {
			let data;
			if (!isStandalone()) {
				/**
				 * Always update the AccessToken before making a new request
				 */
				await KeepAlive.updateAccessToken();
				d('Loading data from %o', process.config.urls.descriptionsURL);
				data = await get(process.config.urls.descriptionsURL);
			} else {
				data = require('mockdata/apps').descriptions;
				await new Promise(resolve => {
					setTimeout(resolve, constants.DURATION_STANDALONE_STARTUP);
				});
			}
			d('Loaded data %O', data);
			dispatch(actions.getDescriptionsSuccess(data));
		} catch (err) {
			/**
			 * In case we're in development mode, we should load the dummy data,
			 * if V4 is unreachable
			 */
			if (isDevelopment()) {
				dispatch(actions.getDescriptionsSuccess(require('mockdata/apps').descriptions));
			} else {
				// TODO: we need to track errors somehow?
				dispatch(actions.getDescriptionsFailure(err));
			}
		}
	}
};
