import React, { Suspense, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalLoadingCover from 'components/ModalLoadingCover/ModalLoadingCover';

const CreateActionsModal = React.lazy(() => import('./CreateActionsModal'));

function CreateActionsModalContainer({ visible }) {
	const [loaded, setLoaded] = useState(false);
	if (!visible && !loaded) {
		return null;
	}
	if (visible && !loaded) {
		setLoaded(true);
		return <ModalLoadingCover />;
	}
	return (
		<div>
			<Suspense fallback={<ModalLoadingCover />}>
				<CreateActionsModal />
			</Suspense>
		</div>
	);
}

CreateActionsModalContainer.propTypes = {
	visible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	visible: state.createActionsModal.visible
});

export default connect(mapStateToProps)(CreateActionsModalContainer);
