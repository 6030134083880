import analytics from './analytics';

export default function sendPanelTrackingToAnalytics(data, state) {
	const trackPayload = eventTrackerPayload(data, state);
	analytics('track', trackPayload, trackPayload.properties.appId);
}

function eventTrackerPayload(data, state) {
	const pinnedApps = state.apps.apps.filter(app => app.pinned).map(app => app.id);

	return {
		event: data.event,
		properties: {
			appId: state.frames.main.appId,
			pinnedApps,
			panelOpen: state.layout.menu,
			...data
		}
	};
}
