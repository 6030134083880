module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Koleksi produk yang ingin Anda tawarkan untuk dijual kepada pembeli tertentu.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Dokumen yang dikeluarkan oleh penjual kepada pembeli yang menunjukkan kuantitas dan biaya produk atau layanan yang disediakan.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Tagihan awal penjualan dikirim sebelum pengiriman atau pengantaran barang.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Akun dijadwalkan untuk dihapus.",
"4e917d587825921098d19cb5374ffa54":"Tambahkan aplikasi ke favorit",
"7328b929c3b7ea8a93ee9e597817f510":"Semua aplikasi",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Ikon tanda panah",
"421d54e688f76e063b53b1bd67178b42":"Aplikasi yang Tersedia",
"614bb33c219a0809bbe8e52f938dcce7":"Order pembelian selimut (permintaan)",
"51d895d6183265b06e899570e19eecf6":"Logo Cabang",
"9d6641632312bcd817a18331b01bd073":"Telusuri dan cari produk yang dapat dibeli.",
"883d14b9efdb4894d9879c0eff9b5acb":"Periksa status platform atau dukungan kontak.",
"55fc4360777c0241e107f70379e57e75":"Tutup menu",
"da76f49c6f0252b80a6e330d6394c094":"Konfirmasikan penerimaan produk dan layanan berdasarkan order pembelian terbuka.",
"67f2b8b530a56e35ec1e8254fb72748f":"Permintaan koneksi",
"9860a293ae95e8583f868975af1f4849":"Hubungi Bagian Dukungan",
"210d71bea3790d832babc970a6674be3":"Konversikan order terbuka menjadi faktur.",
"8df8c2d028d750d31bb796681c2ea3f9":"Buat",
"e3f60bea8ce9079c56a6a69f86f4f048":"Buat Nota Kredit",
"5b5efdc0c8989fe1056b0e54734e824d":"Buat Faktur",
"fadd16ebdc81e4fde9e7fc3154877894":"Buat Faktur Pembayaran di Muka",
"0e1cf03ccaae390506a0da5f0f739280":"Buat Faktur Proforma",
"20c9ff16bb2f0448290a5087a58cdebf":"Buat dan kirim dokumen Penawaran ke pelanggan Anda.",
"830fa5e28d0b88eb70218ea763f71c5c":"Buat order pembelian selimut",
"d75c3f6646800bc987c0c07f986b6111":"Buat produk baru",
"9f7cd528570ea5fa70d5f0fdb8227759":"Buat penawaran",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Buat order",
"9f2650a5abb1e17ffb4714580cc4c457":"Buat produk",
"581c02d2262d5818fb14faef2a5c5e4e":"Buat penawaran",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Buat permintaan tertulis",
"3f884136e59449da353adefd80121181":"Buat...",
"d23014cf5a5e7f8c6440b96692831aa8":"Nota Kredit",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Tentukan item produk atau layanan dengan harga (perkiraan) dan anggaran internal untuk membuat order pembelian selama jangka waktu tertentu.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Tentukan produk untuk dijual di Pasar Tradeshift.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Tentukan permintaan Anda untuk produk atau layanan yang tidak dapat Anda temukan di Toko dan tambahkan ke Keranjang atau Permintaan Pembelian.",
"1b0dd1aca58f84099cff422597422e56":"Gagal memuat Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Gagal memuat Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Tanda terima barang",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Kelompokkan perusahaan ke dalam grup jaringan untuk menyederhanakan administrasi",
"d7b639ae724bad4cb10abf6e411bd74e":"Aplikasi yang terpasang",
"3c4be400f728da4526de626653d56ccc":"Faktur",
"697ca358c7443b09bab9478598f14726":"Faktur dari order",
"db13bed8b517884b5e8e257c3e829e7d":"Berikan kredit untuk pembelian di masa mendatang.",
"8535934d42d580306640d88e0ed217aa":"Memuat perusahaan…",
"802ed138bb62eb56ff187f839234478b":"Memuat…",
"1c8e8813d12a483823656cc7c245cd58":"Keluar",
"b25df08fcf0d0acaf6384653ea02141e":"Keluar",
"34bea6b12f16fea4889a2a80c07ee8ec":"Kelola grup",
"7a558a55eb2d9e641f608f759964a9f6":"Buat order pembelian untuk penjual secara manual, yang menunjukkan barang atau layanan, kuantitas dan harga yang diusulkan.",
"fbb9cc68fee07e74094da1030bfdf236":"Grup jaringan",
"ba116f853ca15c3b6f136da33867158b":"Tidak ada aplikasi yang cocok dengan istilah pencarian Anda.",
"14877050aa06ad5917e9d7a7048ad2e3":"Tidak ada deskripsi.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Tidak ada izin",
"bacad0fc8777924b7400a5b05b8cc715":"Pada {deletionDate}, akun perusahaan Anda dan akun pengguna Tradeshift Anda akan dihapus. Jika ini merupakan kesalahan, Anda dapat membatalkannya.",
"2778717f6acbeb1edaac6a2656b478f3":"Buka",
"37f066e2502bac5316dfc900890ade4c":"Buka menu",
"2ae053867612b2202a870bd52cc3ff63":"Status Platform",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Silakan hubungi administrator akun jika merasa yakin bahwa izin Anda harus diubah.",
"281f9682838b746f5a0b8a4555614843":"Faktur Pra-pembayaran",
"387117ac2a32235f30c143484f94147f":"Faktur Proforma",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Pembelian dari Toko",
"9bb4a36de6bff47eb4209096972979dd":"Penawaran",
"befc83a6b9e66cbfb953b901b91290d0":"Telah mencapai jumlah maksimum favorit!",
"beb839cf63984c0634d05bdc900e1905":"Terima barang dari order",
"d1b0cf6017a647b49abde5b8ba6fe614":"Cabang yang baru saja digunakan",
"793f04b605fc32835a578962a6994422":"Catat pembayaran di muka",
"4eddd0ca790e495626f275843c87f440":"Muat Ulang",
"ab8100964aeb93c35539b88420121939":"Hapus aplikasi dari favorit",
"d817327a5392f2d398ed8721a314bc37":"Cari aplikasi",
"31e12c8c15eae192e29acf9fcb949287":"Cari perusahaan di Jaringan Tradeshift untuk berbisnis.",
"3bcee8f61c10123abe392bf0cc31f99c":"Cari perusahaan",
"4716fe18b1d4f74edf1a1cdacc83b329":"Toko",
"50b5a47e8f2e9f988f509e248860acd0":"Tampilkan tindakan",
"a563aabceadd081e01b44f50d0c9ba26":"Mulai tanggal 31 Maret 2021, aplikasi Tradeshift tidak dijamin akan kompatibel dengan Internet Explorer versi 11. Harap perbarui browser Anda ke versi yang didukung oleh platform kami.",
"3ad73c1b179af6e6df8e5da91028b57c":"Dukungan",
"e6e073d7d80064606e77b870ceaf452a":"Alihkan cabang",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Coba muat ulang halaman.",
"2837b35c04c44f52c772b01723ad1ff0":"Pengaturan pengguna",
"5d3d3522e8b4899177ce29532ae479f0":"Lihat semua cabang",
"39b9368677bb137b328178942025581b":"Lihat order terbuka",
"488b2f58d018e655df26de3ea3f2590c":"Permintaan tertulis",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Anda tidak memiliki izin untuk melakukan tindakan ini.",
"1bf011c064e4459d5665ff6548bb3c04":"Akun Anda dijadwalkan untuk dihapus.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Akun perusahaan Anda, serta akun pengguna Tradeshift Anda, dijadwalkan untuk dihapus pada {deletionDate}. Jika ini merupakan kesalahan, hubungi administrator akun.",
};
