const userData = {
	companyName: 'Acme Corp.',
	userName: 'MOTUS',
	avatarURL: process.env.PUBLIC_URL + '/static/images/mock/avatarPlaceholder.svg',
	companyIsPremium: false,
	companyCountry: 'GB',
	email: 'user_email_adr@example.com',
	language: 'en'
};

export default userData;
