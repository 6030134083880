import io from '@tradeshift/io';
import constants from 'lib/helpers/constants';
import store from 'lib/store';
import URLParser from 'lib/apps/URLParser';
import EventHandler from 'lib/events/EventHandler';
import appSelectors from 'lib/apps/appsSelectors';
import { actions as framesActions } from 'lib/frames/frames';

let hub;

export function topApp() {
	return hub.top();
}

export default function setupIO() {
	hub = io({
		appByWindow(win) {
			if (!win) {
				throw new Error('Empty Window object passed to appIdByWindow()!');
			}
			if (win === window.top) {
				return process.config.featureApps.chrome;
			}

			const frames = store.getState().frames;
			// eslint-disable-next-line default-case
			switch (win) {
				case frames[constants.FRAMES.INBOX].win:
					return process.config.featureApps.inbox;
				case frames[constants.FRAMES.COLLABORATION].win:
					return process.config.featureApps.collaboration;
				case frames[constants.FRAMES.MAIN].win:
					return frames[constants.FRAMES.MAIN].appId;
			}

			const spawnedFrameId = Object.keys(frames.spawned).find(
				frameId => win === frames.spawned[frameId].win
			);
			if (spawnedFrameId) {
				return frames.spawned[spawnedFrameId].appId;
			}

			console.warn('App not found ', win);
		},
		windowByApp(appId, sourceWindow) {
			if (!appId) {
				throw new Error('Empty App Id passed to windowByAppId()!');
			}
			if (appId === process.config.featureApps.chrome) {
				return window.top;
			}

			const frames = store.getState().frames;
			// eslint-disable-next-line default-case
			switch (appId) {
				case process.config.featureApps.inbox:
					return frames[constants.FRAMES.INBOX].win;
				case process.config.featureApps.collaboration:
					return frames[constants.FRAMES.COLLABORATION].win;
				case frames[constants.FRAMES.MAIN].appId:
					return frames[constants.FRAMES.MAIN].win;
			}

			const spawnedFrameId = Object.keys(frames.spawned).find(
				frameId => appId === frames.spawned[frameId].appId
			);
			if (spawnedFrameId) {
				return frames.spawned[spawnedFrameId].win;
			}

			console.warn('Window not found ', appId);
		},
		handleAppTimeout(appId, win) {
			console.log(appId + ' in ', win, 'timed out.');
		},
		handleAppSpawn(spawnedAppId, parentAppId) {
			console.log('spawn', spawnedAppId, parentAppId);
			const app = appSelectors.getAppAfterRedirect(store.getState(), spawnedAppId);
			if (!app) {
				throw new Error(`Can't find ${spawnedAppId} in the list of activated apps`);
			}
			store.dispatch(
				framesActions.spawnFrame({
					appId: app.id,
					src: new URLParser(app.href, true, 'frames:spawn').url,
					/**
					 * @TODO use a more robust way of storing the parent for message routing
					 */
					parent: parentAppId
				})
			);
			return app.id;
		},
		handleAppSubmit(spawnedAppId, parentAppId, data) {
			store.dispatch(
				framesActions.unspawnFrame({
					appId: spawnedAppId,
					parent: parentAppId
				})
			);
		}
	});

	topApp().on(msg => console.log(msg));
	topApp().on('*', ({ topic, data }) => EventHandler.chromeHandler({ key: topic, data }));
}
