import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'lib/language/language';
import { appHref, switchBranch } from 'lib/helpers/helpers';
import { actions as layoutActions } from 'lib/layout/layout';
import { selectors as appSelectors } from 'lib/apps/appsSelectors';
import { selectors as rootSelectors } from 'lib/root/root';
import DeferredImage from 'components/DeferredImage';
import constants from 'lib/helpers/constants';
import { setBranchSwitchLog, setItem } from 'lib/localStorage';
import CompanyProfile from 'components/menu/branchswitcher/__static__/CompanyProfile.svg';
import 'components/menu/branchswitcher/branchSwitcher.less';

export const BranchSwitcher = ({
	t,
	branchSwitchAttrs,
	recentBranches,
	closeMenu,
	companyName
}) => (
	<div className="branch-switcher">
		{recentBranches.length > 0 && (
			<Fragment>
				<div className="branch-switcher-header">{t('Recently used branches')}</div>
				<div className="recent-branches-wrapper">
					{recentBranches.map(branch => (
						<button
							className="branch-option"
							onClick={e => {
								const loc = {
									pathname: window.location.hash.split('#')[1].split('?')[0],
									search: '?' + window.location.hash.split('#')[1].split('?')[1]
								};
								setItem(constants.KEY_QUICK_BRANCH_SWITCH_LAST_URL, JSON.stringify(loc));
								setBranchSwitchLog({
									id: window.ts.chrome.config.companyId.valueOf(),
									name: companyName
								});
								window.ts.chrome.config.companyId = branch.id;

								switchBranch(branch);
								closeMenu();
							}}
						>
							<DeferredImage src={branch.logo || CompanyProfile} alt={t('[Alt] Branch Logo')} />
							<span className="branch-name">{branch.name}</span>
						</button>
					))}
				</div>
			</Fragment>
		)}
		<a
			{...branchSwitchAttrs}
			onClick={e => {
				e.stopPropagation();
				closeMenu();
			}}
			className="branch-selector-link"
			title={t('[Alt] Switch branch')}
		>
			<span>{t('[Button] View all branches')}</span>
		</a>
	</div>
);

BranchSwitcher.propTypes = {
	t: PropTypes.func.isRequired,
	companyName: PropTypes.string.isRequired,
	recentBranches: PropTypes.array,
	branchSwitchAttrs: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	t: t(),
	branchSwitchAttrs: {
		...appHref((appSelectors.getApp(state, process.config.featureApps.branchSelector) || {}).href)
	},
	recentBranches: rootSelectors.recentBranches(state),
	companyName: state.menu.user.companyName
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			closeMenu: () => layoutActions.setMenu(false)
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(BranchSwitcher);
