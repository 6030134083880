import debug from 'debug';
import constants from './helpers/constants';

const d = debug(`${constants.NS}:localStorage`);

/**
 * Get item from localStorage
 * @param key
 * @returns {string}
 */
export const getItem = key => window.localStorage.getItem(key);
/**
 * Set item to localStorage
 * @param key
 * @param val
 */
export const setItem = (key, val) => window.localStorage.setItem(key, String(val));

/**
 * Get the openCounts map
 * @returns {object}
 */
const getAllOpenCounts = () => {
	let openCounts = {};
	try {
		openCounts = JSON.parse(getItem(constants.KEY_APP_OPEN_COUNTS)) || {};
	} catch (e) {
		d('getAllOpenCounts failed to parse JSON %O', e);
	}
	return openCounts;
};

/**
 * Reuse the openCounts map for pinned apps if the user never set his own pinned apps
 * @returns {object}
 */
export const getUserQuickLaunchApps = () => {
	const userOpenCounts = getAllOpenCounts()[getUserId()];
	if (userOpenCounts) {
		return Object.keys(userOpenCounts)
			.sort((a, b) => userOpenCounts[b] - userOpenCounts[a])
			.slice(0, constants.NUM_GRID_APPS);
	} else {
		return process.config.defaultPinnedApps;
	}
};

function hasUserAndCompanyId() {
	return (
		process.config.request && process.config.request.userId && process.config.request.companyId
	);
}

function getUserAndCompanyId() {
	return `${process.config.request.userId}/${process.config.request.companyId}`;
}

function getUserId() {
	return process.config.request.userId;
}

/**
 * Get the items for all users that have used the current device
 */
function getItems(key) {
	let items = {};
	try {
		items = JSON.parse(getItem(key)) || {};
	} catch (e) {
		d('getItems failed to parse JSON %O for key %s', e, key);
	}
	return items;
}

/**
 * Get the item for the current user
 */
export const getItemForCurrentUser = key => {
	const items = getItems(key);
	if (items && hasUserAndCompanyId()) {
		return items[getUserAndCompanyId()];
	}
};

/**
 * Set the item for the current user
 */
export const setItemForCurrentUser = (key, item) => {
	if (hasUserAndCompanyId()) {
		const items = getItems(key);
		items[getUserAndCompanyId()] = item;
		setItem(key, JSON.stringify(items));
	} else {
		d('setItemForCurrentUser failed for %s: %O due to missing user and company ID', key, item);
	}
};

/**
 * Set error count AKA number of times the user has seen the "Failed to load" page
 */
export const getErrorCount = () => parseInt(getItem(constants.KEY_ERROR_COUNT), 10) || 0;

/**
 * Get error count
 */
export const setErrorCount = errorCount =>
	setItem(constants.KEY_ERROR_COUNT, parseInt(errorCount, 10));

/**
 * Returns true if input is null
 *
 * @param {string} val
 * @returns {boolean}
 */
function trueIfNull(val) {
	switch (val) {
		case 'false':
			return false;
		case 'true':
		default:
			return true;
	}
}

/**
 * Is the large device layout enabled?
 */
export const hasLargeDevice = hasLargeDevice => trueIfNull(getItem(constants.KEY_DEVICE_LARGE));
/**
 * Is the medium device layout enabled? Always off.
 * @TODO Fix breakpoints for real, instead of a quick hack to disable the MEDIUM breakpoint.
 */
export const hasMediumDevice = hasMediumDevice => false;
/**
 * Is the small device layout enabled?
 */
export const hasSmallDevice = hasSmallDevice => trueIfNull(getItem(constants.KEY_DEVICE_SMALL));

export const hasDevice = device => {
	switch (device) {
		case constants.DEVICES.LARGE:
			return hasLargeDevice();
		case constants.DEVICES.MEDIUM:
			return hasMediumDevice();
		case constants.DEVICES.SMALL:
			return hasSmallDevice();
		default:
			return false;
	}
};

export const getAllUsersBranchSwitchLog = () => {
	return JSON.parse(getItem(constants.KEY_BRANCH_SWITCH_LOGS)) || {};
};

export const getBranchSwitchLog = () => {
	let recentBranches = [];
	const userId = process.config.request.userId;
	const allRecentBranches = getAllUsersBranchSwitchLog();
	if (allRecentBranches && allRecentBranches[userId]) {
		recentBranches = allRecentBranches[userId];
	}
	return recentBranches;
};

export const setBranchSwitchLog = branch => {
	const userId = process.config.request.userId;
	const oldLog = getAllUsersBranchSwitchLog();
	oldLog[userId] = oldLog[userId] ? [branch, ...oldLog[userId]] : [branch];
	const newLog = JSON.stringify(oldLog);
	setItem(constants.KEY_BRANCH_SWITCH_LOGS, newLog);
};
