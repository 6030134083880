import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Header from 'components/menu/header/Header';
import Footer from 'components/menu/footer/Footer';
import Inbox from 'components/inbox/Inbox';
import AppList from 'components/apps/AppList';
import { selectors as layoutSelectors, actions as layoutActions } from 'lib/layout/layout';
import 'components/menu/menu.less';

export const Menu = ({ menuAttrs, menuAnimClassName, openMenu }) => (
	<div
		className={[menuAttrs.className, menuAnimClassName].join(' ')}
		tabIndex="0"
		onClick={openMenu}
	>
		<Header />
		<div className="container">
			<Inbox />
			<AppList />
		</div>
		<Footer />
	</div>
);

Menu.propTypes = {
	menuAnimClassName: PropTypes.string,
	menuAttrs: PropTypes.object.isRequired,
	openMenu: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	menuAttrs: {
		className: layoutSelectors
			.menuClass(state)
			.concat([state.layout.footerOpen ? 'footer-open' : ''])
	}
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			openMenu: () => layoutActions.setMenu(true)
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
