module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Kolekcia produktov, ktoré chcete ponúknuť na predaj konkrétnemu kupujúcemu.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Dokument vydaný predajcom kupujúcemu, v ktorom je uvedené množstvo a ceny za poskytnuté produkty alebo služby.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Predbežný doklad o predaji zaslaný pred odoslaním alebo dodaním tovaru.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Účet je naplánovaný na odstránenie.",
"4e917d587825921098d19cb5374ffa54":"Pridať aplikáciu k obľúbeným",
"7328b929c3b7ea8a93ee9e597817f510":"Všetky aplikácie",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Ikona šípky",
"421d54e688f76e063b53b1bd67178b42":"Dostupné aplikácie",
"614bb33c219a0809bbe8e52f938dcce7":"Hromadná nákupná objednávka (požiadavka)",
"51d895d6183265b06e899570e19eecf6":"Logo pobočky",
"9d6641632312bcd817a18331b01bd073":"Prejdite a vyhľadajte produkty dostupné na nákup.",
"883d14b9efdb4894d9879c0eff9b5acb":"Skontrolujte stav platformy alebo kontaktujte technickú podporu.",
"55fc4360777c0241e107f70379e57e75":"Zatvoriť ponuku",
"da76f49c6f0252b80a6e330d6394c094":"Potvrďte príjem produktov a služieb na základe otvorenej objednávky.",
"67f2b8b530a56e35ec1e8254fb72748f":"Požiadavka na pripojenie",
"9860a293ae95e8583f868975af1f4849":"Kontaktovať podporu",
"210d71bea3790d832babc970a6674be3":"Konvertujte otvorenú objednávku na faktúru.",
"8df8c2d028d750d31bb796681c2ea3f9":"Vytvoriť",
"e3f60bea8ce9079c56a6a69f86f4f048":"Vytvoriť dobropis",
"5b5efdc0c8989fe1056b0e54734e824d":"Vytvoriť faktúru",
"fadd16ebdc81e4fde9e7fc3154877894":"Vytvoriť zálohovú faktúru",
"0e1cf03ccaae390506a0da5f0f739280":"Vytvorte proforma faktúru",
"20c9ff16bb2f0448290a5087a58cdebf":"Vytvárajte a odosielajte dokumenty cenových ponúk svojim zákazníkom.",
"830fa5e28d0b88eb70218ea763f71c5c":"Vytvorte hromadnú nákupnú objednávku",
"d75c3f6646800bc987c0c07f986b6111":"Vytvorte nový produkt",
"9f7cd528570ea5fa70d5f0fdb8227759":"Vytvoriť ponuku",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Vytvoriť objednávku",
"9f2650a5abb1e17ffb4714580cc4c457":"Vytvoriť produkt",
"581c02d2262d5818fb14faef2a5c5e4e":"Vytvoriť cenovú ponuku",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Vytvoriť požiadavku na nákup",
"3f884136e59449da353adefd80121181":"Vytvoriť...",
"d23014cf5a5e7f8c6440b96692831aa8":"Dobropis",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definujte položky produktov alebo služieb s (odhadovanými) cenami a internými rozpočtami, aby ste vytvorili nákupné objednávky na zadané obdobie.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definujte výrobky na predaj na trhoviskách Tradeshift",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definujte svoju požiadavku na produkt alebo službu, ktoré ste v obchode nenašli a pridajte ich do košíka alebo požiadavky na nákup.",
"1b0dd1aca58f84099cff422597422e56":"Nepodarilo sa načítať Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Nepodarilo sa načítať Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Potvrdenie o prijatí tovaru",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Zoskupte spoločnosti do sieťových skupín, aby ste zjednodušili administratívu",
"d7b639ae724bad4cb10abf6e411bd74e":"Nainštalované aplikácie",
"3c4be400f728da4526de626653d56ccc":"Faktúra",
"697ca358c7443b09bab9478598f14726":"Faktúra z objednávky",
"db13bed8b517884b5e8e257c3e829e7d":"Vystavte úver na budúce nákupy.",
"8535934d42d580306640d88e0ed217aa":"Načítava sa spoločnosť…",
"802ed138bb62eb56ff187f839234478b":"Načítava sa",
"1c8e8813d12a483823656cc7c245cd58":"Odhlásiť sa",
"b25df08fcf0d0acaf6384653ea02141e":"Odhlásiť sa",
"34bea6b12f16fea4889a2a80c07ee8ec":"Spravovať skupiny",
"7a558a55eb2d9e641f608f759964a9f6":"Ručne vytvorte nákupnú objednávku pre predajcu s uvedením navrhovaného tovaru alebo služieb, množstiev a cien.",
"fbb9cc68fee07e74094da1030bfdf236":"Sieťová skupina",
"ba116f853ca15c3b6f136da33867158b":"Vyhľadávanému výrazu nezodpovedajú žiadne aplikácie.",
"14877050aa06ad5917e9d7a7048ad2e3":"Bez popisu.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Žiadne povolenie",
"bacad0fc8777924b7400a5b05b8cc715":"Dňa {deletionDate} bude odstránený účet vašej spoločnosti a váš používateľský účet Tradeshift. Ak ide o chybu, môžete akciu zrušiť.",
"2778717f6acbeb1edaac6a2656b478f3":"Otvoriť",
"37f066e2502bac5316dfc900890ade4c":"Otvoriť ponuku",
"018c4048ea00acea67a5819288f4703f":"Objednávka",
"2ae053867612b2202a870bd52cc3ff63":"Stav platformy",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Ak si myslíte, že by sa vaše povolenia mali zmeniť, kontaktujte svojho správcu účtu.",
"281f9682838b746f5a0b8a4555614843":"Zálohová faktúra",
"387117ac2a32235f30c143484f94147f":"Proforma faktúra",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Nákup z obchodu",
"9bb4a36de6bff47eb4209096972979dd":"Cenová ponuka",
"befc83a6b9e66cbfb953b901b91290d0":"Dosiahli ste maximálny počet obľúbených položiek!",
"beb839cf63984c0634d05bdc900e1905":"Prijať tovar z objednávky",
"d1b0cf6017a647b49abde5b8ba6fe614":"Naposledy použité pobočky",
"793f04b605fc32835a578962a6994422":"Zaznamenajte zálohovú platbu.",
"4eddd0ca790e495626f275843c87f440":"Znova načítať",
"ab8100964aeb93c35539b88420121939":"Odobrať aplikáciu z obľúbených",
"d817327a5392f2d398ed8721a314bc37":"Vyhľadajte aplikácie",
"31e12c8c15eae192e29acf9fcb949287":"Vyhľadajte v sieti Tradeshift spoločnosti, s ktorými chcete obchodovať.",
"3bcee8f61c10123abe392bf0cc31f99c":"Vyhľadávať názov skupiny",
"4716fe18b1d4f74edf1a1cdacc83b329":"Obchod",
"50b5a47e8f2e9f988f509e248860acd0":"Zobraziť akcie",
"a563aabceadd081e01b44f50d0c9ba26":"Od 31. marca 2021 nie je zaručená kompatibilita aplikácií Tradeshift s verziou 11 prehľadávača Internet Explorer. Aktualizujte si prehliadač na verziu podporovanú našou platformou.",
"3ad73c1b179af6e6df8e5da91028b57c":"Podpora",
"e6e073d7d80064606e77b870ceaf452a":"Prepnúť pobočku",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Skúste znova načítať stránku.",
"2837b35c04c44f52c772b01723ad1ff0":"Používateľské nastavenia",
"5d3d3522e8b4899177ce29532ae479f0":"Zobraziť všetky pobočky",
"39b9368677bb137b328178942025581b":"Zobraziť otvorené objednávky",
"488b2f58d018e655df26de3ea3f2590c":"Písomná požiadavka",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Nemáte povolenie na vykonanie tejto akcie.",
"1bf011c064e4459d5665ff6548bb3c04":"Váš účet je naplánovaný na odstránenie.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Účet vašej spoločnosti ako aj váš používateľský účet Tradeshift sú naplánované na odstránenie dňa {deletionDate}. Ak ide o chybu, kontaktujte správcu účtu.",
};
