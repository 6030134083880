export const filters = {
	apps: state => state.apps.apps,
	busy: state => state.apps.busy,
	descriptions: state => state.apps.descriptions,
	filterValue: state => state.apps.appListModal.filterValue,
	appIdToMatch: (_, appId) => appId,
	redirects: state => state.apps.redirects,
	createActions: state => state.createActionsModal.createActions,
	createActionsApps: state => state.createActionsModal.createActionsApps
};

export default filters;
