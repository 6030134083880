/* eslint-disable default-case */
import { createSelector } from 'reselect';
import constants from 'lib/helpers/constants';
import responsive from 'lib/layout/responsive';
import appsTypes from 'lib/apps/appsTypes';
import { filters as menuFilters } from 'lib/menu/menu';
import { selectors as appSelectors } from 'lib/apps/appsSelectors';
import { actions as appsActions } from 'lib/apps/apps';

export const initialState = {
	device: -1,
	collaboration: false,
	menu: false,
	cover: false,
	fullscreen: false,
	maxIcons: constants.NUM_GRID_APPS,
	footerOpen: false,
	branchSwitcherOpen: false
};

export const types = {
	DEVICE_SET: 'ts.chrome/layout/DEVICE_SET',
	FULLSCREEN_SET: 'ts.chrome/layout/FULLSCREEN_SET',
	COLLABORATION_SET: 'ts.chrome/layout/COLLABORATION_SET',
	COVER_SET: 'ts.chrome/layout/COVER_SET',
	MENU_SET: 'ts.chrome/layout/MENU_SET',
	COVER_CLICK: 'ts.chrome/layout/COVER_CLICK',
	MAX_ICONS_SET: 'ts.chrome/layout/MAX_ICONS_SET',
	TOGGLE_FOOTER: 'ts.chrome/menu/TOGGLE_FOOTER',
	TOGGLE_BRANCH_SWITCHER: 'ts.chrome/menu/TOGGLE_BRANCH_SWITCHER'
};

const reducer = (state = initialState, action) => {
	let device = state.device;
	let collaboration = state.collaboration;
	let cover = state.cover;
	let menu = state.menu;
	let fullscreen = state.fullscreen;
	let maxIcons = state.maxIcons;
	let footerOpen = state.footerOpen;
	let branchSwitcherOpen = state.branchSwitcherOpen;

	switch (action.type) {
		// eslint-disable-next-line no-fallthrough
		case types.MENU_SET:
			menu = action.menu;
			if (!menu) {
				footerOpen = false;
				branchSwitcherOpen = false;
			}
			switch (device) {
				case constants.DEVICES.MOBILE:
					collaboration = false;
					break;
				case constants.DEVICES.SMALL:
					if (menu || collaboration) {
						cover = true;
					} else if (!menu && !collaboration) {
						cover = false;
					}
					break;
				case constants.DEVICES.MEDIUM:
					if (menu) {
						collaboration = false;
					}
					break;
			}
			break;

		case types.COLLABORATION_SET:
			collaboration = action.collaboration;
			switch (device) {
				case constants.DEVICES.SMALL:
					if (collaboration) {
						cover = true;
					} else if (!menu) {
						cover = false;
					}
					break;
				case constants.DEVICES.MEDIUM:
					if (collaboration) {
						menu = false;
					}
					break;
			}
			break;

		case appsTypes.APP_LAUNCH:
			cover = false;
			switch (device) {
				case constants.DEVICES.MOBILE:
				case constants.DEVICES.SMALL:
				case constants.DEVICES.MEDIUM:
					collaboration = false;
					menu = false;
					break;
			}
			break;

		case types.COVER_CLICK:
			cover = false;
			menu = false;
			collaboration = false;
			footerOpen = false;
			branchSwitcherOpen = false;
			break;

		case types.COVER_SET:
			cover = action.cover;
			break;

		case types.FULLSCREEN_SET:
			fullscreen = action.fullscreen;
			break;

		case types.DEVICE_SET:
			device = action.device;
			switch (device) {
				case constants.DEVICES.SMALL:
					if (menu || collaboration) {
						cover = true;
					}
					break;
				case constants.DEVICES.MEDIUM:
					cover = false;
					if (menu && collaboration) {
						collaboration = true;
						menu = false;
					}
					break;
				case constants.DEVICES.MOBILE:
				case constants.DEVICES.LARGE:
					cover = false;
					break;
			}
			break;

		case types.MAX_ICONS_SET:
			maxIcons = action.maxIcons;
			break;

		case types.TOGGLE_FOOTER:
			footerOpen = action.state;
			break;

		case types.TOGGLE_BRANCH_SWITCHER:
			branchSwitcherOpen = action.state;
			break;
	}
	return {
		...state,
		cover,
		device,
		collaboration,
		menu,
		fullscreen,
		maxIcons,
		footerOpen,
		branchSwitcherOpen
	};
};

export default reducer;

export const actions = {
	clickCover: () => ({ type: types.COVER_CLICK }),
	setCover: cover => ({ type: types.COVER_SET, cover }),
	setCollaboration: collaboration => ({
		type: types.COLLABORATION_SET,
		collaboration
	}),
	setMenu: (menu, isIOEvent = false) => dispatch => {
		if (menu) {
			dispatch(appsActions.hideAppListModal());
		}
		dispatch({
			type: types.MENU_SET,
			menu,
			isIOEvent
		});
	},
	setDevice: device => ({
		type: types.DEVICE_SET,
		device
	}),
	setFullscreen: fullscreen => ({
		type: types.FULLSCREEN_SET,
		fullscreen
	}),
	setMaxIcons: maxIcons => ({
		type: types.MAX_ICONS_SET,
		maxIcons
	}),
	resizeWindow: () => (dispatch, getState) => {
		const state = getState();
		const device = responsive.getDeviceType();
		if (state.layout.device !== device) {
			dispatch(actions.setDevice(device));
		}
		const maxIcons = responsive.getMaxAppIcons();
		if (state.layout.maxIcons !== maxIcons) {
			dispatch(actions.setMaxIcons(maxIcons));
		}
	},
	toggleFooter: footerState => (dispatch, getState) => {
		dispatch({
			type: types.TOGGLE_FOOTER,
			state: footerState || !getState().layout.footerOpen
		});
	},
	toggleBranchSwitcher: branchSwitcherState => (dispatch, getState) => {
		dispatch({
			type: types.TOGGLE_BRANCH_SWITCHER,
			state: branchSwitcherState
		});
	}
};

export const classHelpers = {
	busyClass: busy => (busy ? constants.CLASS_BUSY : ''),
	showClass: show => (show ? constants.CLASS_SHOW : constants.CLASS_HIDE),
	menuClass: menu => (menu ? constants.CLASS_MENU_OPEN : constants.CLASS_MENU_CLOSED),
	openClass: open => (open ? constants.CLASS_OPEN : constants.CLASS_CLOSED),
	collaborationClass: collaboration =>
		collaboration ? constants.CLASS_COLLABORATION_OPEN : constants.CLASS_COLLABORATION_CLOSED,
	fullscreenClass: fullscreen => (fullscreen ? constants.CLASS_FULLSCREEN : '')
};

export const filters = {
	device: state => state.layout.device,
	collaboration: state => state.layout.collaboration,
	menu: state => state.layout.menu,
	cover: state => state.layout.cover,
	fullscreen: state => state.layout.fullscreen,
	tabIndex: state => (state.layout.menu ? '0' : '-1')
};

export const selectors = {
	rootClass: createSelector(
		[filters.device, filters.menu, filters.collaboration],
		(device, menu, collaboration) =>
			[
				responsive.getDeviceTypeClass(device),
				classHelpers.menuClass(menu),
				classHelpers.collaborationClass(collaboration)
			].join(' ')
	),
	mainClass: createSelector(
		[filters.menu, filters.collaboration, filters.fullscreen],
		(menu, collaboration, fullscreen) =>
			[
				constants.CLASS_MAIN_FRAME_CONTAINER,
				classHelpers.menuClass(menu),
				classHelpers.collaborationClass(collaboration),
				classHelpers.fullscreenClass(fullscreen)
			].join(' ')
	),
	coverClass: createSelector([filters.cover], cover =>
		[constants.CLASS_MAIN_FRAME_COVER, classHelpers.showClass(cover)].join(' ')
	),

	appsClass: createSelector([filters.menu, menuFilters.busy], (menu, busy) =>
		[constants.CLASS_APP_LIST, constants.CLASS_MENU_SECTION, classHelpers.busyClass(busy)].join(' ')
	),

	collaborationClass: createSelector([filters.menu, filters.collaboration], (menu, collaboration) =>
		[
			constants.CLASS_COLLABORATION_CONTAINER,
			classHelpers.menuClass(menu),
			classHelpers.collaborationClass(collaboration)
		].join(' ')
	),

	menuClass: createSelector(
		[filters.menu, menuFilters.busy, appSelectors.hasBranchSelector],
		(menu, busy, hasBranchSelector) =>
			[
				constants.CLASS_MENU,
				hasBranchSelector ? constants.CLASS_FOOTER_HAS_BRANCH_SELECTOR : '',
				classHelpers.menuClass(menu),
				classHelpers.busyClass(busy)
			].join(' ')
	),
	footerClass: createSelector([menuFilters.busy], busy =>
		[constants.CLASS_FOOTER, classHelpers.busyClass(busy)].join(' ')
	)
};
