module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Kolekce produktů, které chcete nabídnout k prodeji konkrétnímu kupujícímu.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Doklad vydaný prodávajícím kupujícímu s uvedením množství a nákladů poskytnutých produktů nebo služeb.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Předběžný doklad o prodeji zaslaný před odesláním nebo dodáním zboží.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Účet je naplánován k odstranění.",
"4e917d587825921098d19cb5374ffa54":"Přidat aplikaci do oblíbených",
"7328b929c3b7ea8a93ee9e597817f510":"Všechny aplikace",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Ikona šipky",
"421d54e688f76e063b53b1bd67178b42":"Dostupné aplikace",
"614bb33c219a0809bbe8e52f938dcce7":"Paušální objednávka (žádost)",
"51d895d6183265b06e899570e19eecf6":"Logo pobočky",
"9d6641632312bcd817a18331b01bd073":"Procházet a hledat dostupné produkty k nákupu.",
"883d14b9efdb4894d9879c0eff9b5acb":"Zkontrolujte stav platformy nebo kontaktujte podporu.",
"55fc4360777c0241e107f70379e57e75":"Zavřít nabídku",
"da76f49c6f0252b80a6e330d6394c094":"Potvrďte příjem produktů a služeb na základě otevřené objednávky.",
"67f2b8b530a56e35ec1e8254fb72748f":"Žádost o propojení",
"9860a293ae95e8583f868975af1f4849":"Kontaktovat podporu",
"210d71bea3790d832babc970a6674be3":"Převést otevřenou objednávku na fakturu.",
"8df8c2d028d750d31bb796681c2ea3f9":"Vytvořit",
"e3f60bea8ce9079c56a6a69f86f4f048":"Vytvořit dobropis",
"5b5efdc0c8989fe1056b0e54734e824d":"Vytvořit fakturu",
"fadd16ebdc81e4fde9e7fc3154877894":"Vytvořit zálohovou fakturu",
"0e1cf03ccaae390506a0da5f0f739280":"Vytvořit proforma fakturu",
"20c9ff16bb2f0448290a5087a58cdebf":"Vytvořte doklady s cenovou nabídkou a odešlete je svým zákazníkům.",
"830fa5e28d0b88eb70218ea763f71c5c":"Vytvořit paušální objednávku",
"d75c3f6646800bc987c0c07f986b6111":"Vytvořit nový produkt",
"9f7cd528570ea5fa70d5f0fdb8227759":"Vytvořit nabídku",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Vytvořit objednávku",
"9f2650a5abb1e17ffb4714580cc4c457":"Vytvořit produkt",
"581c02d2262d5818fb14faef2a5c5e4e":"Vytvořit cenovou nabídku",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Vytvořit písemný požadavek",
"3f884136e59449da353adefd80121181":"Vytvořit...",
"d23014cf5a5e7f8c6440b96692831aa8":"Dobropis",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definujte položky produktu nebo služby s (odhadovanými) cenami a interními rozpočty za účelem vytvoření objednávek na stanovené období.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definujte produkty k prodeji na tržištích sítě Tradeshift.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definujte svou žádost o produkt nebo službu, které jste nenašli v obchodě, a přidejte je do košíku nebo požadavku na nákup.",
"1b0dd1aca58f84099cff422597422e56":"Načtení platformy Tradeshift se nezdařilo",
"2ca056bf517beb52194e04b1d425b85d":"Načtení platformy Tradeshift se nezdařilo,",
"901bcad22b5486ef2185cb1e59e0a234":"Potvrzení o přijetí zboží",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Seskupte společnosti do síťových skupin za účelem zjednodušené správy",
"d7b639ae724bad4cb10abf6e411bd74e":"Instalované aplikace",
"3c4be400f728da4526de626653d56ccc":"Faktura",
"697ca358c7443b09bab9478598f14726":"Fakturovat z objednávky",
"db13bed8b517884b5e8e257c3e829e7d":"Vystavit úvěr proti budoucím nákupům.",
"8535934d42d580306640d88e0ed217aa":"Načítání společnosti…",
"802ed138bb62eb56ff187f839234478b":"Načítání…",
"1c8e8813d12a483823656cc7c245cd58":"Odhlásit se",
"b25df08fcf0d0acaf6384653ea02141e":"Odhlásit se",
"34bea6b12f16fea4889a2a80c07ee8ec":"Správa skupin",
"7a558a55eb2d9e641f608f759964a9f6":"Ručně vytvořit objednávku pro prodejce s uvedením navrhovaného zboží nebo služeb, množství a cen.",
"fbb9cc68fee07e74094da1030bfdf236":"Síťová skupina",
"ba116f853ca15c3b6f136da33867158b":"Vašemu hledanému výrazu neodpovídají žádné aplikace.",
"14877050aa06ad5917e9d7a7048ad2e3":"Bez popisu",
"b6f4ed6dd31b74d156a22c04e9e02199":"Není oprávnění",
"bacad0fc8777924b7400a5b05b8cc715":"Dne {deletionDate} bude váš účet společnosti a váš uživatelský účet Tradeshift odstraněn. Pokud se jedná o chybu, můžete akci zrušit.",
"2778717f6acbeb1edaac6a2656b478f3":"Otevřít",
"37f066e2502bac5316dfc900890ade4c":"Otevřít nabídku",
"018c4048ea00acea67a5819288f4703f":"Objednávka",
"2ae053867612b2202a870bd52cc3ff63":"Stav platformy",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Pokud se domníváte, že by vaše oprávnění měla být změněna, kontaktujte prosím správce účtu.",
"281f9682838b746f5a0b8a4555614843":"Zálohová faktura",
"387117ac2a32235f30c143484f94147f":"Proforma faktura",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Nákup z obchodu",
"9bb4a36de6bff47eb4209096972979dd":"Cenová nabídka",
"befc83a6b9e66cbfb953b901b91290d0":"Dosáhli jste maximálního počtu oblíbených!",
"beb839cf63984c0634d05bdc900e1905":"Přijmout zboží z objednávky",
"d1b0cf6017a647b49abde5b8ba6fe614":"Naposled použité pobočky",
"793f04b605fc32835a578962a6994422":"Zaznamenejte zálohu.",
"4eddd0ca790e495626f275843c87f440":"Načíst znovu",
"ab8100964aeb93c35539b88420121939":"Odebrat aplikaci z oblíbených",
"d817327a5392f2d398ed8721a314bc37":"Hledat v aplikacích",
"31e12c8c15eae192e29acf9fcb949287":"Vyhledejte společnosti v síti Tradeshift pro vzájemné obchodování.",
"3bcee8f61c10123abe392bf0cc31f99c":"Vyhledat společnost",
"4716fe18b1d4f74edf1a1cdacc83b329":"Obchod",
"50b5a47e8f2e9f988f509e248860acd0":"Zobrazit akce",
"a563aabceadd081e01b44f50d0c9ba26":"Od 31. března 2021 včetně nemůžeme zaručit, že budou aplikace Tradeshift kompatibilní s verzí 11 prohlížeče Internet Explorer. Aktualizujte si prosím svůj prohlížeč na verzi podporovanou naší platformou.",
"3ad73c1b179af6e6df8e5da91028b57c":"Podpora",
"e6e073d7d80064606e77b870ceaf452a":"Přepnout pobočku",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Zkuste stránku znovu načíst.",
"2837b35c04c44f52c772b01723ad1ff0":"Uživatelská nastavení",
"5d3d3522e8b4899177ce29532ae479f0":"Zobrazit všechny pobočky",
"39b9368677bb137b328178942025581b":"Zobrazit otevřené objednávky",
"488b2f58d018e655df26de3ea3f2590c":"Písemná žádost",
"7afb6c3060810bd8e4f55a2e42fd69c0":"K provedení této akce nemáte oprávnění.",
"1bf011c064e4459d5665ff6548bb3c04":"Váš účet je naplánován k odstranění.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Váš účet společnosti, stejně jako váš uživatelský účet Tradeshift, má být dne {deletionDate} odstraněn. Pokud je to chyba, obraťte se na správce účtu.",
};
