import App from 'lib/apps/App';

const documentUploader = process.env.PUBLIC_URL + '/static/images/mock/document_uploader.svg';
const nikeSupplier = process.env.PUBLIC_URL + '/static/images/mock/nike_supplier.svg';
const upsLogistics = process.env.PUBLIC_URL + '/static/images/mock/ups_logistics.svg';
const c2fo = process.env.PUBLIC_URL + '/static/images/mock/c2fo.svg';
const xeroxSpotBuy = process.env.PUBLIC_URL + '/static/images/mock/xerox_spot_buy.svg';
const procureport = process.env.PUBLIC_URL + '/static/images/mock/procureport.svg';
const appStore = process.env.PUBLIC_URL + '/static/images/mock/app_store.svg';
const marketplace = process.env.PUBLIC_URL + '/static/images/mock/marketplace.svg';
const createNewDocument = process.env.PUBLIC_URL + '/static/images/mock/create_new_document.svg';
const paypalPayments = process.env.PUBLIC_URL + '/static/images/mock/paypal_payments.svg';
const quyntess = process.env.PUBLIC_URL + '/static/images/mock/quyntess.svg';
const supplierSurvey = process.env.PUBLIC_URL + '/static/images/mock/supplier_survey.svg';
const support = process.env.PUBLIC_URL + '/static/images/mock/support.svg';
const fedex = process.env.PUBLIC_URL + '/static/images/mock/fedex.svg';
const productManager = process.env.PUBLIC_URL + '/static/images/mock/product_manager.svg';
const avatarPlaceholder = process.env.PUBLIC_URL + '/static/images/mock/avatarPlaceholder.svg';

const $getApp = (vendor, name, iconURL, version, appName = name, hidden = false) =>
	new App({
		id: `${vendor}.${appName.replace(/\s/g, '')}`,
		name,
		vendor,
		version,
		tradeshiftVersion: version === 'LEGACY' ? '2.0' : '5.0',
		manifestVersion: version === 'LEGACY' ? '1.0' : version === 'TS25' ? '2.0' : '3.0',
		description: `Some description of ${vendor}.${name}`,
		iconURL,
		activated: true,
		hidden,
		legacyWrapper: false
	});

const getApps = () => [
	$getApp('Tradeshift', 'Új számla létrehozása', createNewDocument, 'TS25', 'Invoice'),
	$getApp('Tradeshift', 'Document Uploader', documentUploader, 'LEGACY'),
	$getApp('Nike', 'Nike Supplier', nikeSupplier, 'V4'),
	$getApp('C2FO', 'C2FO', c2fo, 'V4'),
	$getApp('UPS', 'Logistics', upsLogistics, 'V4'),
	$getApp('PayPal', 'Payments', paypalPayments, 'V4'),
	$getApp('Tradeshift', 'Marketplace', marketplace, 'V4'),
	$getApp('Tradeshift', 'Product Manager', productManager, 'V4'),
	$getApp('FedEx', 'Shipping Manager', fedex, 'V4'),
	$getApp('Xerox', 'Spot Buy', xeroxSpotBuy, 'V4'),
	$getApp('Procureport', 'Procureport', procureport, 'V4'),
	$getApp('Tradeshift', 'App Store', appStore, 'V4'),
	$getApp('Quyntess', 'Quyntess', quyntess, 'V4'),
	$getApp('Tradeshift', 'SettingsLG', supplierSurvey, 'V4'),
	$getApp('Tradeshift', 'Support', support, 'V4'),
	$getApp('Tradeshift', 'OrderLG', support, 'V4'),
	$getApp('Tradeshift', 'QuoteLG', support, 'V4'),
	$getApp('Tradeshift', 'NetworkManager', support, 'V4'),
	$getApp('Tradeshift', 'TaskList', avatarPlaceholder, 'V4'),
	$getApp('Tradeshift', 'Panel App', support, 'V4', 'PanelApp', true),
	$getApp('Tradeshift', 'Maintenance Popup', support, 'V4', 'MaintenancePopup', true),
	$getApp('Tradeshift', 'Support Launcher', support, 'V4', 'SupportLauncher', true)
];

const apps = getApps();

export const descriptions = getApps().reduce((accumulated, app, index) => {
	if (index % 4) {
		accumulated.push({
			id: app.id,
			description:
				app.id +
				': A long description is a way to provide long alternative text for non-text elements, such as images. Generally, alternative text exceeding 250 characters, which cannot be made more concise without making it less descriptive or meaningful, should have a long description.'.slice(
					0,
					Math.random() * 300
				)
		});
	}
	return accumulated;
}, []);

export const createActions = () => ({
	apps: [
		{
			id: 'Tradeshift.DocumentManager',
			name: 'Document Manager',
			vendor: 'Tradeshift',
			pinned: 0
		},
		{
			id: 'Tradeshift.QuoteLG',
			name: 'Quote',
			vendor: 'Tradeshift'
		},
		{
			id: 'Tradeshift.OrderLG',
			name: 'Purchase Order',
			vendor: 'Tradeshift'
		},
		{
			id: 'Tradeshift.NetworkManager',
			name: 'Network',
			vendor: 'Tradeshift'
		},
		{
			id: 'Tradeshift.PrepaymentInvoiceLG',
			name: 'Prepayment Invoice',
			vendor: 'Tradeshift'
		},
		{
			id: 'Tradeshift.CreditNoteLG',
			name: 'Credit Note',
			vendor: 'Tradeshift'
		},
		{
			id: 'Tradeshift.ProFormaInvoiceLG',
			name: 'Pro Forma Invoice',
			vendor: 'Tradeshift'
		},
		{
			id: 'Tradeshift.InvoiceLG',
			name: 'Invoice',
			vendor: 'Tradeshift'
		}
	].map(a => new App(a)),
	actions: [
		'goods-receipt',
		'written-request',
		'blanket-purchase-order',
		'purchase-from-shop',
		'connection-request',
		'network-group',
		'create-new-product',
		'create-offer'
	]
});

export default apps;
