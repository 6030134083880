import { get } from 'lib/helpers/fetch';

export const zeWrapper = {
	show() {
		const zendeskE = window.zE;
		if (zendeskE) {
			zendeskE('webWidget', 'show');
		}
	},
	hide() {
		const zendeskE = window.zE;
		if (zendeskE) {
			zendeskE('webWidget', 'close');
			zendeskE('webWidget', 'hide');
		}
	},
	prefill({ userName, userEmail }) {
		window.zE('webWidget', 'prefill', {
			name: { value: userName, readOnly: true },
			email: { value: userEmail, readOnly: true }
		});
	},
	isChatting() {
		return window.zE('webWidget:get', 'chat:isChatting');
	},
	addChatEndListener() {
		window.zE('webWidget:on', 'chat:end', function() {
			const event = new Event('zendesk-chat-ended');
			window.dispatchEvent(event);
		});
	},
	setSettings() {
		window.zESettings = {
			webWidget: {
				authenticate: {
					chat: {
						jwtFn: async callback => {
							try {
								const authRes = await get(process.config.urls.zendeskAuthenticate);
								callback(authRes.token);
							} catch (e) {
								callback();
							}
						}
					}
				}
			}
		};
	}
};

export const loadZenDeskChatScript = () =>
	new Promise((resolve, reject) => {
		if (window.zE) {
			resolve();
		}
		zeWrapper.setSettings();

		// https://github.com/inlightmedia/react-zendesk-chat/blob/fca5953d41a06c6a475d8d9c52c3209a96de9b15/src/ReactZenDeskChat/ReactZenDeskChat.js#L48
		const zopim = (window.$zopim = function(c) {
			zopim._.push(c);
		});
		const $ = (zopim.s = document.createElement('script'));

		zopim.set = function(o) {
			zopim.set._.push(o);
		};

		zopim._ = [];
		zopim.set._ = [];
		$.async = true;
		$.setAttribute('charset', 'utf-8');

		$.src = `${process.config.zendeskConfig.scriptURL}?key=${process.config.zendeskConfig.key}`;
		$.id = 'ze-snippet';

		$.type = 'text/javascript';

		document.body.appendChild($);

		$.onload = () => {
			resolve();
		};

		$.onerror = error => {
			reject(error);
		};
	});
