import React from 'react';
import PropTypes from 'prop-types';
import { t as translate } from 'lib/language/language';
import { getErrorCount } from 'lib/localStorage';
import { gaEvent } from 'lib/history/ga';
import { isIE11 } from 'lib/helpers/helpers';
import 'components/loading/loading.less';

// eslint-disable-next-line react/no-deprecated
class Loading extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			fadeOut: false,
			fadeIn: false,
			empty: false,
			error: false
		};

		if (props.fadeOut) {
			setTimeout(() => this.setState({ fadeOut: props.fadeOut }), 0);
		}
		if (props.fadeIn) {
			this.setState({ fadeIn: props.fadeIn });
		}
		if (props.empty) {
			this.setState({ empty: props.empty });
		}
		if (props.anim) {
			this.setState({ anim: props.anim });
		}

		if (props.store) {
			this.unsubscribe = props.store.subscribe(() => {
				if (props.store.getState().root.error) {
					this.setState({ error: true });
					this.unsubscribe();
				}
			});
		}
	}
	componentWillUnmount() {
		if (this.unsubscribe && typeof this.unsubscribe === 'function') {
			this.unsubscribe();
		}
	}

	componentWillReceiveProps(nextProps) {
		const fadeInChanged = this.props.fadeIn !== nextProps.fadeIn;
		if (fadeInChanged) {
			this.setState({
				fadeIn: nextProps.fadeIn
			});
		}

		const fadeOutChanged = this.props.fadeOut !== nextProps.fadeOut;
		if (fadeOutChanged) {
			setTimeout(() => this.setState({ fadeOut: nextProps.fadeOut }), 0);
		}

		const emptyChanged = this.props.empty !== nextProps.empty;
		if (emptyChanged) {
			this.setState({
				empty: nextProps.empty
			});
		}
	}

	handleClick = buttonAction => {
		gaEvent('chrome', buttonAction, 'black-fail-screen');
	};

	render() {
		const errorCount = getErrorCount();
		const t = translate();

		return (
			<div
				className={[
					'main-loading',
					this.state.fadeIn ? 'fade-in' : '',
					this.state.fadeOut ? 'fade-out' : '',
					this.state.anim ? 'anim' : '',
					this.state.error ? 'error' : '',
					isIE11() ? 'is-ie11' : ''
				].join(' ')}
			>
				<div className="loading-container">
					<div className="loader">
						{!this.state.empty && (
							<svg
								id="loading-logo"
								alt="Tradeshift"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 64 64"
							>
								<path d="M14 14h9v9h-9v-9zm36 0v9H36.0909v27h-9V14H50z" />
							</svg>
						)}
						{!this.state.empty && !this.state.error && (
							<svg
								className="loader-circle"
								xmlns="http://www.w3.org/2000/svg"
								viewBox="25 25 50 50"
							>
								<circle
									className="loader-circle-path"
									cx="50"
									cy="50"
									r="20"
									fill="none"
									stroke="rgba(10, 55, 240, 1)"
								/>
								<circle cx="50" cy="50" r="20" fill="none" stroke="rgba(10, 55, 240, .2)" />
							</svg>
						)}
					</div>
					{!this.state.empty && this.state.error && (
						<div>
							<div className="error-information">
								<p>
									User ID: {process.config.request.userId}. Timestamp: {Date.now()}
								</p>
							</div>
							{errorCount === 1 ? (
								<div>
									<h1 id="loading-text">{t('[Title] Failed to load Tradeshift')}</h1>
									<h2 id="loading-subtext">{t('[Subtitle] Try reloading the page.')}</h2>
									<div className="loading-button">
										<button
											id="loading-reload"
											onClick={() => {
												this.handleClick('reload-click');
												setTimeout(() => window.location.reload(), 0);
											}}
										>
											{t('[Button] Reload')}
										</button>
									</div>
								</div>
							) : (
								<div>
									<h1 id="loading-text">{t('[Title] Failed to load Tradeshift, ')}</h1>
									<h2 id="loading-subtext">
										{t('[Subtitle] Check the platform status or contact support.')}
									</h2>

									<a
										id="loading-status"
										className="loading-button"
										href="https://status.tradeshift.com"
										onClick={() => this.handleClick('platform-status-click')}
									>
										{t('[Button] Platform Status')}
									</a>
									<a
										id="loading-support"
										className="loading-button"
										href="https://support.tradeshift.com"
										onClick={() => this.handleClick('contact-support-click')}
									>
										{t('[Button] Contact Support')}
									</a>
									<a
										id="loading-logout"
										className="loading-button"
										href="/logout"
										onClick={() => this.handleClick('logout-click')}
									>
										{t('[Button] Log out')}
									</a>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		);
	}
}

Loading.propTypes = {
	empty: PropTypes.bool,
	anim: PropTypes.bool,
	fadeIn: PropTypes.bool,
	fadeOut: PropTypes.bool
};

export default Loading;
