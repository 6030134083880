import debug from 'debug';
import Lexico from '@tradeshift/lexico';
import { initialize as initializeLanguages, setActiveLanguage } from 'react-localize-redux';
import constants from 'lib/helpers/constants';
import store from 'lib/store';

import cs from '../../translations/js/messages_cs.js';
import da from '../../translations/js/messages_da.js';
import de from '../../translations/js/messages_de.js';
import en from '../../translations/js/messages_en.js';
import en_GB from '../../translations/js/messages_en_GB.js';
import es from '../../translations/js/messages_es.js';
import fi from '../../translations/js/messages_fi.js';
import fr from '../../translations/js/messages_fr.js';
import hu from '../../translations/js/messages_hu.js';
import id from '../../translations/js/messages_id.js';
import it from '../../translations/js/messages_it.js';
import ja from '../../translations/js/messages_ja.js';
import ms from '../../translations/js/messages_ms.js';
import nb from '../../translations/js/messages_nb.js';
import nl from '../../translations/js/messages_nl.js';
import pl from '../../translations/js/messages_pl.js';
import pt from '../../translations/js/messages_pt.js';
import pt_PT from '../../translations/js/messages_pt_PT.js';
import ro from '../../translations/js/messages_ro.js';
import sk from '../../translations/js/messages_sk.js';
import sv from '../../translations/js/messages_sv.js';
import zh from '../../translations/js/messages_zh.js';
import zh_TW from '../../translations/js/messages_zh_TW.js';

const d = debug(`${constants.NS}:language`);

let lexico, lastLocale;

// Lexico v1 does a simple direct matching of
// locales, so we are accepting some redundancy
// in the translations map.
// For example: `pt` and `pt-br`
const getTranslations = () => {
	return {
		cs,
		da,
		de,
		en,
		'en-us': { ...en },
		'en-gb': { ...en_GB },
		es,
		fi,
		fr,
		hu,
		id,
		it,
		ja,
		ms,
		nb,
		'nb-no': { ...nb },
		nl,
		pl,
		pt,
		'pt-br': { ...pt },
		'pt-pt': { ...pt_PT },
		ro,
		sk,
		sv,
		'sv-se': { ...sv },
		zh,
		'zh-cn': { ...zh },
		'zh-tw': { ...zh_TW }
	};
};

const getLexico = () => {
	if (lexico && lastLocale === getLanguage()) {
		return lexico;
	}

	lexico = Lexico.fromContext({ locale: getLanguage() });
	lastLocale = getLanguage();
	return lexico;
};

export const getUserLanguage = () =>
	(window.navigator.userLanguage || window.navigator.language).toLowerCase() ||
	process.config.defaultLanguage;

export const getLanguage = () => {
	const state = store.getState();

	const defaultLanguage = state.locale.options.defaultLanguage || 'en-us';

	if (!state.locale || !state.locale.languages) {
		return defaultLanguage;
	}

	const activeLanguages = state.locale.languages.filter(lang => lang.active);
	if (activeLanguages.length === 0) {
		return defaultLanguage;
	}

	return activeLanguages[0].code;
};

export const setLanguage = (language = process.config.defaultLanguage) => {
	const languages = process.config.languages;

	const input = language + '';
	language = language.replace('_', '-').toLowerCase();

	if (language === 'en') {
		language = 'en-us';
	} else if (language === 'de-ch' && !languages.includes(language)) {
		language = 'de';
	}

	if (languages && !languages.includes(language)) {
		language = process.config.defaultLanguage;
	}

	d('setLanguage %o (input: %o)', language, input);
	store.dispatch(setActiveLanguage(language));
};

export const t = () => {
	const lexico = getLexico();
	return lexico.t.bind(lexico);
};

export const tr = () => {
	const lexico = getLexico();
	return lexico.tr.bind(lexico);
};

export const trc = () => {
	const lexico = getLexico();
	return lexico.trc.bind(lexico);
};

export default function setupLanguage() {
	Lexico.setTranslations(getTranslations());

	const languages = process.config.languages;

	store.dispatch(
		initializeLanguages(languages, {
			defaultLanguage: getUserLanguage()
		})
	);
}
