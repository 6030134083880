import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'lib/language/language';
import constants from 'lib/helpers/constants';
import { useEventListener } from 'lib/helpers/customHooks';
import '@tradeshift/elements/src/vars.css';
import '@tradeshift/elements.dialog';
import io from '@tradeshift/io';
import URLParser from '../../lib/apps/URLParser';
import history from '../../lib/history/createHistory';

export const DeletionRequestPopup = ({ t, deletionRequest, isCompanyAdmin }) => {
	const dialogRef = useRef(null);

	useEventListener(dialogRef, 'cancel', () => {
		if (isCompanyAdmin) {
			const url = new URLParser(history.location, false);
			const app = io().top();
			if (url.app.id === constants.APP_COMPANY_PROFILE.id) {
				app.emit(
					constants.EVENT_COMPANY_PROFILE_SETTINGS_OPEN,
					null,
					constants.APP_COMPANY_PROFILE.id
				);
			} else {
				app.spawn(constants.APP_COMPANY_PROFILE.id, { showProfileSettingsAside: true });
			}
		}
	});

	if (!deletionRequest || (deletionRequest && Object.keys(deletionRequest).length === 0)) {
		return null;
	}

	const deletionDate = new Date(deletionRequest.StartDate).toISOString().split('T')[0];

	const title = isCompanyAdmin
		? t('Your account is scheduled for deletion.')
		: t('Account scheduled for deletion.');

	const text = isCompanyAdmin
		? t(
				'On {deletionDate}, your company account and your Tradeshift user account will be deleted. If this is a mistake, you can cancel it.',
				{ deletionDate }
		  )
		: t(
				'Your company account, as well as your Tradeshift user account, are scheduled to be deleted on {deletionDate}. If this is a mistake, contact an account administrator.',
				{ deletionDate }
		  );

	const translations = isCompanyAdmin
		? JSON.stringify({
				accept_button: 'OK',
				cancel_button: 'CANCEL DELETION REQUEST'
		  })
		: JSON.stringify({
				accept_button: 'OK',
				cancel_button: 'CANCEL'
		  });

	return (
		<ts-dialog
			data-visible
			translations={translations}
			type="warning"
			ref={dialogRef}
			primary="accept"
		>
			<div slot="content">
				<p>{title}</p>
				<p>{text}</p>
			</div>
		</ts-dialog>
	);
};

DeletionRequestPopup.propTypes = {
	t: PropTypes.func.isRequired
};

const mapStateToProps = () => ({
	t: t()
});

export default connect(mapStateToProps)(DeletionRequestPopup);
