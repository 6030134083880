import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { t } from 'lib/language/language';
import DeferredImage from 'components/DeferredImage';
import Avatar from 'components/menu/footer/avatar/Avatar';
import { actions as rootActions } from 'lib/root/root';
import { actions as appsActions } from 'lib/apps/apps';
import { selectors as appSelectors } from 'lib/apps/appsSelectors';
import { selectors as layoutSelectors, actions as layoutActions } from 'lib/layout/layout';
import { appHref } from 'lib/helpers/helpers';
import BranchSwitcher from 'components/menu/branchswitcher/BranchSwitcher';
import 'components/menu/footer/footer.less';
import settingsIcon from 'components/menu/footer/__static__/settingsIcon.svg';
import switchBranchIcon from 'components/menu/footer/__static__/switchBranchIcon.svg';
import logoutIcon from 'components/menu/footer/__static__/logoutIcon.svg';
import arrowIcon from 'components/menu/footer/__static__/arrowIcon.svg';
import supportIcon from 'components/menu/footer/__static__/supportIcon.svg';

const logoutButton = (logout, t) => (
	<button className="logout tst-logout footer-action-item" onClick={logout}>
		<span>{t('[Alt] Log out')}</span>
		<DeferredImage className="logout-icon" src={logoutIcon} alt={t('[Alt] Log out')} />
	</button>
);

const settingsButton = (settingsAttrs, settingsAppClick, closeMenu, t) => (
	<a
		{...settingsAttrs}
		onClick={e => {
			e.stopPropagation();
			closeMenu();
			settingsAppClick();
		}}
	>
		<div className="settings tst-settings footer-action-item" {...settingsAttrs}>
			<span>{t('[Alt] User settings')}</span>
			<DeferredImage className="settings-icon" src={settingsIcon} alt={t('[Alt] User settings')} />
		</div>
	</a>
);

const supportButton = (supportAttrs, supportAppClick, closeMenu, t) => (
	<a
		{...supportAttrs}
		onClick={e => {
			e.stopPropagation();
			supportAppClick();
			closeMenu();
		}}
	>
		<div className="footer-action-item" {...supportAttrs}>
			<span>{t('[Alt] Support')}</span>
			<DeferredImage className="support-icon" src={supportIcon} alt={t('[Alt] Support')} />
		</div>
	</a>
);

const branchSwitchButton = (toggleBranchSwitcher, t) => (
	<button
		className="branch-switch-button footer-action-item"
		onClick={e => {
			e.stopPropagation();
			toggleBranchSwitcher(true);
		}}
	>
		<span>{t('[Alt] Switch branch')}</span>
		<DeferredImage
			className="branch-switch-icon"
			src={switchBranchIcon}
			alt={t('[Alt] Switch branch')}
		/>
	</button>
);

const footerToggler = (toggleBranchSwitcher, toggleFooter, busy, user, t) => (
	<button
		className="footer-toggle-wrapper"
		onClick={e => {
			toggleFooter();
			toggleBranchSwitcher(false);
		}}
		tabIndex="0"
		title={t('[Alt] Show actions')}
	>
		<div className="userimage tst-userimage">
			<Avatar
				busy={busy}
				userName={user.userName}
				companyName={user.companyName}
				avatarURL={user.avatarURL}
			/>
		</div>
		<div className="user">
			<span className="username tst-user">
				{busy ? t('[Loading] Loading…') : user.userName || ''}
			</span>
			<span className="company tst-company">
				{busy ? t('[Loading] Loading company…') : user.companyName || ''}
			</span>
		</div>
		<div className="footer-toggle-icon">
			<DeferredImage src={arrowIcon} alt={t('[Alt] Arrow icon')} />
		</div>
	</button>
);

export const Footer = ({
	t,
	user,
	busy,
	footerAttrs,
	settingsAttrs,
	hasBranchSelector,
	logout,
	settingsAppClick,
	toggleFooter,
	toggleBranchSwitcher,
	branchSwitcherOpen,
	supportAttrs,
	supportAppClick,
	closeMenu
}) => (
	<div {...footerAttrs}>
		{footerToggler(toggleBranchSwitcher, toggleFooter, busy, user, t)}
		<div
			className={['footer-actions-list', branchSwitcherOpen ? 'show-branch-switcher' : ''].join(
				' '
			)}
		>
			{hasBranchSelector && branchSwitchButton(toggleBranchSwitcher, t)}
			{settingsButton(settingsAttrs, settingsAppClick, closeMenu, t)}
			{supportButton(supportAttrs, supportAppClick, closeMenu, t)}
			{logoutButton(logout, t)}
		</div>
		{hasBranchSelector && (
			<div
				className={[
					'branch-switcher-wrapper',
					branchSwitcherOpen ? 'show-branch-switcher' : ''
				].join(' ')}
			>
				<BranchSwitcher />
			</div>
		)}
	</div>
);

Footer.propTypes = {
	t: PropTypes.func.isRequired,
	user: PropTypes.shape({
		companyName: PropTypes.string.isRequired,
		userName: PropTypes.string.isRequired,
		avatarURL: PropTypes.string
	}).isRequired,
	busy: PropTypes.bool.isRequired,
	hasBranchSelector: PropTypes.bool.isRequired,
	branchSwitcherOpen: PropTypes.bool.isRequired,
	settingsAttrs: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
	user: state.menu.user,
	busy: state.menu.busy,
	footerAttrs: {
		className: layoutSelectors.footerClass(state)
	},
	branchSwitcherOpen: state.layout.branchSwitcherOpen,
	settingsAttrs: {
		...appHref((appSelectors.getApp(state, process.config.featureApps.settings) || {}).href)
	},
	supportAttrs: {
		...appHref((appSelectors.getApp(state, process.config.featureApps.supportLauncher) || {}).href)
	},
	hasBranchSelector: appSelectors.hasBranchSelector(state),
	t: t()
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			logout: () => rootActions.logout(),
			settingsAppClick: () => appsActions.settingsAppClick(),
			supportAppClick: () => appsActions.supportAppClick(),
			toggleFooter: () => layoutActions.toggleFooter(),
			toggleBranchSwitcher: open => layoutActions.toggleBranchSwitcher(open),
			closeMenu: () => layoutActions.setMenu(false)
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
