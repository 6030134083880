import history from 'lib/history/createHistory';
import { isDevelopment } from 'lib/helpers/helpers';

export const getTitle = app => {
	if (app && app.name) {
		let debugStr = '';
		if (isDevelopment(false)) {
			debugStr = `[${history.location.pathname}${history.location.search}${history.location.hash}]`;
		}
		return `${app.name} ${process.config.title.separator} ${process.config.title.ts} ${debugStr}`;
	} else {
		return process.config.title.ts;
	}
};
