module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Azon termékek gyűjteménye, amelyeket szeretne eladásra felkínálni meghatározott vevőnek.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Az eladó által a vevőnek kibocsátott dokumentum, ami feltünteti a termékek vagy nyújtott szolgáltatások mennyiségét és költségét.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Előzetes adásvételi számla, amelyet az áruk szállítása előtt küldenek el.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Fiók törlése előjegyezve.",
"4e917d587825921098d19cb5374ffa54":"Alkalmazás hozzáadása a kedvencekhez",
"7328b929c3b7ea8a93ee9e597817f510":"Összes alkalmazás",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Nyíl ikon",
"421d54e688f76e063b53b1bd67178b42":"Rendelkezésre álló alkalmazások",
"614bb33c219a0809bbe8e52f938dcce7":"Általános beszerzési rendelés (kérés)",
"51d895d6183265b06e899570e19eecf6":"Fiók logója",
"9d6641632312bcd817a18331b01bd073":"Böngésszen és keresse a megvásárolható termékeket.",
"883d14b9efdb4894d9879c0eff9b5acb":"Ellenőrizze a platform állapotát, vagy értesítse az ügyfélszolgálatot.",
"55fc4360777c0241e107f70379e57e75":"Menü bezárása",
"da76f49c6f0252b80a6e330d6394c094":"Nyugtázza a nyitott beszerzési rendelésen alapuló termékek és szolgáltatások beérkezését",
"67f2b8b530a56e35ec1e8254fb72748f":"Függő kapcsolat",
"9860a293ae95e8583f868975af1f4849":"Lépjen kapcsolatba a terméktámogatás részleggel",
"210d71bea3790d832babc970a6674be3":"Nyitott megrendelés átalakítása számlává.",
"8df8c2d028d750d31bb796681c2ea3f9":"Létrehozás",
"e3f60bea8ce9079c56a6a69f86f4f048":"Jóváírási értesítő létrehozása",
"5b5efdc0c8989fe1056b0e54734e824d":"Számla létrehozása",
"fadd16ebdc81e4fde9e7fc3154877894":"Előlegszámla létrehozása",
"0e1cf03ccaae390506a0da5f0f739280":"Proforma számla létrehozása",
"20c9ff16bb2f0448290a5087a58cdebf":"Hozza létre és küldje el árajánlati dokumentumait az ügyfelei számára.",
"830fa5e28d0b88eb70218ea763f71c5c":"Általános beszerzési rendelés létrehozása",
"d75c3f6646800bc987c0c07f986b6111":"Új termék létrehozása",
"9f7cd528570ea5fa70d5f0fdb8227759":"Ajánlat létrehozása",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Megrendelés létrehozása",
"9f2650a5abb1e17ffb4714580cc4c457":"Termék létrehozása",
"581c02d2262d5818fb14faef2a5c5e4e":"Árajánlat létrehozása",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Írásbeli kérelem létrehozása",
"3f884136e59449da353adefd80121181":"Létrehozás...",
"d23014cf5a5e7f8c6440b96692831aa8":"Jovairas",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Termék- vagy szolgáltatáselemek meghatározása (becsült) árakkal és belső költségvetéssel, hogy meghatározott időszakra beszerzési rendeléseket hozhasson létre.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Termékek meghatározás a Tradeshift Marketplace alkalmazásokban történő értékesítéshez.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Határozza meg olyan termék vagy szolgáltatás iránti kérelmét, amelyet nem talált meg webáruházban, majd adja hozzá a vásárlási kérelméhez.",
"1b0dd1aca58f84099cff422597422e56":"Nem sikerült a Tradeshift betöltése",
"2ca056bf517beb52194e04b1d425b85d":"Nem sikerült a Tradeshift betöltése,",
"901bcad22b5486ef2185cb1e59e0a234":"Árubeérkezés",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Csoportosítsa a vállalatokat hálózati csoportokba az egyszerűbb adminisztráció érdekében",
"d7b639ae724bad4cb10abf6e411bd74e":"Telepített alkalmazások",
"3c4be400f728da4526de626653d56ccc":"Számla",
"697ca358c7443b09bab9478598f14726":"Számla készítése megrendelésből",
"db13bed8b517884b5e8e257c3e829e7d":"Hitel kiadása jövőbeli vásárlások ellenében.",
"8535934d42d580306640d88e0ed217aa":"Vállalat beöltése…",
"802ed138bb62eb56ff187f839234478b":"Betöltés…",
"1c8e8813d12a483823656cc7c245cd58":"Kijelentkezés",
"b25df08fcf0d0acaf6384653ea02141e":"Kijelentkezés",
"34bea6b12f16fea4889a2a80c07ee8ec":"Csoportok kezelése",
"7a558a55eb2d9e641f608f759964a9f6":"Manuálisan készítsen beszerzési rendelést az eladó számára, feltüntetve a javasolt árukat vagy szolgáltatásokat, mennyiségeket és árakat.",
"fbb9cc68fee07e74094da1030bfdf236":"Hálózati csoport",
"ba116f853ca15c3b6f136da33867158b":"A keresési feltételnek egyetlen alkalmazás sem felel meg.",
"14877050aa06ad5917e9d7a7048ad2e3":"Nincs leírás.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Nincs engedélye",
"bacad0fc8777924b7400a5b05b8cc715":"{deletionDate} napján az Ön céges fiókja és a Tradeshift felhasználói fiókja törlésre kerül. Ha ez tévedésből történt, akkor visszavonhatja a műveletet.",
"2778717f6acbeb1edaac6a2656b478f3":"Megnyitás",
"37f066e2502bac5316dfc900890ade4c":"Menü megnyitása",
"018c4048ea00acea67a5819288f4703f":"Megrendelés",
"2ae053867612b2202a870bd52cc3ff63":"Platform állapota",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Kérjük, lépjen kapcsolatba a számlafelelőssel, ha szeretné a jogosultságát módosítani.",
"281f9682838b746f5a0b8a4555614843":"Előlegszámla",
"387117ac2a32235f30c143484f94147f":"Proforma számla",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Vásárlás webáruházból",
"9bb4a36de6bff47eb4209096972979dd":"Árajánlat",
"befc83a6b9e66cbfb953b901b91290d0":"Elérte a kedvencek maximális számát!",
"beb839cf63984c0634d05bdc900e1905":"Áruk beérkezése megrendelésből",
"d1b0cf6017a647b49abde5b8ba6fe614":"Legutóbb használt fiókok",
"793f04b605fc32835a578962a6994422":"Előlegfizetés rögzítése.",
"4eddd0ca790e495626f275843c87f440":"Frissítés",
"ab8100964aeb93c35539b88420121939":"Alkalmazás törlése a kedvencekből",
"d817327a5392f2d398ed8721a314bc37":"Alkalmazások keresése",
"31e12c8c15eae192e29acf9fcb949287":"Vállalatok keresése a Tradeshift hálózatban üzletkötés céljából.",
"3bcee8f61c10123abe392bf0cc31f99c":"Vállalat keresése",
"4716fe18b1d4f74edf1a1cdacc83b329":"Áruház",
"50b5a47e8f2e9f988f509e248860acd0":"Mûveletek megjelenítése",
"a563aabceadd081e01b44f50d0c9ba26":"2021. március 31-től a Tradeshift alkalmazások nem garantáltan kompatibilisek az Internet Explorer 11. verziójával. Kérjük, frissítse böngészőjét a platformunk által támogatott verzióra.",
"3ad73c1b179af6e6df8e5da91028b57c":"Segítség",
"e6e073d7d80064606e77b870ceaf452a":"Fiók átváltása",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Próbálja meg frissíteni az oldalt.",
"2837b35c04c44f52c772b01723ad1ff0":"Felhasználói beállítások",
"5d3d3522e8b4899177ce29532ae479f0":"Az összes fiók megtekintése",
"39b9368677bb137b328178942025581b":"Nyitott megrendelések megtekintése",
"488b2f58d018e655df26de3ea3f2590c":"Írásbeli kérelem",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Nincs engedélye a műveletet végrehajtásához",
"1bf011c064e4459d5665ff6548bb3c04":"Fiók törlése előjegyezve.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Az Ön céges fiókját, valamint a Tradeshift felhasználói fiókját a tervek szerint {deletionDate} dátummal törlik. Ha ez tévedés, akkor lépjen kapcsolatba egy fiókadminisztrátorral.",
};
