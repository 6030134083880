module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"O colecție de produse pe care doriți să le oferiți spre vânzare unui anumit cumpărător.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Un document emis de un vânzător către un cumpărător în care se indică cantitățile și costurile produselor sau serviciilor furnizate.",
"5b24efebe4bd25a8c5e3aca06197e35e":"O factură preliminară de vânzare trimisă înainte de expedierea sau livrarea mărfurilor.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Cont programat pentru ștergere.",
"4e917d587825921098d19cb5374ffa54":"Adăugați aplicația la favorite",
"7328b929c3b7ea8a93ee9e597817f510":"Toate aplicațiile",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Pictograma săgeată",
"421d54e688f76e063b53b1bd67178b42":"Aplicații disponibile",
"614bb33c219a0809bbe8e52f938dcce7":"Comandă de cumpărare deschisă (solicitare)",
"51d895d6183265b06e899570e19eecf6":"Logo sucursală",
"9d6641632312bcd817a18331b01bd073":"Navigați și căutați produsele disponibile pentru cumpărare.",
"883d14b9efdb4894d9879c0eff9b5acb":"Verificați starea platformei sau contactați asistența.",
"55fc4360777c0241e107f70379e57e75":"Închideți meniul",
"da76f49c6f0252b80a6e330d6394c094":"Confirmați primirea de produse și servicii pe baza unei comenzi de cumpărare deschise.",
"67f2b8b530a56e35ec1e8254fb72748f":"Solicitare conexiune",
"9860a293ae95e8583f868975af1f4849":"Contactați asistența",
"210d71bea3790d832babc970a6674be3":"Transformare comandă deschisă într-o factură.",
"8df8c2d028d750d31bb796681c2ea3f9":"Creați",
"e3f60bea8ce9079c56a6a69f86f4f048":"Creare notă de credit",
"5b5efdc0c8989fe1056b0e54734e824d":"Creare factură",
"fadd16ebdc81e4fde9e7fc3154877894":"Creați factura de plată în avans",
"0e1cf03ccaae390506a0da5f0f739280":"Creare factură proformă",
"20c9ff16bb2f0448290a5087a58cdebf":"Creare și trimitere documente de ofertă clienților dvs.",
"830fa5e28d0b88eb70218ea763f71c5c":"Creare comandă de cumpărare deschisă",
"d75c3f6646800bc987c0c07f986b6111":"Creare produs nou",
"9f7cd528570ea5fa70d5f0fdb8227759":"Creați oferta",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Creați comanda",
"9f2650a5abb1e17ffb4714580cc4c457":"Creați produsul",
"581c02d2262d5818fb14faef2a5c5e4e":"Creați oferta",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Creați solicitarea scrisă",
"3f884136e59449da353adefd80121181":"Creați...",
"d23014cf5a5e7f8c6440b96692831aa8":"Notă de credit",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definire produse sau articolele de serviciu cu prețuri (estimative) și bugete interne pentru a crea comenzi de cumpărare pentru o perioadă de timp definită.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definire produse pentru vânzare în bazarele Tradeshift.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definiți solicitarea dvs. pentru un produs sau serviciu pe care nu l-ați putut găsi în Magazin și adăugați-l în Coșul dvs. sau într-o Solicitare de cumpărare.",
"1b0dd1aca58f84099cff422597422e56":"Încărcarea site-ului Tradeshift nu a reușit",
"2ca056bf517beb52194e04b1d425b85d":"Încărcarea site-ului Tradeshift nu a reușit,",
"901bcad22b5486ef2185cb1e59e0a234":"Notă de recepție a mărfurilor",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Gruparea firmelor în grupuri de rețea, în vederea unei administrări simplificate",
"d7b639ae724bad4cb10abf6e411bd74e":"Aplicații instalate",
"3c4be400f728da4526de626653d56ccc":"Factură",
"697ca358c7443b09bab9478598f14726":"Factură de la comandă",
"db13bed8b517884b5e8e257c3e829e7d":"Emitere de credit conform cumpărăturilor viitoare.",
"8535934d42d580306640d88e0ed217aa":"Se încarcă compania…",
"802ed138bb62eb56ff187f839234478b":"Se încarcă…",
"1c8e8813d12a483823656cc7c245cd58":"Deconectați-vă",
"b25df08fcf0d0acaf6384653ea02141e":"Deconectați-vă",
"34bea6b12f16fea4889a2a80c07ee8ec":"Gestionare grupuri",
"7a558a55eb2d9e641f608f759964a9f6":"Creați manual o comandă de cumpărare pentru un vânzător, indicând bunurile sau serviciile propuse, cantitățile și prețurile.",
"fbb9cc68fee07e74094da1030bfdf236":"Grup de rețea",
"ba116f853ca15c3b6f136da33867158b":"Nicio aplicație nu corespunde termenului căutat.",
"14877050aa06ad5917e9d7a7048ad2e3":"Fără descriere.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Nu aveți acces",
"bacad0fc8777924b7400a5b05b8cc715":"În data de {deletionDate}, contul firmei dvs. și contul dvs. de utilizator Tradeshift vor fi șterse. Dacă a fost o greșeală, puteți anula ștergerea.",
"2778717f6acbeb1edaac6a2656b478f3":"Deschideți",
"37f066e2502bac5316dfc900890ade4c":"Deschideți meniul",
"018c4048ea00acea67a5819288f4703f":"Comandă",
"2ae053867612b2202a870bd52cc3ff63":"Stare platformă",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Contactați-vă administratorul contului în cazul în care credeți că permisiunile dvs. trebuie modificate.",
"281f9682838b746f5a0b8a4555614843":"Factură plată în avans",
"387117ac2a32235f30c143484f94147f":"Factură proformă",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Cumpără din Magazin",
"9bb4a36de6bff47eb4209096972979dd":"Ofertă",
"befc83a6b9e66cbfb953b901b91290d0":"Numărul maxim de favorite a fost atins!",
"beb839cf63984c0634d05bdc900e1905":"Primește bunuri din comandă",
"d1b0cf6017a647b49abde5b8ba6fe614":"Sucursale utilizate recent",
"793f04b605fc32835a578962a6994422":"Înregistrare o plată în avans.",
"4eddd0ca790e495626f275843c87f440":"Reîncărcați",
"ab8100964aeb93c35539b88420121939":"Ștergeți aplicația de la favorite",
"d817327a5392f2d398ed8721a314bc37":"Căutare aplicații",
"31e12c8c15eae192e29acf9fcb949287":"Căutați firme din rețeaua Tradeshift cu care să faceți afaceri.",
"3bcee8f61c10123abe392bf0cc31f99c":"Căutare firmă",
"4716fe18b1d4f74edf1a1cdacc83b329":"Magazin",
"50b5a47e8f2e9f988f509e248860acd0":"Afișați acțiunile",
"a563aabceadd081e01b44f50d0c9ba26":"Începând cu 31 martie 2021, aplicațiile Tradeshift nu au garanția că sunt compatibile cu versiunea 11 a programului Internet Explorer. Vă rugăm să actualizezi browser-ul la o versiune suportată de platforma noastră.",
"3ad73c1b179af6e6df8e5da91028b57c":"Asistență",
"e6e073d7d80064606e77b870ceaf452a":"Schimbați acțiunile",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Încercați să reîncărcați pagina.",
"2837b35c04c44f52c772b01723ad1ff0":"Setări utilizator",
"5d3d3522e8b4899177ce29532ae479f0":"Vizualizați toate sucursalele",
"39b9368677bb137b328178942025581b":"Vezi comenzile deschise",
"488b2f58d018e655df26de3ea3f2590c":"Solicitare scrisă",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Nu aveți permisiunile pentru a efectua această acțiune.",
"1bf011c064e4459d5665ff6548bb3c04":"Contul dvs. este programat pentru ștergere.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Contul companiei dvs., precum și contul dvs. de utilizator Tradeshift, sunt programate să fie șterse în data de {deletionDate}. Dacă este o greșeală, contactați un administrator de cont.",
};
