// From https://github.com/segmentio/utm-params/
function utm(query) {
	// From https://github.com/component/querystring/blob/master/index.js
	var decode = function(str) {
		try {
			return decodeURIComponent(str.replace(/\+/g, ' '));
		} catch (e) {
			return str;
		}
	};
	var pattern = /(\w+)\[(\d+)\]/;
	var parse = function(str) {
		if (typeof str !== 'string') {
			return {};
		}
		str = str.trim();
		if (str === '') {
			return {};
		}
		if (str.charAt(0) === '?') {
			str = str.slice(1);
		}

		var obj = {};
		var pairs = str.split('&');
		for (var i = 0; i < pairs.length; i++) {
			var parts = pairs[i].split('=');
			var key = decode(parts[0]);
			var m = pattern.exec(key);
			if (m) {
				obj[m[1]] = obj[m[1]] || [];
				obj[m[1]][m[2]] = decode(parts[1]);
				continue;
			}
			obj[parts[0]] = parts[1] === null ? '' : decode(parts[1]);
		}

		return obj;
	};

	if (query.charAt(0) === '?') {
		query = query.substring(1);
	}

	query = query.replace(/\?/g, '&');

	var param;
	var params = parse(query);
	var results = {};

	for (var key in params) {
		if (Object.prototype.hasOwnProperty.call(params, key)) {
			if (key.substr(0, 4) === 'utm_') {
				param = key.substr(4);
				if (param === 'campaign') {
					param = 'name';
				}
				results[param] = params[key];
			}
		}
	}

	return results;
}

module.exports = utm;
