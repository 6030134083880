import { types as rootTypes } from 'lib/root/root';

const initialState = {
	hasTopBar: false
};

export const types = {
	SET_TOP_BAR: 'ts.chrome/topBar/SET_TOP_BAR'
};

export const actions = {
	setTopBar: data => ({ type: types.SET_TOP_BAR, data })
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case rootTypes.DATA_FETCHED:
		case types.SET_TOP_BAR:
			const topBarData = action.data.topBar;
			if (topBarData && Object.keys(topBarData).length > 0) {
				return {
					hasTopBar: true,
					...topBarData
				};
			}
		// fall through

		default:
			return {
				hasTopBar: false,
				...state
			};
	}
};

export default reducer;
