import blanketPurchaseOrder from 'components/CreateActionsModal/__static__/icons/blanket-purchase-order.svg';
import genericIcon from 'components/CreateActionsModal/__static__/icons/generic.svg';
import invoiceIcon from 'components/CreateActionsModal/__static__/icons/invoice.svg';
import proformaInvoiceIcon from 'components/CreateActionsModal/__static__/icons/proforma-invoice.svg';
import prePaymentInvoiceIcon from 'components/CreateActionsModal/__static__/icons/pre-payment-invoice.svg';
import purchaseOrderIcon from 'components/CreateActionsModal/__static__/icons/purchase-order.svg';
import creditNoteIcon from 'components/CreateActionsModal/__static__/icons/credit-note.svg';
import quoteIcon from 'components/CreateActionsModal/__static__/icons/quote.svg';
import writtenRequest from 'components/CreateActionsModal/__static__/icons/written-request.svg';

export const createActionsList = t => [
	{
		actionId: 'invoice',
		name: t('Invoice'),
		actionName: t('Create Invoice'),
		appId: 'Tradeshift.InvoiceLG',
		icon: invoiceIcon,
		description: t(
			'A document issued by a seller to a buyer that indicates the quantities and costs of products or services provided.'
		)
	},
	{
		actionId: 'pro-forma-invoice',
		name: t('Proforma Invoice'),
		actionName: t('Create Proforma Invoice'),
		appId: 'Tradeshift.ProFormaInvoiceLG',
		icon: proformaInvoiceIcon,
		description: t('A preliminary bill of sale sent in advance of shipment or delivery of goods.')
	},
	{
		actionId: 'pre-payment-invoice',
		name: t('Prepayment Invoice'),
		actionName: t('Create Prepayment Invoice'),
		appId: 'Tradeshift.PrepaymentInvoiceLG',
		icon: prePaymentInvoiceIcon,
		description: t('Record an advance payment.')
	},
	{
		actionId: 'invoice-from-order',
		name: t('Invoice from order'),
		actionName: t('View open orders'),
		appId: 'Tradeshift.DocumentManager',
		icon: invoiceIcon,
		description: t('Convert an open order into an invoice.'),
		hasBackendAction: true
	},
	{
		actionId: 'order',
		name: t('Order'),
		actionName: t('Create order'),
		appId: 'Tradeshift.OrderLG',
		icon: purchaseOrderIcon,
		description: t(
			'Manually create a purchase order for a seller, indicating proposed goods or services, quantities and prices.'
		)
	},
	{
		actionId: 'credit-note',
		name: t('Credit Note'),
		actionName: t('Create Credit Note'),
		appId: 'Tradeshift.CreditNoteLG',
		icon: creditNoteIcon,
		description: t('Issue credit against future purchases.')
	},
	{
		actionId: 'goods-receipt',
		name: t('Goods receipt'),
		actionName: t('Receive goods from order'),
		appId: 'Tradeshift.DocumentManager',
		icon: genericIcon,
		description: t('Confirm the receipt of products and services based on an open purchase order.'),
		hasBackendAction: true,
		onlyAppActivatedCheck: true
	},
	{
		actionId: 'quote',
		name: t('Quote'),
		actionName: t('Create quote'),
		appId: 'Tradeshift.QuoteLG',
		icon: quoteIcon,
		description: t('Create and send Quote documents to your customers.')
	},
	{
		actionId: 'written-request',
		name: t('Written request'),
		actionName: t('Create written request'),
		appId: 'Tradeshift.WrittenRequest',
		icon: writtenRequest,
		description: t(
			'Define your request for a product or service that you could not find in Shop and add it to your Basket or a Purchase Request.'
		)
	},
	{
		actionId: 'blanket-purchase-order',
		name: t('Blanket purchase order (request)'),
		actionName: t('Create blanket purchase order'),
		appId: 'Tradeshift.BlanketPurchaseOrderV2',
		icon: blanketPurchaseOrder,
		description: t(
			'Define product or service items with (estimated) prices and internal budgets to create purchase orders for a defined period of time.'
		)
	},
	{
		actionId: 'purchase-from-shop',
		name: t('Purchase from Shop'),
		actionName: t('Shop'),
		appId: 'Tradeshift.Shop',
		icon: genericIcon,
		description: t('Browse and search for available products for purchase.')
	},
	{
		actionId: 'connection-request',
		name: t('Connection request'),
		actionName: t('Search for company'),
		appId: 'Tradeshift.NetworkManager',
		icon: genericIcon,
		description: t('Search for companies on the Tradeshift Network to do business with.'),
		addHref: '/network',
		onlyAppActivatedCheck: true
	},
	{
		actionId: 'network-group',
		name: t('Network group'),
		actionName: t('Manage groups'),
		appId: 'Tradeshift.NetworkManager',
		icon: genericIcon,
		description: t('Group companies into network groups for simplified administration'),
		addHref: '/groups',
		onlyAppActivatedCheck: true
	},
	{
		actionId: 'create-new-product',
		name: t('Create new product'),
		actionName: t('Create product'),
		appId: 'Tradeshift.SupplierMarketplaceManager',
		icon: genericIcon,
		description: t('Define products for sale on Tradeshift Marketplaces.'),
		addHref: '/products/new'
	},
	{
		actionId: 'create-offer',
		name: t('Create offer'),
		actionName: t('Create order'),
		appId: 'Tradeshift.SupplierMarketplaceManager',
		icon: genericIcon,
		description: t("A collection of products you'd like to offer for sale to a specific buyer."),
		addHref: '/offers/new'
	}
];
