module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Eine Auswahl von Produkten, die Sie einem bestimmten Käufer zum Kauf anbieten möchten.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Ein Dokument, das von einem Verkäufer an einen Käufer ausgestellt wird und in dem die Mengen und Kosten von Produkten oder erbrachten Dienstleistungen angeben sind.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Eine vorläufige Verkaufsrechnung, die vor dem Versand oder der Lieferung der Ware übermittelt wird.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Konto zur Löschung vorgemerkt.",
"4e917d587825921098d19cb5374ffa54":"App zu den Favoriten hinzufügen",
"7328b929c3b7ea8a93ee9e597817f510":"Alle Apps",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Pfeilsymbol",
"421d54e688f76e063b53b1bd67178b42":"Verfügbare Apps",
"614bb33c219a0809bbe8e52f938dcce7":"Blankobestellung (Anforderung)",
"51d895d6183265b06e899570e19eecf6":"Filiallogo",
"9d6641632312bcd817a18331b01bd073":"Suchen Sie nach verfügbaren Produkten.",
"883d14b9efdb4894d9879c0eff9b5acb":"Prüfen Sie den Plattformstatus oder kontaktieren Sie den Kundenservice.",
"55fc4360777c0241e107f70379e57e75":"Menü schließen",
"da76f49c6f0252b80a6e330d6394c094":"Bestätigen Sie den Erhalt von Produkten und Services, die Teil einer offenen Bestellung sind.",
"67f2b8b530a56e35ec1e8254fb72748f":"Verbindungsanfrage",
"9860a293ae95e8583f868975af1f4849":"Support Team kontaktieren",
"210d71bea3790d832babc970a6674be3":"Wandeln Sie eine offene Bestellung in eine Rechnung um.",
"8df8c2d028d750d31bb796681c2ea3f9":"Erstellen",
"e3f60bea8ce9079c56a6a69f86f4f048":"Rechnungskorrektur erstellen",
"5b5efdc0c8989fe1056b0e54734e824d":"Rechnung erstellen",
"fadd16ebdc81e4fde9e7fc3154877894":"Vorauszahlungsrechnung erstellen",
"0e1cf03ccaae390506a0da5f0f739280":"Proformarechnung erstellen",
"20c9ff16bb2f0448290a5087a58cdebf":"Erstellen und senden Sie Angebotsunterlagen an Ihre Kunden.",
"830fa5e28d0b88eb70218ea763f71c5c":"Blankobestellung erstellen",
"d75c3f6646800bc987c0c07f986b6111":"Neues Produkt erstellen",
"9f7cd528570ea5fa70d5f0fdb8227759":"Angebot erstellen",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Bestellung erstellen",
"9f2650a5abb1e17ffb4714580cc4c457":"Produkt erstellen",
"581c02d2262d5818fb14faef2a5c5e4e":"Angebot erstellen",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Schriftliche Anfrage erstellen",
"3f884136e59449da353adefd80121181":"Wird erstellt...",
"d23014cf5a5e7f8c6440b96692831aa8":"Rechnungskorrektur",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definieren Sie Produkt- oder Dienstleistungsartikel mit (geschätzten) Preisen und internen Budgets, um Bestellungen für einen festgelegten Zeitraum zu erstellen.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definieren Sie Produkte für den Verkauf auf Tradeshift-Marktplätzen.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definieren Sie Ihre Anfrage für Produkte oder Dienstleistungen, die Sie im Shop nicht finden konnten, und fügen Sie sie Ihrem Korb oder einer Bestellanforderung hinzu.",
"1b0dd1aca58f84099cff422597422e56":"Tradeshift fehlgeschlagen",
"2ca056bf517beb52194e04b1d425b85d":"Tradeshift fehlgeschlagen,",
"901bcad22b5486ef2185cb1e59e0a234":"Wareneingang",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Fassen Sie Unternehmen in Netzwerkgruppen zusammen, um die Verwaltung zu vereinfachen",
"d7b639ae724bad4cb10abf6e411bd74e":"Installierte Anwendungen",
"3c4be400f728da4526de626653d56ccc":"Rechnung",
"697ca358c7443b09bab9478598f14726":"Rechnung von Bestellung",
"db13bed8b517884b5e8e257c3e829e7d":"Guthaben für zukünftige Einkäufe ausstellen.",
"8535934d42d580306640d88e0ed217aa":"Das Unternehmen wird geladen…",
"802ed138bb62eb56ff187f839234478b":"Wird geladen ...",
"1c8e8813d12a483823656cc7c245cd58":"Logout",
"b25df08fcf0d0acaf6384653ea02141e":"Logout",
"34bea6b12f16fea4889a2a80c07ee8ec":"Gruppen verwalten",
"7a558a55eb2d9e641f608f759964a9f6":"Erstellen Sie manuell eine Bestellung für einen Verkäufer, die die vorgeschlagenen Waren oder Dienstleistungen, Mengen und Preise enthält.",
"fbb9cc68fee07e74094da1030bfdf236":"Netzwerkgruppe",
"ba116f853ca15c3b6f136da33867158b":"Es stimmen keine Apps mit Ihrem Suchbegriff überein.",
"14877050aa06ad5917e9d7a7048ad2e3":"Keine Beschreibung.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Keine Genehmigung",
"bacad0fc8777924b7400a5b05b8cc715":"Am {deletionDate} werden Ihr Firmenkonto und Ihr Tradeshift-Benutzerkonto gelöscht. Falls dies ein Fehler ist, können Sie die Löschung aufheben.",
"2778717f6acbeb1edaac6a2656b478f3":"Öffnen",
"37f066e2502bac5316dfc900890ade4c":"Menü öffnen",
"018c4048ea00acea67a5819288f4703f":"Bestellung",
"2ae053867612b2202a870bd52cc3ff63":"Plattform Status",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Bitte wenden Sie sich an Ihren Kontoadministrator, falls Sie glauben, dass Ihre Berechtigungen geändert werden sollten.",
"281f9682838b746f5a0b8a4555614843":"Vorauszahlungsrechnung",
"387117ac2a32235f30c143484f94147f":"Proformarechnung",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Im Shop kaufen",
"9bb4a36de6bff47eb4209096972979dd":"Angebot",
"befc83a6b9e66cbfb953b901b91290d0":"Sie haben die maximale Favoritenanzahl erreicht!",
"beb839cf63984c0634d05bdc900e1905":"Waren der Bestellung erhalten",
"d1b0cf6017a647b49abde5b8ba6fe614":"Vor kurzem verwendete Filialen",
"793f04b605fc32835a578962a6994422":"Eine Vorauszahlung erfassen.",
"4eddd0ca790e495626f275843c87f440":"Nachladen",
"ab8100964aeb93c35539b88420121939":"App aus den Favoriten löschen",
"d817327a5392f2d398ed8721a314bc37":"Apps durchsuchen",
"31e12c8c15eae192e29acf9fcb949287":"Suchen Sie nach Unternehmen im Tradeshift-Netzwerk, mit denen Sie Geschäfte machen können.",
"3bcee8f61c10123abe392bf0cc31f99c":"Nach Unternehmen suchen",
"50b5a47e8f2e9f988f509e248860acd0":"Aktionen anzeigen",
"a563aabceadd081e01b44f50d0c9ba26":"Ab dem 31. März 2021 sind Tradeshift-Anwendungen nicht mehr garantiert mit Version 11 des Internet Explorers kompatibel. Bitte updaten Sie Ihren Browser auf eine Version, die von unserer Plattform unterstützt wird.",
"3ad73c1b179af6e6df8e5da91028b57c":"Kundenservice",
"e6e073d7d80064606e77b870ceaf452a":"Filiale wechseln",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Seite erneut laden.",
"2837b35c04c44f52c772b01723ad1ff0":"Benutzereinstellungen",
"5d3d3522e8b4899177ce29532ae479f0":"Alle Zweigstellen anzeigen",
"39b9368677bb137b328178942025581b":"Offene Bestellungen anzeigen",
"488b2f58d018e655df26de3ea3f2590c":"Schriftliche Anfrage",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Sie haben keine Berechtigung, diese Aktion auszuführen.",
"1bf011c064e4459d5665ff6548bb3c04":"Ihr Konto ist zur Löschung vorgemerkt.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Ihr Firmenkonto und Ihr Tradeshift-Benutzerkonto werden am {deletionDate} gelöscht. Falls dies ein Fehler ist, wenden Sie sich an einen Administrator.",
};
