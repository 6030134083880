module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Uma coleção de produtos que gostaria de oferecer para venda a um comprador específico.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Um documento emitido por um vendedor a um comprador, que indica as quantidades e custos de produtos ou serviços fornecidos.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Uma fatura de venda preliminar enviada antes do envio ou da entrega de produtos.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Conta programada para eliminação.",
"4e917d587825921098d19cb5374ffa54":"Adicionar aplicação aos favoritos",
"7328b929c3b7ea8a93ee9e597817f510":"Todas as aplicações",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Ícone da seta",
"421d54e688f76e063b53b1bd67178b42":"Aplicações Disponíveis",
"614bb33c219a0809bbe8e52f938dcce7":"Ordem de compra limitada (pedido)",
"51d895d6183265b06e899570e19eecf6":"Logotipo da Filial",
"9d6641632312bcd817a18331b01bd073":"Navegue e procure produtos disponíveis para compra.",
"883d14b9efdb4894d9879c0eff9b5acb":"Verifique o estado da plataforma ou contacte o apoio.",
"55fc4360777c0241e107f70379e57e75":"Fechar menu",
"da76f49c6f0252b80a6e330d6394c094":"Confirme a receção de produtos e serviços com base numa ordem de compra aberta.",
"67f2b8b530a56e35ec1e8254fb72748f":"Solicitação de conexão",
"9860a293ae95e8583f868975af1f4849":"Contactar o suporte",
"210d71bea3790d832babc970a6674be3":"Converta um pedido aberto numa fatura.",
"8df8c2d028d750d31bb796681c2ea3f9":"Criar",
"e3f60bea8ce9079c56a6a69f86f4f048":"Criar nota de crédito",
"5b5efdc0c8989fe1056b0e54734e824d":"Criar Fatura",
"fadd16ebdc81e4fde9e7fc3154877894":"Criar fatura de pré-pagamento",
"0e1cf03ccaae390506a0da5f0f739280":"Criar fatura proforma",
"20c9ff16bb2f0448290a5087a58cdebf":"Crie e envie documentos de orçamento aos seus clientes.",
"830fa5e28d0b88eb70218ea763f71c5c":"Criar ordem de compra limitada",
"d75c3f6646800bc987c0c07f986b6111":"Criar novo produto",
"9f7cd528570ea5fa70d5f0fdb8227759":"Criar oferta",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Criar pedido",
"9f2650a5abb1e17ffb4714580cc4c457":"Criar produto",
"581c02d2262d5818fb14faef2a5c5e4e":"Criar orçamento",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Criar pedido por escrito",
"3f884136e59449da353adefd80121181":"A criar...",
"d23014cf5a5e7f8c6440b96692831aa8":"Nota de crédito",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Defina itens de produtos ou serviços com preços e orçamentos internos (estimados) para criar ordens de compra para um período de tempo definido.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Defina produtos para venda nas Lojas da Tradeshift.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Defina o seu pedido para um produto ou serviço que não conseguiu encontrar na loja e adicione-o ao seu Cesto ou a um Pedido de compra.",
"1b0dd1aca58f84099cff422597422e56":"Falha ao carregar o Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Falha ao carregar o Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Receção de mercadorias",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Agrupar empresas em grupos de redes para uma administração simplificada",
"d7b639ae724bad4cb10abf6e411bd74e":"Aplicações instaladas",
"3c4be400f728da4526de626653d56ccc":"Fatura",
"697ca358c7443b09bab9478598f14726":"Fatura da encomenda",
"db13bed8b517884b5e8e257c3e829e7d":"Emita créditos para compras futuras.",
"8535934d42d580306640d88e0ed217aa":"A carregar empresa…",
"802ed138bb62eb56ff187f839234478b":"A carregar…",
"1c8e8813d12a483823656cc7c245cd58":"Sair",
"b25df08fcf0d0acaf6384653ea02141e":"Sair",
"34bea6b12f16fea4889a2a80c07ee8ec":"Gerir grupos",
"7a558a55eb2d9e641f608f759964a9f6":"Crie manualmente uma ordem de compra para um vendedor, indicando produtos ou serviços propostos, quantidades e preços.",
"fbb9cc68fee07e74094da1030bfdf236":"Grupo de rede",
"ba116f853ca15c3b6f136da33867158b":"Nenhuma aplicação corresponde ao seu termo de pesquisa.",
"14877050aa06ad5917e9d7a7048ad2e3":"Sem descrição.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Sem autorização",
"bacad0fc8777924b7400a5b05b8cc715":"A {deletionDate}, a sua conta da empresa e a sua conta de utilizador da Tradeshift serão eliminadas. Se isto acontecer por engano, pode anular esta ação.",
"2778717f6acbeb1edaac6a2656b478f3":"Abrir",
"37f066e2502bac5316dfc900890ade4c":"Abrir menu",
"018c4048ea00acea67a5819288f4703f":"Pedido",
"2ae053867612b2202a870bd52cc3ff63":"Estado da plataforma",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Entre em contacto com o administrador da sua conta se achar que as suas permissões devem ser modificadas.",
"281f9682838b746f5a0b8a4555614843":"Fatura de pré-pagamento",
"387117ac2a32235f30c143484f94147f":"Fatura Pró-forma",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Comprar na Loja",
"9bb4a36de6bff47eb4209096972979dd":"Orçamento",
"befc83a6b9e66cbfb953b901b91290d0":"Número máximo de favoritos alcançado!",
"beb839cf63984c0634d05bdc900e1905":"Receber produtos da encomenda",
"d1b0cf6017a647b49abde5b8ba6fe614":"Filiais utilizadas recentemente",
"793f04b605fc32835a578962a6994422":"Registe um pagamento adiantado.",
"4eddd0ca790e495626f275843c87f440":"Recarregar",
"ab8100964aeb93c35539b88420121939":"Remover aplicação dos favoritos",
"d817327a5392f2d398ed8721a314bc37":"Procurar aplicações",
"31e12c8c15eae192e29acf9fcb949287":"Procure empresas na rede Tradeshift para fazer negócios com elas.",
"3bcee8f61c10123abe392bf0cc31f99c":"Pesquisar empresa",
"4716fe18b1d4f74edf1a1cdacc83b329":"Loja",
"50b5a47e8f2e9f988f509e248860acd0":"Mostrar ações",
"a563aabceadd081e01b44f50d0c9ba26":"A partir de 31 de março de 2021, as aplicações da Tradeshift deixarão de ser compatíveis com a versão 11 do Internet Explorer. Atualize o seu navegador para uma versão suportada pela nossa plataforma.",
"3ad73c1b179af6e6df8e5da91028b57c":"Suporte",
"e6e073d7d80064606e77b870ceaf452a":"Mudar filial",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Experimente recarregar a página.",
"2837b35c04c44f52c772b01723ad1ff0":"Definições do utilizador",
"5d3d3522e8b4899177ce29532ae479f0":"Ver todas as filiais",
"39b9368677bb137b328178942025581b":"Ver pedidos em aberto",
"488b2f58d018e655df26de3ea3f2590c":"Pedido por escrito",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Não tem permissões para efetuar esta ação",
"1bf011c064e4459d5665ff6548bb3c04":"A sua conta está programada para eliminação.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"A sua conta da empresa, bem como a sua conta de utilizador da Tradeshift, estão programadas para serem eliminadas a {deletionDate}. Se isto acontecer por engano, entre em contacto com um administrador da conta.",
};
