import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions as layoutActions, selectors as layoutSelectors } from 'lib/layout/layout';
import 'components/cover/cover.less';

export const Cover = ({ coverAttrs, onCoverClick }) => (
	<div {...coverAttrs} onClick={onCoverClick} />
);

Cover.propTypes = {
	coverAttrs: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	coverAttrs: {
		className: layoutSelectors.coverClass(state)
	}
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			onCoverClick: () => layoutActions.clickCover()
		},
		dispatch
	);

export default connect(mapStateToProps, mapDispatchToProps)(Cover);
