import debug from 'debug';
import constants from 'lib/helpers/constants';
import EventHandler from 'lib/events/EventHandler';

/**
 * Debug logging
 */
const d = debug(`${constants.NS}:broadcast`);

/**
 * Encode broadcast to be posted.
 * @param {array|string} appIds List of apps to receive the message, supports glob
 * @param {string} key The key/subject of the event
 * @param {object} data Data to be sent with the event
 * @return {string} message
 */
const stringify = (appIds, key, data) => {
	const prefix = constants.PREFIX_TS_APP_BROADCAST;
	let content = {};
	content.appIds = appIds || '';
	content.key = key || '';
	content.data = data || {};
	let postfix = '';
	try {
		postfix = JSON.stringify(content);
	} catch (e) {
		console.warn(e);
	}
	return prefix + postfix;
};

/**
 * Broadcast Message to one or more apps.
 * @param {array|string} appIds List of apps to receive the message, supports glob
 * Examples:
 * * ['Tradeshift.Developer', 'Tradeshift.DeveloperDemo'] (matches Tradeshift.Developer and Tradeshift.DeveloperDemo)
 * * 'Tradeshift.Developer' (matches Tradeshift.Developer)
 * * '*' (matches any app)
 * * 'Tradeshift.*' (matches all Tradeshift apps)
 * * 'Tradeshift.??Y' (matches Tradeshift.Buy, Tradeshift.Pay, etc.)
 * @param {string} key The key/subject of the event
 * @param {object} data Data to be sent with the event
 */
export default function broadcast(appIds, key, data) {
	d('appIds: %o, key: %o, data: %O', appIds, key, data);
	window.top.postMessage(stringify(appIds, key, data), '*');
}

/**
 * Send a vanilla postMessage to iframe with target id
 * @param {string} data
 * @param {string} targetId ID of target iframe
 */
export function message(data, targetId) {
	d('postMessage %o, data: %o', targetId, data);
	const frameContentWindow = EventHandler.getContentWindow(targetId);
	if (frameContentWindow) {
		frameContentWindow.postMessage(data, '*');
	}
}
