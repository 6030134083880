export default function payload(app, prevApp, prevURL) {
	return {
		properties: {
			appId: app ? app.id : '',
			appPath: app ? app.path || '/' : '/',
			previousAppId: prevApp ? prevApp.id : '',
			previousUri: prevURL ? prevURL.pathname + prevURL.search + prevURL.hash : ''
		}
	};
}
