import debug from 'debug';
import constants from 'lib/helpers/constants';

const d = debug(`${constants.NS}:ga`);

/* eslint-disable */
export default (trackingId) => {
	(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
		(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
		m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;a.crossOrigin='anonymous';a.integrity='sha384-+Mufq/JopHTZLYFkTpT8TA9y0zY/A/VflmpyMsbjcGbcyUbfudhn5Et/w6EIFhoo';m.parentNode.insertBefore(a,m)
	})(window,document,'script','https://d5wfroyti11sa.cloudfront.net/prod/b/2017layout-8e9ebf1475067a135d387bfcc96fb06f9ead496b/js/analytics/ga.js','ga');

	window.ga=window.ga||function(){(window.ga.q=window.ga.q||[]).push(arguments)};window.ga.l=+new Date();

	if (!trackingId || trackingId === 'false') {
		d('Not creating tracker, no %o supplied', 'trackingId');
		return;
	}

	window.ga('create', {
		trackingId,
		name: constants.GA_NS,
		cookieDomain: 'auto',
		siteSpeedSampleRate: 100,
		cookieFlags: 'Secure; SameSite=Lax'
	});

	// Disables AD Features from Google Analytics
	//
	// https://support.google.com/analytics/answer/9050852?hl=en
	window.ga(`${constants.GA_NS}.set`, 'allowAdFeatures', false);
	window.ga(`${constants.GA_NS}.set`, 'anonymizeIp', true);

	window.ga(`${constants.GA_NS}.set`, 'dimension1', '2018');
	window.ga(`${constants.GA_NS}.set`, 'dimension2', process.env.REACT_APP_VERSION);
	window.ga(`${constants.GA_NS}.set`, 'dimension3', 'top');
	window.ga(`${constants.GA_NS}.set`, 'userId', process.config.request.userId);
	window.ga(`${constants.GA_NS}.send`, 'pageview');

	d('Create - id: %o', trackingId);
}

/**
 * Track user timings in Google Analytics
 * @param {string} timingCategory Specifies the user timing category.
 * @param {string} timingVar Specifies the user timing variable.
 * @param {number} timingValue Specifies the user timing value. The value is in milliseconds.
 * @param {string=} timingLabel Specifies the user timing label.
 */
export const gaTiming = ({timingCategory, timingVar, timingValue, timingLabel}) => {
	if (!timingCategory || !timingVar || !timingValue) {
		return;
	}
	timingValue = Math.round(timingValue);
	window.ga(`${constants.GA_NS}.send`, 'timing', timingCategory, timingVar, Math.round(timingValue), timingLabel);
	d('Timing - category: %o, var: %o, value: %oms, label: %o', timingCategory, timingVar, timingValue, timingLabel);

};

/**
 * Sends a hit to Google Analytics.
 * @param {string} page Document Path
 */
export const gaPageview = ({page}) => {
	if (!page) {
		return;
	}
	window.ga(`${constants.GA_NS}.set`, 'page', page);
	window.ga(`${constants.GA_NS}.send`, 'pageview');
	d('Pageview - page: %o', page);
}

/**
 * Sends an event to Google Analytics.
 * @param {string} category What was interacted with (e.g. log-on-page).
 * @param {string} action The type of interaction (e.g. reload-click).
 * @param {string=} label Categorize the event (e.g. black-error-screen).
 * @param {number=} value A numeric value associated with the event (e.g. black-error-screen).
 */
export const gaEvent = (category, action, label, value) => {
	if (!category || !action) {
		return;
	}
	window.ga(`${constants.GA_NS}.send`, 'event', category, action, label, value);
}


/**
 * Sends an exception (crash/error) to Google Analytics.
 * @param {string} exDescription A description of the exception.
 * @param {boolean} fatal `true` if the exception was fatal.
 */
export const gaException = (exDescription, exFatal = false) => {
	if (!exDescription) {
		return;
	}
	window.ga(`${constants.GA_NS}.send`, 'exception', {exDescription, exFatal});
}
