export const initialState = {
	unread: false
};

export const types = {
	UNREAD: 'ts.chrome/inbox/UNREAD'
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case types.UNREAD:
			return {
				...state,
				unread: action.unread
			};
		default:
			return state;
	}
}

export const actions = {
	setUnread: unread => ({
		type: types.UNREAD,
		unread
	})
};
