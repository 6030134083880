import debug from 'debug';
import constants from 'lib/helpers/constants';
import { setItem } from 'lib/localStorage';
import handleMaintenance from 'lib/events/MaintenanceHandler';

const d = debug(`${constants.NS}:debug`);
/**
 * Format URL, no matter if it's a string or object
 * @param {string|object} url
 * @returns {string}
 */
export const u = url => {
	if (typeof url === 'string') {
		return url;
	}
	return `${url.pathname}${url.search}${url.hash}`;
};

window.ts = window.ts || {};
window.ts.chrome = window.ts.chrome || {};
window.ts.chrome.debug = window.ts.chrome.debug || {
	setDebug(hasDebug) {
		setItem('debug', hasDebug ? constants.NS + ':*' : '');
	},
	setDevice(device, enabled) {
		switch (device.toLowerCase()) {
			case 'large':
				setItem(constants.KEY_DEVICE_LARGE, enabled);
				d('deviceType %o set to %o', 'large', enabled);
				break;
			case 'medium':
				setItem(constants.KEY_DEVICE_MEDIUM, enabled);
				d('deviceType %o set to %o', 'medium', enabled);
				break;
			case 'small':
				setItem(constants.KEY_DEVICE_SMALL, enabled);
				d('deviceType %o set to %o', 'small', enabled);
				break;
			default:
				console.debug('Unknown `device`, use %o, %o or %o', 'LARGE', 'MEDIUM', 'SMALL');
		}
	},
	setMaintenance(minutesLeft = 15) {
		d('Maintenance timer set to %o minutes from now.', minutesLeft);
		handleMaintenance(Date.now() + 1000 * 60 * minutesLeft);
	}
};
