import debug from 'debug';
import constants from 'lib/helpers/constants';
import broadcast from 'lib/events/broadcast';
import handleMaintenance from 'lib/events/MaintenanceHandler';

/**
 * Debug logging
 */
const d = debug(`${constants.NS}:EventBus`);

/**
 * Set up a new WebSocket to the EventBus
 * @returns {WebSocket}
 */
function initSocket() {
	const protocol = `ws${window.location.protocol === 'https:' ? 's' : ''}:`;
	const host = window.location.host;
	return new WebSocket(`${protocol}//${host}/_events`);
}

/**
 * Handle events on the EventBus WebSocket connection
 */
function initHandlers(socket) {
	socket.addEventListener('error', event => {
		d('Error! %O', event);
	});

	socket.addEventListener('open', event => {
		d('Connection open! %O', event);
	});

	socket.addEventListener('close', event => {
		d('Connection closed. %O Reconnecting after %ss', event, constants.DURATION_EVENTBUS_RETRY);
		setTimeout(function() {
			d('Reconnecting...');
			initHandlers(initSocket());
		}, constants.DURATION_EVENTBUS_RETRY);
	});

	socket.addEventListener('message', event => {
		d('Message received: %O', event);
		let data;
		try {
			data = JSON.parse(event.data);
			// Hotfix until the Backend JSON stringifier is fixed
			if (data.type && !data.Type) {
				data.Type = data.type;
			}
		} catch (e) {
			d('Error parsing message: %o %O', e, e);
		}

		/**
		 * Re-broadcast event using ts.app
		 */
		if (data && data.Type) {
			// @TODO allow the ts.io Hub to emit events and act as a write-only system bus
			// io().emit(constants.PREFIX_EVENT_BUS + data.Type, data, process.config.featureApps.chrome);
			if (data.Type === constants.EVENT_SYSTEM_MAINTENANCE_STATUS_CHANGE) {
				if (data.Properties && data.Properties.nextMaintenance) {
					handleMaintenance(data.Properties.nextMaintenance);
				}
			} else {
				broadcast(process.config.featureApps.chrome, constants.PREFIX_EVENT_BUS + data.Type, data);
			}
		}
	});
}

/**
 * All events are received from the Tradeshift System here.
 */
export default class EventBus {
	/**
	 * Start an auto-reconnecting WebSocket connection and listen for events.
	 */
	static listen() {
		initHandlers(initSocket());
	}
}
