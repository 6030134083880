import broadcast from 'lib/events/broadcast';
import store from 'lib/store';
import constants from 'lib/helpers/constants';

/**
 * Initialize all things localStorage
 */
const layoutBroadcaster = () => {
	let prevCollaboration;
	let prevMenu;

	store.subscribe(() => {
		const state = store.getState();

		const collaborationVisible = state.layout.collaboration;
		if (prevCollaboration !== collaborationVisible) {
			if (!collaborationVisible) {
				broadcast(process.config.featureApps.universalInbox, constants.EVENT_TASK_LIST_CLOSE);
			}
			prevCollaboration = collaborationVisible;
		}

		const menu = state.layout.menu;
		if (prevMenu !== menu) {
			broadcast(process.config.featureApps.chrome, constants.EVENT_LAYOUT_SEND, {
				app: process.config.featureApps.engagementFlow
			});
			prevMenu = menu;
		}
	});
};

export default layoutBroadcaster;
