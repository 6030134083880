module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"En samling af produkter, du gerne vil tilbyde til salg til en bestemt køber.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Et dokument, der er udstedt af en leverandør til en køber, og som angiver antal og omkostningerne ved de leverede produkter eller tjenester.",
"5b24efebe4bd25a8c5e3aca06197e35e":"En foreløbig faktura for salg, der sendes forud for forsendelse eller levering af varer.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Konto er planlagt til sletning.",
"4e917d587825921098d19cb5374ffa54":"Tilføj app til favoritter",
"7328b929c3b7ea8a93ee9e597817f510":"Alle apps",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Pile-ikon",
"421d54e688f76e063b53b1bd67178b42":"Tilgængelige apps",
"614bb33c219a0809bbe8e52f938dcce7":"Rammeindkøbsordre (anmodning)",
"51d895d6183265b06e899570e19eecf6":"Afdelingslogo",
"9d6641632312bcd817a18331b01bd073":"Gennemse og søg efter tilgængelige produkter til køb.",
"883d14b9efdb4894d9879c0eff9b5acb":"Kontroller platformens status, eller kontakt support.",
"55fc4360777c0241e107f70379e57e75":"Luk menu",
"da76f49c6f0252b80a6e330d6394c094":"Bekræft modtagelse af produkter og tjenester på en åben indkøbsordre.",
"67f2b8b530a56e35ec1e8254fb72748f":"Kontaktanmodning",
"9860a293ae95e8583f868975af1f4849":"Kontakt Support",
"210d71bea3790d832babc970a6674be3":"Konverter en åben ordre til en faktura.",
"8df8c2d028d750d31bb796681c2ea3f9":"Lav testfaktura",
"e3f60bea8ce9079c56a6a69f86f4f048":"Opret kreditnota",
"5b5efdc0c8989fe1056b0e54734e824d":"Opret faktura",
"fadd16ebdc81e4fde9e7fc3154877894":"Opret forudbetalingfaktura",
"0e1cf03ccaae390506a0da5f0f739280":"Opret proformafaktura",
"20c9ff16bb2f0448290a5087a58cdebf":"Opret og send tilbudsdokumenter til dine kunder.",
"830fa5e28d0b88eb70218ea763f71c5c":"Opret rammeindkøbsordre",
"d75c3f6646800bc987c0c07f986b6111":"Opret nyt produkt",
"9f7cd528570ea5fa70d5f0fdb8227759":"Opret tilbud",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Opret ordre",
"9f2650a5abb1e17ffb4714580cc4c457":"Opret produkt",
"581c02d2262d5818fb14faef2a5c5e4e":"Opret tilbud",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Opret skriftlig anmodning",
"3f884136e59449da353adefd80121181":"Opret...",
"d23014cf5a5e7f8c6440b96692831aa8":"Kreditnota",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definer produkt- eller tjenesteelementer med (estimerede) priser og interne budgetter til at oprette indkøbsordrer for en defineret periode.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definer produkter til salg på Tradeshift Marketplaces.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definer din anmodning om et produkt eller en tjeneste, som du ikke kunne finde i butikken, og føj den til din kurv eller en indkøbsrekvisition.",
"1b0dd1aca58f84099cff422597422e56":"Det mislykkedes at indlæse Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Det mislykkedes at indlæse Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Varemodtagelse",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Grupper virksomheder i netværksgrupper for forenklet administration",
"d7b639ae724bad4cb10abf6e411bd74e":"Installerede apps",
"3c4be400f728da4526de626653d56ccc":"Faktura",
"697ca358c7443b09bab9478598f14726":"Faktura fra ordre",
"db13bed8b517884b5e8e257c3e829e7d":"Udsted kredit mod fremtidige køb.",
"8535934d42d580306640d88e0ed217aa":"Indlæser firma…",
"802ed138bb62eb56ff187f839234478b":"Indlæser...",
"1c8e8813d12a483823656cc7c245cd58":"Log af",
"b25df08fcf0d0acaf6384653ea02141e":"Log af",
"34bea6b12f16fea4889a2a80c07ee8ec":"Administrer grupper",
"7a558a55eb2d9e641f608f759964a9f6":"Opret manuelt en indkøbsordre for en leverandør med angivelse af foreslåede varer eller tjenester, antal og priser.",
"fbb9cc68fee07e74094da1030bfdf236":"Netværksgruppe",
"ba116f853ca15c3b6f136da33867158b":"Ingen apps matcher dit søgeudtryk.",
"14877050aa06ad5917e9d7a7048ad2e3":"Ingen beskrivelse.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Ingen tilladelse",
"bacad0fc8777924b7400a5b05b8cc715":"Den {deletionDate} vil din virksomhedskonto og din Tradeshift-brugerkonto blive slettet. Hvis dette er en fejl, kan du annullere det.",
"2778717f6acbeb1edaac6a2656b478f3":"Åbn",
"37f066e2502bac5316dfc900890ade4c":"Åbn menu",
"018c4048ea00acea67a5819288f4703f":"Ordre",
"2ae053867612b2202a870bd52cc3ff63":"Platformsstatus",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Kontakt din kontoadministrator, hvis du mener, at dine tilladelser bør ændres.",
"281f9682838b746f5a0b8a4555614843":"Forudbetalingfaktura",
"387117ac2a32235f30c143484f94147f":"Proformafaktura",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Køb fra butik",
"9bb4a36de6bff47eb4209096972979dd":"Tilbud",
"befc83a6b9e66cbfb953b901b91290d0":"Nåede det maksimale antal favoritter",
"beb839cf63984c0634d05bdc900e1905":"Modtag varer fra ordre",
"d1b0cf6017a647b49abde5b8ba6fe614":"Afdelinger, der er brugt for nyligt",
"793f04b605fc32835a578962a6994422":"Registrer en forskudsbetaling.",
"4eddd0ca790e495626f275843c87f440":"Genindlæs",
"ab8100964aeb93c35539b88420121939":"Fjern app fra favoritter",
"d817327a5392f2d398ed8721a314bc37":"Søg efter apps",
"31e12c8c15eae192e29acf9fcb949287":"Søg efter virksomheder på Tradeshift-netværket, du kan gøre forretninger med.",
"3bcee8f61c10123abe392bf0cc31f99c":"Søg efter firma",
"4716fe18b1d4f74edf1a1cdacc83b329":"Butik",
"50b5a47e8f2e9f988f509e248860acd0":"Vis handlinger",
"a563aabceadd081e01b44f50d0c9ba26":"Fra den 31. marts 2021 er Tradeshift-applikationer ikke garanteret kompatible med version 11 af Internet Explorer. Opdater din browser til en version, der understøttes af vores platform.",
"e6e073d7d80064606e77b870ceaf452a":"Skift afdeling",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Forsøg at genindlæse siden.",
"2837b35c04c44f52c772b01723ad1ff0":"Brugerindstillinger",
"5d3d3522e8b4899177ce29532ae479f0":"Vis alle undergrene",
"39b9368677bb137b328178942025581b":"Vis åbne ordrer",
"488b2f58d018e655df26de3ea3f2590c":"Skriftlig anmodning",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Du har ikke tilladelse til at udføre denne handling.",
"1bf011c064e4459d5665ff6548bb3c04":"Din konto er planlagt til sletning.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Din virksomhedskonto samt din Tradeshift-brugerkonto er planlagt til sletning den {deletionDate}. Hvis dette er en fejl, skal du kontakte en kontoadministrator.",
};
