import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'lib/language/language';
import AppsModalButton from 'components/apps/appsmodalbutton/AppsModalButton';
import CreateActionsButton from 'components/apps/createactionsbutton/CreateActionsButton';
import AppListSection from 'components/apps/applistsection/AppListSection';
import constants from 'lib/helpers/constants';
import appsSelectors from 'lib/apps/appsSelectors';
import { selectors as layoutSelectors } from 'lib/layout/layout';
import 'components/apps/appList.less';

export const AppList = ({ t, appListAttrs, pinnedApps, unread }) => {
	const apps = pinnedApps.map(app => {
		if (app.id === constants.APP_MESSAGES.id) {
			app.notification = unread;
		}
		return app;
	});

	return (
		<div {...appListAttrs}>
			<div className={[constants.CLASS_APPS_CONTAINER, constants.CLASS_CONTAINER].join(' ')}>
				<CreateActionsButton />
				<AppListSection
					title={t('[Title] Installed apps')}
					className={constants.CLASS_INSTALLED_APPS}
					apps={apps}
				>
					<AppsModalButton />
				</AppListSection>
			</div>
		</div>
	);
};

AppList.propTypes = {
	t: PropTypes.func.isRequired,
	appListAttrs: PropTypes.object.isRequired,
	pinnedApps: PropTypes.arrayOf(PropTypes.object).isRequired,
	unread: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
	appListAttrs: {
		className: layoutSelectors.appsClass(state)
	},
	pinnedApps: appsSelectors.getPinnedApps(state),
	unread: state.inbox.unread,
	t: t()
});

export default connect(mapStateToProps)(AppList);
