import React from 'react';
import PropTypes from 'prop-types';
import store from 'lib/store';
import constants from 'lib/helpers/constants';
import { connect } from 'react-redux';
import { actions as framesActions } from 'lib/frames/frames';

function isAboutBlank(src) {
	return src === 'about:blank';
}

// eslint-disable-next-line react/no-deprecated
export class TsFrame extends React.Component {
	constructor() {
		super();
		this.state = {};
	}
	componentDidMount() {
		this.setState({
			src: this.props.src
		});
	}
	componentWillReceiveProps(nextProps) {
		if (nextProps.src !== this.props.src) {
			this.setState({
				src: nextProps.src
			});
		}
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (isAboutBlank(nextState.src) || !nextState.src) {
			return false;
		}

		return this.state.src !== nextState.src;
	}
	static onLoad(props, state, contentWindow) {
		if (!props || !state || !state.src || isAboutBlank(state.src)) {
			return function() {};
		}
		return function() {
			store.dispatch(
				framesActions.setFrame({
					target: props.frameId,
					ready: true
				})
			);
		};
	}
	frameHTML() {
		if (!this.state.src) {
			return;
		}

		const props = [];
		const flags = this.props.sandboxFlags;
		for (const prop in flags) {
			if (flags.hasOwnProperty(prop)) {
				props.push(`${prop}="${flags[prop]}"`);
			}
		}
		const __html = `<iframe
				src="${this.state.src}"
				class="${this.props.className || ''}"
				name="${this.props.id}"
				id="${this.props.id}"
				title="${this.props.id}"
				allowtransparency="true"
				${props}
			/>`;
		//FIXME: This is a hack for Firefox issue with loading the collaboration frame
		if (this.props.id === constants.ID_COLLABORATION_FRAME) {
			window.setTimeout(() => {
				document.getElementById(this.props.id).setAttribute('src', this.state.src);
			}, 5);
		}

		return { __html };
	}
	setupOnLoad(container) {
		const src = this.state.src;
		if (!src || isAboutBlank(src)) {
			return;
		}

		if (container) {
			if (!this.iframeSrc || this.iframeSrc !== this.state.src) {
				this.container = container;
				if (this.container.children) {
					this.iframe = this.container.children[0];
					this.iframe.onload = TsFrame.onLoad(this.props, this.state, this.iframe.contentWindow);
					store.dispatch(
						framesActions.setFrame({
							target: this.props.frameId,
							win: this.iframe.contentWindow
						})
					);
				}
			}
		}
	}
	render() {
		if (this.state.src && isAboutBlank(this.state.src)) {
			return;
		}
		return (
			<div
				id={`frame-container-${this.props.frameId}`}
				dangerouslySetInnerHTML={this.frameHTML()}
				ref={container => this.setupOnLoad(container)}
			/>
		);
	}
}

TsFrame.propTypes = {
	frameId: PropTypes.string,
	sandboxFlags: PropTypes.string
};

const mapStateToProps = state => ({
	frames: state.frames
});
const mapDispatchToProps = dispatch => ({});
const mergeProps = (stateProps, dispatchProps, ownProps) => {
	const props = {
		sandboxFlags: ownProps.sandboxFlags
	};
	const frameId = ownProps.frameId;
	if (frameId) {
		let id, className, src;
		if (Object.values(constants.FRAMES).includes(frameId)) {
			({ id, className, src } = stateProps.frames[frameId]);
		} else if (stateProps.frames.spawned[frameId]) {
			({ id, className, src } = stateProps.frames.spawned[frameId]);
		} else {
			return props;
		}
		props.frameId = frameId;
		props.id = id;
		props.className = className;
		props.src = src;
	}
	return props;
};

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(TsFrame);
