import debug from 'debug';
import { setLanguage, t } from 'lib/language/language';
import { gaEvent, gaException, gaTiming } from 'lib/history/ga';
import history from 'lib/history/createHistory';
import App from 'lib/apps/App';
import URLParser from 'lib/apps/URLParser';
import KeepAlive from 'lib/events/KeepAlive';
import handleMaintenance from 'lib/events/MaintenanceHandler';
import appsTypes from 'lib/apps/appsTypes';
import { get } from 'lib/helpers/fetch';
import { getTitle } from 'lib/root/rootHelpers';
import { isDevelopment, isEdge, isIE11, removeDuplicates } from 'lib/helpers/helpers';
import { loadFirstApp } from 'lib/history/hashHistory';
import { actions as framesActions } from 'lib/frames/frames';
import constants from 'lib/helpers/constants';
import {
	getBranchSwitchLog,
	getErrorCount,
	getUserQuickLaunchApps,
	setErrorCount
} from 'lib/localStorage';
import { createSelector } from 'reselect';
import { actions as topBarActions } from '../topBar/topBar';
import { loadAppcues } from '@tradeshift/appcues';

const d = debug(`${constants.NS}:root`);

export const initialState = {
	title: '',
	ready: false,
	error: false,
	deletionRequest: null,
	isCompanyAdmin: false,
	recentBranches: [],
	flagrURL: ''
};

export const types = {
	DATA_FETCH: 'ts.chrome/root/DATA_FETCH',
	DATA_FETCHED: 'ts.chrome/root/DATA_FETCHED',
	DATA_FETCHED_NEXT: 'ts.chrome/root/DATA_FETCHED_NEXT',
	USER_LOGOUT: 'ts.chrome/root/USER_LOGOUT',
	TITLE_SET: 'ts.chrome/root/TITLE_SET',
	ERROR_SET: 'ts.chrome/root/ERROR_SET',
	DELETION_REQUEST_SET: 'ts.chrome/root/DELETION_REQUEST_SET',
	IS_COMPANY_ADMIN_SET: 'ts.chrome/root/IS_COMPANY_ADMIN_SET',
	FLAGR_URL_SET: 'ts.chrome/root/FLAGR_URL_SET',
	ADD_SWITCHED_BRANCH: 'ts.chrome/root/ADD_SWITCHED_BRANCH',
	SET_RECENT_BRANCHES: 'ts.chrome/root/SET_RECENT_BRANCHES'
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.USER_LOGOUT:
			window.location.href = process.config.urls.logoutURL;
			return {
				...state
			};

		case types.TITLE_SET:
		case appsTypes.APP_LAUNCH:
			const title = getTitle(action.app);
			document.title = title;
			return {
				...state,
				title
			};

		case types.DATA_FETCH:
		case types.DATA_FETCHED_NEXT:
			return {
				...state,
				ready: state.ready || action.type === types.DATA_FETCHED_NEXT
			};

		case types.ERROR_SET:
			return {
				...state,
				error: action.err
			};

		case types.DELETION_REQUEST_SET:
			return {
				...state,
				deletionRequest: action.deletionRequest
			};

		case types.IS_COMPANY_ADMIN_SET:
			return {
				...state,
				isCompanyAdmin: action.isCompanyAdmin
			};

		case types.FLAGR_URL_SET:
			return {
				...state,
				flagrURL: action.flagrURL + '/api/v1/evaluation'
			};

		case types.ADD_SWITCHED_BRANCH:
			return {
				...state,
				recentBranches: [action.branch, ...state.recentBranches]
			};

		case types.SET_RECENT_BRANCHES:
			return {
				...state,
				recentBranches: action.recentBranches
			};

		default:
			return state;
	}
};

export default reducer;

export const requests = {
	fetchData: async () => {
		const quickLaunchApps = getUserQuickLaunchApps();
		try {
			/**
			 * Always update the AccessToken before making a new request
			 */
			await KeepAlive.updateAccessToken();
			d('Loading data from %o', process.config.urls.serviceURL);
			const data = await get(process.config.urls.serviceURL, {
				url: history.location.pathname + history.location.search + history.location.hash
			});
			if (!data.hasPinned) {
				data.apps = data.apps.map(app => {
					return new App().setFromApp({
						...app,
						pinned: quickLaunchApps.indexOf(app.id) + 1
					});
				});
			}
			if (data.user && data.user.avatarUrl) {
				data.user.avatarURL = data.user.avatarUrl;
			}

			try {
				// get data from new endpoint
				const favApps = await get(process.config.urls.favAppsURL);

				data.apps = data.apps.map(app => {
					return new App().setFromApp({
						...app,
						pinned: favApps.pinnedApps.indexOf(app.id) + 1
					});
				});
			} catch (err) {
				console.error('Error fetch/set fav-apps', err);
			}

			d('Loaded data %O', data);
			setErrorCount(0);
			return data;
		} catch (err) {
			/**
			 * In case we're in development mode, we should load the dummy data,
			 * if V4 is unreachable
			 */
			if (isDevelopment()) {
				setErrorCount(0);
				const mockData = {
					user: require('mockdata/user').default,
					apps: require('mockdata/apps').default,
					// uncomment next line for testing the top bar
					// topBar: require('mockdata/top-bar').default,
					startApp: process.config.startApp,
					redirects: {
						'Tradeshift.Marketplace': 'Tradeshift.ProductManager'
					},
					flagrURL: 'https://flagr.eu-west-1.sand.ts.sv'
				};
				mockData.apps = mockData.apps.map(app => {
					return new App().setFromApp({
						...app,
						pinned: quickLaunchApps.indexOf(app.id) + 1
					});
				});
				await new Promise(function(resolve) {
					setTimeout(resolve, constants.DURATION_STANDALONE_STARTUP);
				});
				d('Standalone mode, mocking data %O', mockData);
				return mockData;
			}
			throw err;
		}
	}
};

export const actions = {
	logout: () => ({ type: types.USER_LOGOUT }),
	fetchData: () => ({ type: types.DATA_FETCH }),
	gotData: data => ({ type: types.DATA_FETCHED, data }),
	afterData: firstApp => ({ type: types.DATA_FETCHED_NEXT, firstApp }),
	setTitle: app => ({ type: types.TITLE_SET, app }),
	setError: err => ({ type: types.ERROR_SET, err }),
	setDeletionRequest: deletionRequest => ({ type: types.DELETION_REQUEST_SET, deletionRequest }),
	setIsCompanyAdmin: isCompanyAdmin => ({ type: types.IS_COMPANY_ADMIN_SET, isCompanyAdmin }),
	setFlagrURL: flagrURL => ({ type: types.FLAGR_URL_SET, flagrURL }),
	addSwitchedBranch: branch => ({ type: types.ADD_SWITCHED_BRANCH, branch }),
	setRecentBranches: recentBranches => ({ type: types.SET_RECENT_BRANCHES, recentBranches }),
	handleIE11Banner: t => async (dispatch, getState) => {
		if (isIE11()) {
			dispatch(
				topBarActions.setTopBar({
					topBar: {
						textLeft: t(
							'Starting March 31st, 2021, Tradeshift applications are not guaranteed to be compatible with version 11 of Internet Explorer. Please update your browser to a version supported by our platform.'
						),
						textLeftColor: '#00aeff',
						backgroundColor: '#fdf3d7',
						url: 'https://support.tradeshift.com/knowledgebase/article/206369444',
						isIEDeprecationBanner: true
					}
				})
			);
		}
	},
	/**(
	 * Initiate loading all data for the Chrome
	 * This includes the User and the list of Apps
	 */
	getData: () => async (dispatch, getState) => {
		/**
		 * When calling getData() for the first time, we should have a loading screen.
		 * All other times, we shouldn't.
		 */
		const firstRun = getState().root.ready === false;
		// Tell everybody we're starting to fetch the data
		dispatch(actions.fetchData());
		// Set recentBranches list from localstorage
		dispatch(actions.setRecentBranches(getBranchSwitchLog()));
		let data, loadStart, loadEnd;
		try {
			// Make request to our backend, Tradeshift.ChromeService in v4-apps
			loadStart = window.performance.now();
			data = await requests.fetchData();
			loadEnd = window.performance.now();

			if (!data) {
				throw new Error('No data received.');
			}
		} catch (err) {
			// Something didn't work out
			const msBrowser = isIE11() || isEdge();
			const errorMsg = 'Error fetching user, company and apps.\n\n';
			const errorDetails = msBrowser ? `${err.message}\n${err.stack}` : err;
			console.error(errorMsg, errorDetails);
			// Remember how many times the user tried to reload
			setErrorCount(getErrorCount() + 1);
			// Trigger fancy animations
			setTimeout(() => dispatch(actions.setError(err)), constants.DURATION_ERROR_FADE);
			// Send events to Google Analytics

			const isLogon = history.length <= 1;
			const gaAction = isLogon ? constants.GA_ACTION_LOGIN : constants.GA_ACTION_RELOAD;
			const gaValue = getErrorCount() - 1;
			gaEvent('chrome', gaAction, constants.GA_LABEL_BSOD, isLogon ? gaValue : undefined);
			gaException(
				`Error fetching user, company and apps. | Error: ${err.message} | Stack: ${err.stack}`,
				true
			);
		}

		try {
			// Track timing to GA
			gaTiming({
				timingCategory: 'ChromeService Request',
				timingVar: 'load',
				timingValue: loadEnd - loadStart,
				timingLabel: process.env.REACT_APP_VERSION
			});
			// Track detailed timing of each backend call to GA
			if (
				data.performanceEntries &&
				Array.isArray(data.performanceEntries) &&
				data.performanceEntries.length
			) {
				data.performanceEntries.forEach(entry => {
					gaTiming({
						timingCategory: 'ChromeService Measurement',
						timingVar: entry.name,
						timingValue: entry.duration,
						timingLabel: process.env.REACT_APP_VERSION
					});
				});
			}

			if (data.flagrURL) {
				dispatch(actions.setFlagrURL(data.flagrURL));
				dispatch(actions.handleIE11Banner(t()));
			}
			// Set the default language
			if (data.user && data.user.language) {
				setLanguage(data.user.language);
			}
			if (data.user) {
				dispatch(actions.setIsCompanyAdmin(data.user.isCompanyAdmin));
			}

			// TODO: This is the hack for University. Remove it after they fix the Japanese translation
			const lang = data.user && data.user.language ? data.user.language : window.navigator.language;
			if (lang.toLowerCase().includes('ja')) {
				let university = data.apps.find(app => app.id === 'Tradeshift.University');
				university.hidden = true;
			}

			// Inject the fake app, Tradeshift.Legacy to load Frontend/Grails pages
			data.apps.push(new App(constants.APP_LEGACY));
			// Populate redirects
			data.redirects = data.redirects || {};
			// Tell everybody that we've got the data
			dispatch(actions.gotData(data));
			// Load the first app and get the App object for it
			const firstApp = loadFirstApp(data);
			dispatch(actions.setTitle(firstApp));
			dispatch(
				framesActions.setFrame({
					target: constants.FRAMES.MAIN,
					src: new URLParser(history.location, true, 'root:launchFirstApp').url,
					ready: firstRun ? false : undefined,
					appId: firstApp.id
				})
			);
			dispatch(
				framesActions.setFrame({
					target: constants.FRAMES.INBOX,
					src: process.config.urls.inboxURL,
					ready: firstRun ? false : undefined,
					appId: process.config.featureApps.inbox
				})
			);
			dispatch(
				framesActions.setFrame({
					target: constants.FRAMES.COLLABORATION,
					ready: firstRun ? false : undefined,
					appId: process.config.featureApps.collaboration
				})
			);

			// Tell everybody that we've got the data and did something with it
			dispatch(actions.afterData());
			loadAppcues({
				app: 'Tradeshift.Chrome',
				accessToken: process.config.request.accessToken,
				apiBaseUrl: data.apiBaseUrl,
				locale: data.user && data.user.language,
				userId: process.config.request.userId,
				companyId: process.config.request.companyId
			});

			// Set the deletion request state
			const deletionRequest = await get(process.config.urls.deletionRequestURL);
			if (deletionRequest) {
				dispatch(actions.setDeletionRequest(deletionRequest === true ? {} : deletionRequest));
			}

			// Trigger the maintenance popup if the next maintenance is in the future
			handleMaintenance(data.nextMaintenance);
		} catch (err) {
			console.error(
				'Something went wrong while processing user, company and apps.',
				`${err.message}\n${err.stack}`
			);
			gaException(
				`Error processing user, company and apps. | Error: ${err.message} | Stack: ${err.stack}`,
				true
			);
		}
	}
};

export const filters = {
	recentBranches: state => state.root.recentBranches
};

export const selectors = {
	recentBranches: createSelector([filters.recentBranches], recentBranches => {
		const uniqueBranches = removeDuplicates(recentBranches, 'id');
		const withoutTheCurrentBranch = uniqueBranches.filter(
			branch => branch.id !== window.ts.chrome.config.companyId
		);
		return withoutTheCurrentBranch.slice(0, constants.MAX_RECENT_BRANCHES);
	})
};
