module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Een verzameling producten die u aan een bepaalde verkoper te koop wilt aanbieden.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Een document dat door een verkoper aan een koper is uitgegeven waarin de hoeveelheden en kosten van geleverde producten of diensten worden aangeduid.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Een voorlopig aankoopbewijs dat vóór verzending of levering van goederen is verzonden.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Account staat gepland voor verwijdering.",
"4e917d587825921098d19cb5374ffa54":"App toevoegen aan favorieten",
"7328b929c3b7ea8a93ee9e597817f510":"Alle apps",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Pijlpictogram",
"421d54e688f76e063b53b1bd67178b42":"Beschikbare apps",
"614bb33c219a0809bbe8e52f938dcce7":"Limietbestelling (aanvraag)",
"51d895d6183265b06e899570e19eecf6":"Logo van filiaal",
"9d6641632312bcd817a18331b01bd073":"Blader en zoek naar beschikbare producten voor aankoop.",
"883d14b9efdb4894d9879c0eff9b5acb":"Controleer de platformstatus of neem contact op met Ondersteuning.",
"55fc4360777c0241e107f70379e57e75":"Menu sluiten",
"da76f49c6f0252b80a6e330d6394c094":"Bevestig het ontvangstbewijs van producten en diensten op basis van een open inkooporder",
"67f2b8b530a56e35ec1e8254fb72748f":"Verbindingsverzoek",
"9860a293ae95e8583f868975af1f4849":"Contact met Ondersteuning",
"210d71bea3790d832babc970a6674be3":"Zet een open order om in een factuur.",
"8df8c2d028d750d31bb796681c2ea3f9":"Testfactuur aanmaken",
"e3f60bea8ce9079c56a6a69f86f4f048":"Creditnota aanmaken",
"5b5efdc0c8989fe1056b0e54734e824d":"Factuur aanmaken",
"fadd16ebdc81e4fde9e7fc3154877894":"Factuur van vooruitbetaling aanmaken",
"0e1cf03ccaae390506a0da5f0f739280":"Pro-formafactuur aanmaken",
"20c9ff16bb2f0448290a5087a58cdebf":"Maak offertedocumenten aan en verstuur deze naar uw klanten.",
"830fa5e28d0b88eb70218ea763f71c5c":"Limietbestelling aanmaken",
"d75c3f6646800bc987c0c07f986b6111":"Nieuw product aanmaken",
"9f7cd528570ea5fa70d5f0fdb8227759":"Aanbieding aanmaken",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Order aanmaken",
"9f2650a5abb1e17ffb4714580cc4c457":"Product aanmaken",
"581c02d2262d5818fb14faef2a5c5e4e":"Prijsopgave aanmaken",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Schriftelijke aanvraag aanmaken",
"3f884136e59449da353adefd80121181":"Aanmaken...",
"d23014cf5a5e7f8c6440b96692831aa8":"Creditnota",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definieer product- of dienstelementen met (geschatte) prijzen en interne budgetten om inkooporders voor een bepaalde periode aan te maken.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definieer producten die op Tradeshift-marktplaatsen te koop zijn",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definieer uw aanvraag voor een product of dienst die u niet in de winkel kon vinden en voeg deze toe aan uw winkelwagen of aan een inkoopaanvraag.",
"1b0dd1aca58f84099cff422597422e56":"Tradeshift laden mislukt",
"2ca056bf517beb52194e04b1d425b85d":"Tradeshift laden mislukt,",
"901bcad22b5486ef2185cb1e59e0a234":"Goederenontvangst",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Bedrijven in netwerkgroepen groeperen om de administratie te vereenvoudigen",
"d7b639ae724bad4cb10abf6e411bd74e":"Uw geïnstalleerde apps",
"3c4be400f728da4526de626653d56ccc":"Factuur",
"697ca358c7443b09bab9478598f14726":"Factuur van order",
"db13bed8b517884b5e8e257c3e829e7d":"Krediet verstrekken tegen toekomstige aankopen.",
"8535934d42d580306640d88e0ed217aa":"Bedrijf laden…",
"802ed138bb62eb56ff187f839234478b":"Wordt geladen...",
"1c8e8813d12a483823656cc7c245cd58":"Afmelden",
"b25df08fcf0d0acaf6384653ea02141e":"Afmelden",
"34bea6b12f16fea4889a2a80c07ee8ec":"Groepen beheren",
"7a558a55eb2d9e641f608f759964a9f6":"Handmatig een inkooporder aanmaken voor een verkoper, met voorgestelde goederen of diensten, hoeveelheden en prijzen.",
"fbb9cc68fee07e74094da1030bfdf236":"Netwerkgroep",
"ba116f853ca15c3b6f136da33867158b":"Er zijn geen apps die met uw zoekterm overeenstemmen.",
"14877050aa06ad5917e9d7a7048ad2e3":"Geen beschrijving.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Geen toestemming",
"bacad0fc8777924b7400a5b05b8cc715":"Op {deletionDate} worden uw bedrijfsaccount en uw Tradeshift-gebruikersaccount verwijderd. Als dit een vergissing is, kunt u het annuleren.",
"2778717f6acbeb1edaac6a2656b478f3":"Openen",
"37f066e2502bac5316dfc900890ade4c":"Menu openen",
"2ae053867612b2202a870bd52cc3ff63":"Platformstatus",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Neem contact op met uw accountbeheerder als u vindt dat uw rechten moeten worden aangepast.",
"281f9682838b746f5a0b8a4555614843":"Factuur van vooruitbetaling",
"387117ac2a32235f30c143484f94147f":"Pro-formafactuur",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Kopen in de winkel",
"9bb4a36de6bff47eb4209096972979dd":"Offerte",
"befc83a6b9e66cbfb953b901b91290d0":"Maximaal aantal favorieten bereikt!",
"beb839cf63984c0634d05bdc900e1905":"Goederen van order ontvangen",
"d1b0cf6017a647b49abde5b8ba6fe614":"Onlangs gebruikte filialen",
"793f04b605fc32835a578962a6994422":"Een vooruitbetaling registreren.",
"4eddd0ca790e495626f275843c87f440":"Opnieuw laden",
"ab8100964aeb93c35539b88420121939":"App verwijderen uit favorieten",
"d817327a5392f2d398ed8721a314bc37":"Apps zoeken",
"31e12c8c15eae192e29acf9fcb949287":"Zoek naar bedrijven op het Tradeshift-netwerk om zaken mee te doen.",
"3bcee8f61c10123abe392bf0cc31f99c":"Zoeken naar bedrijf",
"4716fe18b1d4f74edf1a1cdacc83b329":"Winkel",
"50b5a47e8f2e9f988f509e248860acd0":"Actie weergeven",
"a563aabceadd081e01b44f50d0c9ba26":"Vanaf 31 maart 2021 wordt niet meer gegarandeerd dat Tradeshift-toepassingen compatibel zijn met versie 11 van Internet Explorer. Update uw browser naar een versie die door ons platform wordt ondersteund.",
"3ad73c1b179af6e6df8e5da91028b57c":"Ondersteuning",
"e6e073d7d80064606e77b870ceaf452a":"Filiaal wisselen",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Probeer de pagina nogmaals te laden.",
"2837b35c04c44f52c772b01723ad1ff0":"Gebruikersinstellingen",
"5d3d3522e8b4899177ce29532ae479f0":"Alle filialen weergeven",
"39b9368677bb137b328178942025581b":"Openstaande orders weergeven",
"488b2f58d018e655df26de3ea3f2590c":"Schriftelijke aanvraag",
"7afb6c3060810bd8e4f55a2e42fd69c0":"U hebt geen toestemming om deze actie uit te voeren.",
"1bf011c064e4459d5665ff6548bb3c04":"Uw account staat gepland voor verwijdering.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Uw bedrijfsaccount en uw Tradeshift-gebruikersaccount staan gepland voor verwijdering op {deletionDate}. Als dit een vergissing is, neem dan contact op met een accountbeheerder.",
};
