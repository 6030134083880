module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Una collezione di prodotti che desideri vendere a ad uno specifico acquirente.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Un documento rilasciato da un venditore ad un acquirente che indica le quantità e i costi dei prodotti o dei servizi forniti.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Una fattura preliminare di vendita inviata prima della spedizione o della consegna della merce.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Account programmato per la cancellazione.",
"4e917d587825921098d19cb5374ffa54":"Aggiungi app ai preferiti",
"7328b929c3b7ea8a93ee9e597817f510":"Tutte le app",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Icona a freccia",
"421d54e688f76e063b53b1bd67178b42":"App disponibili",
"614bb33c219a0809bbe8e52f938dcce7":"Ordine d'acquisto generale (richiesta)",
"51d895d6183265b06e899570e19eecf6":"Logo filiale",
"9d6641632312bcd817a18331b01bd073":"Sfoglia e cerca i prodotti disponibili per l'acquisto.",
"883d14b9efdb4894d9879c0eff9b5acb":"Controlla lo stato della piattaforma o contatta il supporto.",
"55fc4360777c0241e107f70379e57e75":"Chiudi menu",
"da76f49c6f0252b80a6e330d6394c094":"Conferma la ricezione di prodotti e servizi basati su un ordine d'acquisto aperto.",
"67f2b8b530a56e35ec1e8254fb72748f":"Richiesta di connessione",
"9860a293ae95e8583f868975af1f4849":"Contatta supporto",
"210d71bea3790d832babc970a6674be3":"Converti un ordine aperto in una fattura.",
"8df8c2d028d750d31bb796681c2ea3f9":"Creare",
"e3f60bea8ce9079c56a6a69f86f4f048":"Crea nota di credito",
"5b5efdc0c8989fe1056b0e54734e824d":"Crea fattura",
"fadd16ebdc81e4fde9e7fc3154877894":"Crea una fattura di pagamento anticipato",
"0e1cf03ccaae390506a0da5f0f739280":"Crea fattura proforma",
"20c9ff16bb2f0448290a5087a58cdebf":"Crea ed invia preventivi ai tuoi clienti.",
"830fa5e28d0b88eb70218ea763f71c5c":"Crea ordine d'acquisto generale",
"d75c3f6646800bc987c0c07f986b6111":"Crea nuovo prodotto",
"9f7cd528570ea5fa70d5f0fdb8227759":"Crea offerta",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Crea ordine",
"9f2650a5abb1e17ffb4714580cc4c457":"Creare prodotto",
"581c02d2262d5818fb14faef2a5c5e4e":"Creare preventivo",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Crea una richiesta scritta",
"3f884136e59449da353adefd80121181":"Crea...",
"d23014cf5a5e7f8c6440b96692831aa8":"Nota di credito",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definisci prodotti o servizi con prezzi (stimati) e budget interni per creare ordini d'acquisto per un periodo di tempo definito.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definisci i prodotti in vendita sui mercati di Tradeshift.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Definisci la tua richiesta per un prodotto o servizio che non sei riuscito a trovare nel negozio ed aggiungila al tuo carrello o a una richiesta emissione dell'ordine.",
"1b0dd1aca58f84099cff422597422e56":"Impossibile caricare Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Impossibile caricare Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Entrata merci",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Raggruppa le aziende in gruppi di rete per un'amministrazione semplificata",
"d7b639ae724bad4cb10abf6e411bd74e":"Le tue app Installate",
"3c4be400f728da4526de626653d56ccc":"Fattura",
"697ca358c7443b09bab9478598f14726":"Fattura ordine",
"db13bed8b517884b5e8e257c3e829e7d":"Emetti credito a copertura di futuri acquisti",
"8535934d42d580306640d88e0ed217aa":"Caricamento azienda…",
"802ed138bb62eb56ff187f839234478b":"Caricamento...",
"1c8e8813d12a483823656cc7c245cd58":"Uscita",
"b25df08fcf0d0acaf6384653ea02141e":"Uscita",
"34bea6b12f16fea4889a2a80c07ee8ec":"Gestisci gruppi",
"7a558a55eb2d9e641f608f759964a9f6":"Crea manualmente un ordine d'acquisto per un venditore, indicando i beni o i servizi proposti, le quantità ed i prezzi.",
"fbb9cc68fee07e74094da1030bfdf236":"Gruppo di rete",
"ba116f853ca15c3b6f136da33867158b":"Nessuna app corrisponde al tuo termine di ricerca.",
"14877050aa06ad5917e9d7a7048ad2e3":"Nessuna descrizione.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Nessuna autorizzazione",
"bacad0fc8777924b7400a5b05b8cc715":"Il {deletionDate}, il tuo account aziendale e il tuo account utente Tradeshift verranno eliminati. Se questo è un errore, potrai annullarlo.",
"2778717f6acbeb1edaac6a2656b478f3":"Aprire",
"37f066e2502bac5316dfc900890ade4c":"Apri menu",
"018c4048ea00acea67a5819288f4703f":"Ordine",
"2ae053867612b2202a870bd52cc3ff63":"Stato piattaforma",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Contatta l'amministratore del tuo account se ritieni che le tue autorizzazioni debbano essere modificate.",
"281f9682838b746f5a0b8a4555614843":"Pagamento anticipato fattura",
"387117ac2a32235f30c143484f94147f":"Fattura proforma",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Acquisto dal negozio",
"9bb4a36de6bff47eb4209096972979dd":"Preventivo",
"befc83a6b9e66cbfb953b901b91290d0":"Hai raggiunto il numero massimo di preferiti!",
"beb839cf63984c0634d05bdc900e1905":"Ricevi merci dall'ordine",
"d1b0cf6017a647b49abde5b8ba6fe614":"Filiali usate di recente",
"793f04b605fc32835a578962a6994422":"Registra un pagamento anticipato.",
"4eddd0ca790e495626f275843c87f440":"Ricarica",
"ab8100964aeb93c35539b88420121939":"Rimuovi app dai preferiti",
"d817327a5392f2d398ed8721a314bc37":"Cerca applicazioni",
"31e12c8c15eae192e29acf9fcb949287":"Cerca aziende sulla rete Tradeshift con cui vuoi fare affari.",
"3bcee8f61c10123abe392bf0cc31f99c":"Cerca un’azienda",
"4716fe18b1d4f74edf1a1cdacc83b329":"Negozio",
"50b5a47e8f2e9f988f509e248860acd0":"Mostra azioni",
"a563aabceadd081e01b44f50d0c9ba26":"A partire dal 31 marzo 2021, la compatibilità delle applicazioni Tradeshift con la versione 11 di Internet Explorer non è garantita. Aggiorna il tuo browser a una versione supportata dalla nostra piattaforma.",
"3ad73c1b179af6e6df8e5da91028b57c":"Supporto",
"e6e073d7d80064606e77b870ceaf452a":"Cambia filiale",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Prova a ricaricare la pagina.",
"2837b35c04c44f52c772b01723ad1ff0":"Impostazioni utente",
"5d3d3522e8b4899177ce29532ae479f0":"Visualizza tutte le filiali",
"39b9368677bb137b328178942025581b":"Visualizza ordini aperti",
"488b2f58d018e655df26de3ea3f2590c":"Richiesta scritta",
"7afb6c3060810bd8e4f55a2e42fd69c0":"Non disponi delle autorizzazioni per eseguire quest'azione.",
"1bf011c064e4459d5665ff6548bb3c04":"Il tuo account è programmato per la cancellazione.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Il tuo account aziendale, così come il tuo account utente Tradeshift, sono programmati per la cancellazione il {deletionDate}. Se questo è un errore, contatta l'amministratore dell'account.",
};
