import store from 'lib/store';
import constants from 'lib/helpers/constants';
import { actions as layoutActions } from 'lib/layout/layout';

export default function listener() {
	store.dispatch(layoutActions.resizeWindow());

	let resizeTimeout;
	window.addEventListener(
		'resize',
		function debouncedResize() {
			clearTimeout(resizeTimeout);
			resizeTimeout = setTimeout(function() {
				store.dispatch(layoutActions.resizeWindow());
			}, constants.DURATION_RESIZE_DEBOUNCE);
		},
		false
	);
}
