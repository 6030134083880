import debug from 'debug';
import KeepAlive from 'lib/events/KeepAlive';
import constants from 'lib/helpers/constants';
import { get, post } from 'lib/helpers/fetch';
import { actions as appsActions } from 'lib/apps/apps';
import { isDevelopment } from 'lib/helpers/helpers';

const d = debug(`${constants.NS}:createActionsModal`);

export const types = {
	GET_CREATE_ACTIONS: 'ts.chrome/createActionsModal/GET_CREATE_ACTIONS',
	GET_CREATE_ACTIONS_SUCCESS: 'ts.chrome/createActionsModal/GET_CREATE_ACTIONS_SUCCESS',
	GET_CREATE_ACTIONS_FAILURE: 'ts.chrome/createActionsModal/GET_CREATE_ACTIONS_FAILURE',
	SHOW_CREATE_MODAL: 'ts.chrome/createActionsModal/SHOW_CREATE_MODAL',
	HIDE_CREATE_MODAL: 'ts.chrome/createActionsModal/HIDE_CREATE_MODAL'
};

const initialState = {
	createActions: [],
	createActionsApps: [],
	createActionsLoading: false,
	visible: false
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case types.GET_CREATE_ACTIONS:
			return {
				...state,
				createActionsLoading: true
			};

		case types.GET_CREATE_ACTIONS_SUCCESS:
			return {
				...state,
				createActionsLoading: false,
				createActions: action.data.actions,
				createActionsApps: action.data.apps
			};
		case types.GET_CREATE_ACTIONS_FAILURE:
			return {
				...state,
				createActionsLoading: false
			};

		case types.SHOW_CREATE_MODAL:
			return {
				...state,
				visible: true
			};

		case types.HIDE_CREATE_MODAL:
			return {
				...state,
				visible: false
			};

		default:
			return state;
	}
};

export default reducer;

export const actions = {
	createAppOpen: (id, href, hasBackendAction) => async dispatch => {
		dispatch(actions.hideCreateActionsModal());

		// if we need to setup app before open it, we send a request with app id to backend
		if (hasBackendAction) {
			if (!isDevelopment()) {
				await KeepAlive.updateAccessToken();
				await post(process.config.urls.prepareCreateActionsURL, {}, { body: { appId: id } });
			}
		}

		// navigating to selected app
		dispatch(appsActions.appClick(id));
		window.location = href;
	},
	showCreateActionsModal: () => dispatch => {
		dispatch(appsActions.hideAppListModal());
		dispatch(actions.fetchCreateActions());
		dispatch({ type: types.SHOW_CREATE_MODAL });
	},
	hideCreateActionsModal: () => ({ type: types.HIDE_CREATE_MODAL }),
	getCreateActions: () => ({ type: types.GET_CREATE_ACTIONS }),
	getCreateActionsSuccess: actions => ({
		type: types.GET_CREATE_ACTIONS_SUCCESS,
		data: actions
	}),
	getCreateActionsFailure: err => ({
		type: types.GET_CREATE_ACTIONS_FAILURE,
		data: err
	}),
	fetchCreateActions: () => async (dispatch, getState) => {
		const createActionsApps = getState().createActionsModal.createActionsApps;
		if (createActionsApps && Object.keys(createActionsApps).length > 0) {
			return;
		}
		dispatch(actions.getCreateActions());
		try {
			let data;
			if (!isDevelopment()) {
				/**
				 * Always update the AccessToken before making a new request
				 */
				await KeepAlive.updateAccessToken();
				d('Loading data from %o', process.config.urls.createActionsURL);
				data = await get(process.config.urls.createActionsURL);
			} else {
				data = require('mockdata/apps').createActions();
				await new Promise(resolve => {
					setTimeout(resolve, constants.DURATION_STANDALONE_STARTUP);
				});
			}
			d('Loaded data %O', data);
			dispatch(actions.getCreateActionsSuccess(data));
		} catch (err) {
			/**
			 * In case we're in development mode, we should load the dummy data,
			 * if V4 is unreachable
			 */
			if (isDevelopment()) {
				dispatch(actions.getCreateActionsSuccess(require('mockdata/apps').createActions()));
			} else {
				// TODO: we need to track errors somehow?
				dispatch(actions.getCreateActionsFailure(err));
			}
		}
	}
};
