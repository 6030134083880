module.exports = {
"9884bd8fac7d5a801f997c4f65d56af6":"Una serie de productos que quiere ofrecer a un comprador concreto.",
"b9b2bec3b24aa4b9a601ab046cd12009":"Documento emitido por un vendedor a un comprador, que indica las cantidades y el coste de los productos o servicios prestados.",
"5b24efebe4bd25a8c5e3aca06197e35e":"Una factura preliminar de venta enviada con antelación del envío o entrega de mercancías.",
"fb97e5cd5cce63e6ec87634972bdb3f3":"Cuenta programada para eliminación.",
"4e917d587825921098d19cb5374ffa54":"Añadir aplicación a favoritos",
"7328b929c3b7ea8a93ee9e597817f510":"Todas las aplicaciones",
"baaa8b0af4b7c2a73cdd46756902f9dd":"Icono de flecha",
"421d54e688f76e063b53b1bd67178b42":"Apps disponibles",
"614bb33c219a0809bbe8e52f938dcce7":"Orden de compra abierta (solicitud)",
"51d895d6183265b06e899570e19eecf6":"Logotipo de sucursal",
"9d6641632312bcd817a18331b01bd073":"Navegue y busque los productos disponibles para compra.",
"883d14b9efdb4894d9879c0eff9b5acb":"Comprobar el estado de la plataforma o consultar a soporte.",
"55fc4360777c0241e107f70379e57e75":"Cerrar menú",
"da76f49c6f0252b80a6e330d6394c094":"Confirme la entrada de mercancías y servicios según un pedido abierto.",
"67f2b8b530a56e35ec1e8254fb72748f":"Solicitud de conexión",
"9860a293ae95e8583f868975af1f4849":"Contactar con soporte técnico",
"210d71bea3790d832babc970a6674be3":"Convertir un pedido abierto en una factura.",
"8df8c2d028d750d31bb796681c2ea3f9":"Crear",
"e3f60bea8ce9079c56a6a69f86f4f048":"Crear abono",
"5b5efdc0c8989fe1056b0e54734e824d":"Crear factura",
"fadd16ebdc81e4fde9e7fc3154877894":"Crear factura prepago",
"0e1cf03ccaae390506a0da5f0f739280":"Crear factura proforma",
"20c9ff16bb2f0448290a5087a58cdebf":"Crear y enviar documentos de presupuesto a los clientes.",
"830fa5e28d0b88eb70218ea763f71c5c":"Crear orden de compra abierta",
"d75c3f6646800bc987c0c07f986b6111":"Crear un producto nuevo",
"9f7cd528570ea5fa70d5f0fdb8227759":"Crear oferta",
"e778d3c66bb24ea357fcbaacb2e4bab9":"Crear orden",
"9f2650a5abb1e17ffb4714580cc4c457":"Crear producto",
"581c02d2262d5818fb14faef2a5c5e4e":"Crear presupuesto",
"c155d5b2e52f1335ad9c4d3c70fc7e49":"Crear solicitud por escrito",
"3f884136e59449da353adefd80121181":"Crear...",
"d23014cf5a5e7f8c6440b96692831aa8":"Nota de crédito",
"a9616b4df87bbe0147d76ca08f1a4ad3":"Definir productos o artículos de servicio con precios (estimados) y presupuestos internos para crear pedidos de compra durante un período de tiempo definido.",
"4d20d8e30fa5857d15cbe98f3ed9eb58":"Definir productos a la venta en Tradeshift Marketplaces.",
"f8b64ecf8d2c5860b2881a7ccd3d9727":"Defina su solicitud de un producto o servicio que no pueda encontrar en la Tienda y añádalo a su cesta o a una Solicitud de compra.",
"1b0dd1aca58f84099cff422597422e56":"Error al cargar Tradeshift",
"2ca056bf517beb52194e04b1d425b85d":"Error al cargar Tradeshift,",
"901bcad22b5486ef2185cb1e59e0a234":"Recepción de mercancías",
"3a6aa4aeb3aaeb89709afe6f5e0368a0":"Agrupar empresas en grupos de red para simplificar la administración",
"d7b639ae724bad4cb10abf6e411bd74e":"Tus apps instaladas",
"3c4be400f728da4526de626653d56ccc":"Factura",
"697ca358c7443b09bab9478598f14726":"Factura del pedido",
"db13bed8b517884b5e8e257c3e829e7d":"Emitir crédito contra compras futuras.",
"8535934d42d580306640d88e0ed217aa":"Cargando empresa…",
"802ed138bb62eb56ff187f839234478b":"Cargando...",
"1c8e8813d12a483823656cc7c245cd58":"Cerrar sesión",
"b25df08fcf0d0acaf6384653ea02141e":"Cerrar sesión",
"34bea6b12f16fea4889a2a80c07ee8ec":"Gestionar grupos",
"7a558a55eb2d9e641f608f759964a9f6":"Crear manualmente un pedido para un vendedor, indicando los bienes o servicios propuestos, las cantidades y los precios.",
"fbb9cc68fee07e74094da1030bfdf236":"Grupo de red",
"ba116f853ca15c3b6f136da33867158b":"No hay aplicaciones que coincidan con el término de búsqueda.",
"14877050aa06ad5917e9d7a7048ad2e3":"Sin descripción.",
"b6f4ed6dd31b74d156a22c04e9e02199":"Sin permiso",
"bacad0fc8777924b7400a5b05b8cc715":"El {deletionDate}, se eliminará su cuenta de empresa y su cuenta de usuario de Tradeshift. Si se ha hecho por error, se puede cancelar.",
"2778717f6acbeb1edaac6a2656b478f3":"Abrir",
"37f066e2502bac5316dfc900890ade4c":"Abrir menú",
"018c4048ea00acea67a5819288f4703f":"Orden",
"2ae053867612b2202a870bd52cc3ff63":"Estado de la plataforma",
"fbfe37f0e1dbe4d0478ead1e91d01be8":"Si considera que se deben modificar sus permisos, póngase en contacto con el administrador.",
"281f9682838b746f5a0b8a4555614843":"Factura prepago",
"387117ac2a32235f30c143484f94147f":"Factura proforma",
"b2562e71feb3c0a4d25d8b4aeacd545f":"Compra en la Tienda",
"9bb4a36de6bff47eb4209096972979dd":"Oferta",
"befc83a6b9e66cbfb953b901b91290d0":"Se ha alcanzado el número máximo de favoritos.",
"beb839cf63984c0634d05bdc900e1905":"Recibir productos del pedido",
"d1b0cf6017a647b49abde5b8ba6fe614":"Sucursales usadas recientemente",
"793f04b605fc32835a578962a6994422":"Registrar un pago por adelantado.",
"4eddd0ca790e495626f275843c87f440":"Recargar",
"ab8100964aeb93c35539b88420121939":"Eliminar aplicación de favoritos",
"d817327a5392f2d398ed8721a314bc37":"Buscar aplicaciones",
"31e12c8c15eae192e29acf9fcb949287":"Busca empresas de la red Tradeshift con las que hacer negocios.",
"3bcee8f61c10123abe392bf0cc31f99c":"Buscar una empresa",
"4716fe18b1d4f74edf1a1cdacc83b329":"Comprar",
"50b5a47e8f2e9f988f509e248860acd0":"Mostrar acciones",
"a563aabceadd081e01b44f50d0c9ba26":"A partir del 31 de marzo de 2021, las aplicaciones de Tradeshift no son compatibles con la versión 11 de Internet Explorer. Hay que actualizar el navegador a una versión admitida por la plataforma.",
"3ad73c1b179af6e6df8e5da91028b57c":"Ayuda",
"e6e073d7d80064606e77b870ceaf452a":"Conmutar sucursal",
"5b79e2bee98d5f41df426c8a1cbad3a4":"Intenta volver a cargar la página.",
"2837b35c04c44f52c772b01723ad1ff0":"Ajustes de usuario",
"5d3d3522e8b4899177ce29532ae479f0":"Ver todas las sucursales",
"39b9368677bb137b328178942025581b":"Ver pedidos abiertos",
"488b2f58d018e655df26de3ea3f2590c":"Solicitud por escrito",
"7afb6c3060810bd8e4f55a2e42fd69c0":"No tienes permiso para realizar esta acción",
"1bf011c064e4459d5665ff6548bb3c04":"Su cuenta está programada para eliminación.",
"cc6e5b5856f58981e2ad4a72a5136fdc":"Su cuenta de empresa, así como la cuenta de usuario de Tradeshift, están programadas para eliminación el {deletionDate}. Si se ha hecho por error, póngase en contacto con el administrador de la cuenta.",
};
