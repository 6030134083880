/**
 * Redux Action Types for the apps Reducer
 * @note This had to be moved to a separate file, because it's used in root and apps,
 * but apps also uses root therefore creating a cyclic dependency =/
 */
const appsTypes = {
	UI_BUSY: 'ts.chrome/apps/UI_BUSY',
	APP_LAUNCH: 'ts.chrome/apps/APP_LAUNCH',
	APP_PIN: 'ts.chrome/apps/APP_PIN',
	APP_UNPIN: 'ts.chrome/apps/APP_UNPIN',
	APP_CLICK: 'ts.chrome/apps/APP_CLICK',
	SETTINGS_APP_CLICK: 'ts.chrome/apps/SETTINGS_APP_CLICK',
	SUPPORT_APP_CLICK: 'ts.chrome/apps/SUPPORT_APP_CLICK',
	SAVE_PINNED_APPS: 'ts.chrome/apps/SAVE_PINNED_APPS',
	GET_DESCRIPTIONS: 'ts.chrome/apps/GET_DESCRIPTIONS',
	GET_DESCRIPTIONS_SUCCESS: 'ts.chrome/apps/GET_DESCRIPTIONS_SUCCESS',
	GET_DESCRIPTIONS_FAILURE: 'ts.chrome/apps/GET_DESCRIPTIONS_FAILURE',
	SHOW_MODAL: 'ts.chrome/apps/SHOW_MODAL',
	HIDE_MODAL: 'ts.chrome/apps/HIDE_MODAL',
	FILTER_APPS: 'ts.chrome/apps/FILTER_APPS'
};

export default appsTypes;
